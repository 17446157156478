<template>
  <v-container fluid class="pa-0">
  <v-layout row wrap>
    <v-flex xs12>
      <v-card>
        <v-toolbar flat color="white" class="px-2 mt-3">
          <planogram-status-description v-if="!noPlanograms" class="mr-4">
            <template #activator="{ on: dialog }">
              <v-tooltip bottom open-delay="50">
                <template #activator="{ on: tooltip }">
                  <span v-on="dialog">
                    <planogram-status :status="planogram.status" :show-info="true"
                      :on="tooltip" class="mr-2"></planogram-status>
                  </span>
                </template>
                {{ $t('clickForMoreInfoIndividual') }}
              </v-tooltip>
            </template>
          </planogram-status-description>

          <v-flex xs4>
            <!-- Presentation -->
            <h6 class="title mt-2" v-if="!noPlanograms">
              {{ $t('planogramIndividual') }} {{planogram.id}}
            </h6>
            <h6 class="title mt-2" v-if="noPlanograms && !loading.planograms">
              {{ $t('noPlanogramsIndividual') }}
            </h6>
            <!-- Version of planogram -->
            <div class="subtitle-1 grey--text text--darken-1 mb-2" v-if="!noPlanograms" style="line-height:14px">
                {{ getPlanogramDescription(this.planogram) }}
            </div>
          </v-flex>
          <v-spacer></v-spacer>
          <v-form @submit.prevent="options.page = 1; getPlanogramPaginated(planogramId)">
            <v-text-field
              v-if="!noPlanograms"
              class="mr-2"
              v-model="search"
              prepend-inner-icon="search"
              :label="$t('searchProductIndividual')"
              @click:prepend-inner="options.page = 1; getPlanogramPaginated(planogramId)"
              @click:clear="search = ''; getPlanogramPaginated(planogramId)"
              @keydown:enter="options.page = 1; getPlanogramPaginated(planogramId)"
              single-line
              hide-details
              clearable
              filled
              rounded
              dense
            >
            </v-text-field>
          </v-form>
          <!-- Tools for presentation -->
          <!-- Next and Preview Planograms buttons -->
          <v-btn
            class="ml-4 mr-2"
            v-show="!noPlanograms"
            @click.native="previousPlanogram"
            :disabled="this.planogramIndex >= this.planograms.length - 1 ? true : false"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            class="mr-4"
            v-show="!noPlanograms"
            @click.native="nextPlanogram"
            :disabled="this.planogramIndex === 0 ? true : false"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>

          <!-- Create new Planogram -->
          <planogram-creation :posId="posId" @success="onPlanogramCreated" @error="onPlanogramCreationError">
            <template #activator="{ on: dialog }">
              <v-tooltip bottom open-delay="50" :disabled="!disableNewPlanogram">
                <template #activator="{ on: tooltip }">
                  <div v-on="tooltip">
                    <v-btn color="success" v-on="dialog" :disabled="disableNewPlanogram">
                      <v-icon left>add</v-icon>{{ $t('planogramIndividual') }}
                    </v-btn>
                  </div>
                </template>
                <span>{{ $t('draftModeIndividual') }}</span>
              </v-tooltip>
            </template>
          </planogram-creation>

        </v-toolbar>

        <!-- Tools for pending planogram -->
        <v-toolbar v-if="!noPlanograms" flat dense color="blue-grey lighten-5" class="px-2">

          <v-btn text color="grey darken-3" @click.native="changeView" v-if="!fastEditView">
            <v-icon left>mdi-view-quilt</v-icon>
            <span v-if="tableView">
              {{ $t('cardVisionIndividual') }}
            </span>
            <span v-if="!tableView">
              {{ $t('tabularViewIndividual') }}
            </span>
          </v-btn>

          <v-divider v-if="!fastEditView" vertical></v-divider>

          <template v-if="planogram.status === 'Active'">
            <v-btn text color="success" @click.native="getExcel" :loading="loading.excel">
              <v-icon left>mdi-file-excel</v-icon>{{ $t('iconExcel') }}
            </v-btn>
            <v-divider vertical></v-divider>
          </template>

          <template v-if="planogram.status === 'Active'">
            <v-btn text color="grey darken-3" @click.native="showOnlyCritical = !showOnlyCritical">
              <v-icon left>{{ showOnlyCritical ? 'mdi-filter-variant-minus' : 'mdi-filter-variant' }}</v-icon>
              {{ showOnlyCritical ? $t('showAllIndividual') : $t('filterCriticalIndividual') }}
            </v-btn>
            <v-divider vertical></v-divider>
          </template>

          <template v-if="planogram.status === 'Active'">
            <v-btn text color="red darken-4" @click="openProductUnusedDialog()">
              <v-icon left color="red darken-4">mdi-delete-sweep</v-icon>
              {{$t('ProductsUnused') }}
            </v-btn>
            <v-divider vertical></v-divider>
          </template>
          <v-spacer></v-spacer>

          <template v-if="planogram.status === 'Draft'">
            <v-divider vertical></v-divider>
            <v-slide-x-reverse-transition>
              <v-btn
                height="48px"
                large
                tile
                :text="!fastEditView"
                @click.native="changeFastEdit()"
                color="info"
                >
                <v-icon left>edit</v-icon>{{ $t('quickEditIndividual') }}
              </v-btn>
            </v-slide-x-reverse-transition>
          </template>

          <!-- Add entry button -->
          <template v-if="planogram.status === 'Draft'">
            <v-divider vertical></v-divider>
            <v-btn
              text
              @click.native="addNewItem()"
              color="success"
              >
              <v-icon left>add</v-icon>{{ $t('newItemIndividual') }}
            </v-btn>
          </template>

          <portal-target name="planogramToolbar"></portal-target>

          <!-- Entry add / update dialog -->
          <planogram-entry
            v-model="itemDialog"
            :planogram-id="planogramId"
            :is-new="editedIndex === -1"
            :edited-entry="editedEntry"
            :selection-list="selectionList"
            :pos-type="planogram.pointOfSalePosType"
            :next-selection="nextSelection"
            @entry-created="onEntryCreated"
            @entry-updated="onEntryUpdated"
            @entry-error="onEntryError"
          ></planogram-entry>

          <template v-if="planogram.status === 'Draft' && !fastEditView">
            <v-divider vertical></v-divider>
            <v-btn text color="warning" @click.native="finalizeDialog.enabled = true">
              <v-icon left>check</v-icon>{{ $t('finalizeDraftIndividual') }}
            </v-btn>
          </template>

          <template v-if="planogram.status === 'Pending'">
            <v-divider vertical></v-divider>
            <v-btn text color="success" @click.native="activateDialog.enabled = true">
              <v-icon left>check</v-icon>{{ $t('activateNowIndividual') }}
            </v-btn>
          </template>

          <!-- Delete Planogram Button -->
          <template v-if="planogram.status === 'Draft' && !fastEditView || planogram.status === 'Pending'">
            <v-divider vertical></v-divider>
            <v-btn text color="error" @click.native="removePlanogramDialog.enabled = true">
              <v-icon left>delete</v-icon>{{ $t('removePlanogramIndividual') }}
            </v-btn>
          </template>

          <!-- Remove planogram dialog -->
          <v-dialog v-model="removePlanogramDialog.enabled" max-width="400">
            <v-card>
              <v-card-text class="pt-4">{{ $t('confirmRemovePlanogramIndividual') }}</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text
                  @click.native="removePlanogramDialog.enabled = false; removePlanogramDialog.loading = false"
                  >{{ $t('removeNo') }}</v-btn>
                <v-btn
                  color="green darken-1"
                  :loading="removePlanogramDialog.loading"
                  text
                  @click.native="deletePlanogram"
                >{{ $t('removeIndividual') }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Finalize planogram dialog -->
          <v-dialog v-model="finalizeDialog.enabled" max-width="400">
            <v-card>
              <v-card-title class="headline"><v-icon left>info</v-icon>{{ $t('finalizePlanogramIndividual') }}</v-card-title>
              <v-card-text>
                {{ $t('finalizePlanogramWarningIndividual') }}
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="green darken-1" @click.native="finalizeDialog.enabled = false; finalizeDialog.loading = false">
                  {{ $t('cancelIndividual') }}
                </v-btn>
                <v-btn text color="green darken-1" :loading="finalizeDialog.loading" @click.native="finalizePlanogram">{{ $t('btnFinish') }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Activate planogram dialog -->
          <v-dialog v-model="activateDialog.enabled" max-width="400">
            <v-card>
              <v-card-title class="headline"><v-icon left>warning</v-icon>{{ $t('activatePlanogramCard') }}</v-card-title>
              <v-card-text>
                {{ $t('activatePlanogramWarningIndividual') }}
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="green darken-1" @click.native="activateDialog.enabled = false; activateDialog.loading = false">
                  {{ $t('cancelIndividual') }}
                </v-btn>
                <v-btn text color="green darken-1" :loading="activateDialog.loading" @click.native="activatePlanogram">{{ $t('activatePlanogramIndividual') }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-progress-linear indeterminate :hidden="!loading.planograms"></v-progress-linear>
      </v-card>
    </v-flex>

    <!-- Card Vision -->
    <v-container v-if="!tableView && !fastEditView" class="mb-4" fluid grid-list-lg>
      <v-layout row wrap>
        <v-container v-if="planogram != null" fluid grid-list-lg class="px-8 py-4">

          <!-- Card Vision header -->
          <v-row class="ma-0 pa-0" cols="12">
            <v-col cols="9" class="pa-0 ma-0">
              <div class="subtitle-1 grey--text text--darken-1">
                {{ $t('paginationDisplay') }} {{ this.paginationIndexes[0] }} - {{ this.paginationIndexes[1] }} {{ $t('paginationOf') }} {{ this.totalItems }}
              </div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="2" class="pa-0 ma-0">
              <div class="subtitle-1 grey--text text--darken-1">
                {{ $t('paginationItemsPerPage') }}
              </div>
            </v-col>
            <v-col cols="1" class="pa-0 ma-0">
              <v-select
                v-model="options.itemsPerPage"
                :items="[10, 25, 50, 100]"
                class="ma-0 pa-0"
                dense
                @input="getPlanogramPaginated(planogramId)"
              ></v-select>
            </v-col>
          </v-row>

          <!-- Card Vision elements -->
          <template v-for="entryLine in entryLines">
            <div :key="entryLine.line">
              <v-layout xs12 row wrap>
                <v-flex xs6 md4 lg2 v-for="entry in entryLine.entries" :key="entry.index" flat>
                  <v-card
                    max-width="344"
                    shaped>
                    <v-system-bar
                      :color="getWarningColor(planogram, entry)"
                    >
                      <template v-if="entry.currentQuantity <= entry.minimumQuantity && planogram.status === 'Active'">
                        <v-icon color="#FFC107">warning</v-icon>{{ $t('criticalStock') }}
                      </template>
                      <template v-if="entry.planogram !== null && entry.quantityToSupply == null && planogram.status === 'Draft'">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <p class="text-formatting pt-4">{{ $t('requiredQuantity') }}</p>
                            <v-icon color="#FFC107" v-on="on">warning</v-icon>
                          </template>
                          <span>{{ $t('desiredQuantityNotFilled') }}</span>
                        </v-tooltip>
                      </template>
                    </v-system-bar>
                    <v-card-title primary-title class="pl-3 pr-3 pb-3 pt-1">
                      <v-avatar
                        tile
                        size="80">
                        <v-img
                          class="py-2"
                          v-if='entry.productImageUrl != null'
                          :src='entry.productImageUrl'
                          contain></v-img>
                      </v-avatar>
                      <v-spacer></v-spacer>
                      <template v-if="!noPlanograms && planogram.status === 'Active' && entry.productId != null">
                        <v-tooltip left>
                          <template #activator="{ on }">
                            <v-btn text icon color="grey darken-3" class="mx-0" @click.native="editQuantity(entry)" v-on="on">
                              <v-icon>edit</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('updateQuantityIndividual') }}</span>
                        </v-tooltip>
                        <span class="subtitle-1">{{entry.currentQuantity}}/{{entry.quantityToSupply}}</span>
                      </template>
                      <template v-if="editPlanogram">
                        <v-tooltip left>
                          <template #activator="{ on }">
                            <v-btn text icon color="primary" class="mx-0" @click.native="editItem(entry)" v-on="on">
                              <v-icon>{{entry.productId !== null ? 'edit' : 'add'}}</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('editItemIndividual') }}</span>
                        </v-tooltip>
                        <v-tooltip left>
                          <template #activator="{ on }">
                            <v-btn text icon color="error" class="mx-0" @click.native="confirmRemoval(entry)" v-on="on">
                              <v-icon>delete</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('deleteItemIndividual') }}</span>
                        </v-tooltip>
                      </template>
                      <div class="info-subtitle grey--text text--darken-3">
                        {{entry.productId !== null && entry.productCode !== null ? entry.productCode : '-'}}
                      </div>
                      <div class="info-subtitle grey--text text--darken-3">
                        {{entry.productId !== null && entry.productDescription !== null ? entry.productDescription : '-'}}
                      </div>
                      <div class="info-subtitle grey--text text--darken-3">
                        {{entry.productId !== null && entry.productCategoryName !== null ? entry.productCategoryName : '-'}}
                      </div>
                      <div class="info-subtitle grey--text text--darken-3">
                        {{ entry.productId !== null ? $currencyFormatter.format(entry.price) : '-' }}
                      </div>
                    </v-card-title>
                    <v-card-actions>
                      <template>
                        <span :class="selectionClass" style="vertical-align:middle">{{entry.selection}}</span>
                        <v-spacer></v-spacer>
                        <template v-if="!noPlanograms && (planogram.status !== 'Archived' && planogram.status !== 'Pending')">
                          <router-link :to="`/${$t('path.nameInventoryMaster')}?productId=${entry.productId}&inventoryId=${planogram.pointOfSaleInventoryId}`">
                            <v-tooltip left>
                              <template #activator="{ on }">
                                <v-btn text icon class="ma-0" v-on="on">
                                  <v-icon>mdi-swap-horizontal-bold</v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t('viewMovementsHistoryIndividual') }}</span>
                            </v-tooltip>
                          </router-link>
                        </template>
                        <template v-if="!noPlanograms && (planogram.status !== 'Archived' && planogram.status !== 'Pending')">
                          <router-link :to="`/${$t('path.nameProductsMaster')}?id=${entry.productId}&pdv=${posId}&pog=${planogramIdQuery}&openDialog=true`">
                            <v-tooltip left>
                              <template #activator="{ on }">
                                <v-btn text icon class="ma-0" v-on="on">
                                  <v-icon>mdi-subdirectory-arrow-right</v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t('goToProductRegistrationIndividual') }}</span>
                            </v-tooltip>
                          </router-link>
                        </template>
                      </template>
                    </v-card-actions>
                  </v-card>
                </v-flex>
              </v-layout>
              <v-divider v-if="entryLine.line < entryLines.length" class="mt-4 mb-4"></v-divider>
            </div>
          </template>
          <v-pagination
            class="py-2"
            v-model="options.page"
            :length="totalPages"
            :total-visible="7"
            @input="getPlanogramPaginated(planogramId)"
          ></v-pagination>
        </v-container>
      </v-layout>
    </v-container>

    <v-container v-if="tableView && !fastEditView" class="mb-6">
      <template>
        <v-container fluid class="py-0">
          <v-layout>
            <v-flex xs-12>
              <v-card class="mb-16">
                <v-data-table
                  :headers="filteredHeaders"
                  :items="filteredItems"
                  :options.sync="options"
                  :server-items-length="totalItems"
                  :no-data-text="$t('noItemsIndividual')"
                  :footer-props="{ itemsPerPageOptions: [10, 25, 50, 100] }"
                >
                  <template #[`item.productImageUrl`]="{ item }">
                    <v-img
                      class="py-2"
                      v-if='item.productImageUrl != null'
                      :src='item.productImageUrl'
                      max-width="95"
                      max-height="95"
                      contain
                    ></v-img>
                  </template>
                  <template #[`item.price`]="{ item }">
                    {{ $currencyFormatter.format(item.price) }}
                  </template>
                  <template #[`item.currentQuantity`]="{ item }">
                    <v-chip v-if="planogram.status === 'Active'" :color="getCardColor(item.currentQuantity/item.quantityToSupply)" dark>
                      {{item.currentQuantity}} / {{item.quantityToSupply}}
                    </v-chip>
                    <span v-if="planogram.status !=='Active'">-</span>
                  </template>
                  <template #[`item.actions`]="{ item }" class="justify-center px-0">
                    <v-tooltip v-if="!noPlanograms && planogram.status === 'Active' && item.productId != null" left>
                      <template #activator="{ on }">
                        <v-btn text icon color="grey darken-3" class="ma-0" @click.native="editQuantity(item)" v-on="on">
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('updateQuantityIndividual') }}</span>
                    </v-tooltip>
                    <v-tooltip v-if="editPlanogram" left>
                      <template #activator="{ on }">
                        <v-btn text icon color="primary" class="ma-0" @click.native="editItem(item)" v-on="on">
                          <v-icon>{{item.productId !== null ? 'edit' : 'add'}}</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('editItemIndividual') }}</span>
                    </v-tooltip>
                    <v-tooltip v-if="editPlanogram" left>
                      <template #activator="{ on }">
                        <v-btn text icon color="error" class="ma-0" @click.native="confirmRemoval(item)" v-on="on">
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('deleteItemIndividual') }}</span>
                    </v-tooltip>
                    <template>
                      <router-link :to="`/${$t('path.nameProductsMaster')}?id=${item.productId}&pdv=${posId}&pog=${planogramIdQuery}`">
                        <v-tooltip left>
                          <template #activator="{ on }">
                            <v-btn text icon class="ma-0" v-on="on">
                              <v-icon>mdi-subdirectory-arrow-right</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('changeDefaultProductIndividual') }}</span>
                        </v-tooltip>
                      </router-link>
                      <v-tooltip left v-if="item.currentQuantity <= item.minimumQuantity && planogram.status === 'Active'">
                        <template #activator="{ on }">
                          <v-btn text icon class="ma-0" v-on="on">
                            <v-icon color="#FFC107" left>warning</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('criticalStockIndividual') }}</span>
                      </v-tooltip>
                    </template>
                  </template>
                </v-data-table>
                <inventory-change
                  v-model="quantityDialog"
                  :pos-id="posId"
                  :item="editedEntry"
                  @change="onQuantityChanged">
                </inventory-change>

              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </template>
    </v-container>

    <planogram-draft-edition
      ref="draftEdition"
      v-if="fastEditView"
      :filtered-items="filteredItems"
      :pos-type="planogram.pointOfSalePosType"
      :pos-id="posId"
      :edited-entry="editedEntry"
      :planogram-id="planogramId"
      :total-items="totalItems"
      @draft-child-cancel="endDraftview()"
      @draft-save="updateDraftView()"
      :draft-search="search"
    ></planogram-draft-edition>

    <feedback ref="feedback"></feedback>

    <inventory-change
      v-model="quantityDialog"
      :pos-id="posId"
      :item="editedEntry"
      @change="onQuantityChanged"
    ></inventory-change>

    <!-- Remove dialog -->
    <v-dialog v-model="removeDialog.enabled" max-width="400">
      <v-card>
        <v-card-text class="pt-4">
          {{ $t('removeItemConfirmationIndividual') }} {{removeDialog.item.selection}}?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click.native="removeDialog.enabled = false">{{ $t('removeNo') }}</v-btn>
          <v-btn
            color="green darken-1"
            :loading="loading.removeItem"
            text
            @click.native="deleteItem(removeDialog.item)"
          >{{ $t('removeIndividual') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialog-products-unused
      v-model="productUnused.unusedProductDialog"
      :posId="posId"
      :planogramId="planogramId"
      @close="closeProductUnusedDialog"
      @error="handleError"
      @openCreatePlanogramUnusedProduct="openCreatePlanogramUnusedProduct">
    </dialog-products-unused>

    <dialog-create-draft-product-unused
      v-model="productUnused.createPlanogramDraftDialog"
      :posId="posId"
      :planogramId="planogramId"
      :dateFilter="dateInterval"
      :selectedItems="selectedItems"
      @close="closeCreatePlanogramUnusedProduct"
      @error="handleError"
      @createSucess="onPlanogramCreated">
    </dialog-create-draft-product-unused>

  </v-layout>
  </v-container>
</template>

<script>
import PlanogramCreationDialog from '@/planogram/PlanogramCreationDialog'
import PlanogramEntryDialog from '@/planogram/PlanogramEntryDialog'
import PlanogramStatusDescriptionDialog from '@/planogram/PlanogramStatusDescriptionDialog'
import PlanogramStatusChip from '@/planogram/PlanogramStatusChip'
import PlanogramDates from '@/planogram/PlanogramDates'
import download from '@/components/Download'
import InventoryChangeDialog from '@/planogram/InventoryChangeDialog'
import PlanogramDraftEdition from '@/planogram/PlanogramDraftEdition'
import { shouldRefreshData, toApiPagination } from '../components/TableOptionsComparator';
import DialogProductsUnused from '@/planogram/RemoveProductUnused/DialogProductsUnused'
import DialogCreateDraftProductUnused from '@/planogram/RemoveProductUnused/DialogCreateDraftProductUnused.vue'
import agent from '@/api/agent'

export default {
  props: {
    posId: { type: Number, required: true },
    planogramIdQuery: { type: Number, default: null }
  },
  data() {
    return {
      dialogDate: false,
      validTo: null,
      validFrom: null,
      dateFrom: null,
      dateTo: null,
      menuFrom: false,
      menuTo: false,
      planograms: [],
      planogramId: null,
      planogramIndex: 0,
      planogram: {
        id: null,
        entries: [],
        posType: null,
        pointOfSaleInventoryId: null
      },
      headers: [
        { text: this.$t('headersImageIndividual'), align: 'left', sortable: false, value: 'productImageUrl' },
        { text: this.$t('headersSelectionIndividual'), align: 'left', sortable: true, value: 'selection' },
        { text: this.$t('headersProductCodeIndividual'), align: 'left', sortable: true, value: 'productCode' },
        { text: this.$t('headersProductNameIndividual'), align: 'left', sortable: true, value: 'productDescription' },
        { text: this.$t('headersProductCategoryIndividual'), align: 'left', sortable: true, value: 'productCategoryName' },
        { text: this.$t('headersPriceIndividual'), align: 'left', sortable: true, value: 'price' },
        { text: this.$t('headersCurrentQuantityIndividual'), align: 'left', sortable: true, value: 'currentQuantity'},
        { text: this.$t('headersDesiredParLevelIndividual'), align: 'left', sortable: true, value: 'quantityToSupply'},
        { text: this.$t('headersActionIndividual'), align: 'left', sortable: false, value: 'actions' }
      ],
      tableView: false,
      fastEditView: false,
      categories: null,
      showOnlyCritical: false,
      itemDialog: false,
      quantityDialog: false,
      editedEntry: {},
      editedIndex: -1,
      nextSelection: null,
      loading: {
        planograms: true,
        removeItem: false,
        excel: false,
        pickList: false
      },
      removeDialog: {
        enabled: false,
        item: {}
      },
      removePlanogramDialog: {
        enabled: false,
        loading: false
      },
      finalizeDialog: {
        enabled: false,
        loading: false
      },
      activateDialog: {
        enabled: false,
        loading: false
      },
      search: '',
      totalItems: 0,
      totalPages: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["quantityToSupply"],
        sortDesc: [true],
        mustSort: true
      },
      productUnused : {
        unusedProductDialog: false,
        createPlanogramDraftDialog: false,
      },
      dateInterval: "",
      selectedItems: [],
    }
  },
  computed: {
    selectionList() {
      if (this.planogram === null) return [];
      return this.planogram.entries.map(e => e.selection);
    },
    noPlanograms() {
      return this.planogram.id === null;
    },
    editPlanogram() {
      return this.planogram.status === 'Draft';
    },
    disableNewPlanogram() {
      return this.planograms.some((p) => p.status == 'Draft' || p.status == 'Pending');
    },
    filteredItems() {
      return this.planogram.entries;
    },
    entryLines() {
      const lines = [];
      if (this.checkSnack()) {
        this.filteredItems.forEach((e) => {
          const firstDigit = (''+e.selection)[0];
          const entryLine = lines.find((l) => l.line === firstDigit);
          if (entryLine != null) {
            entryLine.entries.push(e);
          }
          else {
            lines.push({ line: firstDigit, entries: [e] });
          }
        });
      }
      else {
        lines.push({ line: 1, entries: this.filteredItems });
      }
      return lines;
    },
    selectionClass() {
      return this.planogram.pointOfSalePosType === 'MicroMarket' ? 'caption' : 'title';
    },
    paginationIndexes() {
      return [
        (this.options.page - 1) * this.options.itemsPerPage + 1,
        Math.min((this.options.page) * this.options.itemsPerPage, this.totalItems)
      ]
    },
    filteredHeaders() {
    if (this.planogram.status === 'Draft') {
      return this.headers.filter((h) => h.value !== 'currentQuantity');
    } else {
      return this.headers.filter((h) => h.value !== 'quantityToSupply');
    }
    },
  },
  watch: {
    planogramId(newId) {
      this.getPlanogramPaginated(newId);
    },
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.getPlanogramPaginated(this.planogramId);
        }
      },
      deep: true
    },
    showOnlyCritical() {
      this.getPlanogramPaginated(this.planogramId);
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init(){
      const posId = this.posId;
      agent.Planogram.getPlanogram(posId)
        .then(response => {
          this.planograms = response;
          if (response.length > 0) {
            const newId = response[0];
            if (this.planogramId === newId) {
              this.getPlanogramPaginated(newId);
            } else {
              this.planogramId = newId;
            }
          } else {
            this.loading.planograms = false;
          }
        })
    },
    getPlanogramPaginated(id){
      this.loading.planograms = true;
      const params = toApiPagination(this.options, this.search);
      params.showOnlyCritical = this.showOnlyCritical;
      agent.Planogram.getPlanogramPaginated(id, params)
        .then(response => {
          this.loading.planograms = false;
          const planogram = response;
          this.totalItems = planogram.entries.totalItems;
          this.totalPages = planogram.entries.totalPages;
          planogram.entries = planogram.entries.items;
          this.planogram = planogram;
          this.updateNextSelection();
          this.replacePlanogramRoute();
        })
    },
    updateNextSelection() {
      let nextSelection = 1;
      const entries = this.planogram.entries;
      for (let i = 0; i < entries.length; i++) {
        if (entries[i].selection === 0) continue;
        if (nextSelection === entries[i].selection) nextSelection++;
        else break;
      }
      this.nextSelection = nextSelection;
    },
    finalizePlanogram(){
      this.finalizeDialog.loading = true;
      const posId = this.posId
      agent.Planogram.putFinalize(posId)
        .then(() => {
          this.$refs.feedback.handleSuccess(this.$t('planogramFinalizedIndividual'));
          this.init();
        }).catch((error) => {
          this.$refs.feedback.handleError(this.$t('errorFinalizingPlanogramIndividual'), error);
        }).then(() => {
          this.finalizeDialog.loading = false;
          this.finalizeDialog.enabled = false;
        })
    },
    activatePlanogram() {
      this.activateDialog.loading = true;
      this.$refs.feedback.handleInfo(this.$t('activatePlanogramFeedback'));
      const posId = this.posId;
      agent.Planogram.putActivate(posId)
        .then(() => {
          this.$refs.feedback.handleSuccess(this.$t('planogramActivatedIndividual'));
          this.init();
        }).catch((error) => {
          this.$refs.feedback.handleError(this.$t('errorActivatingPlanogramIndividual'), error);
        }).then(() => {
          this.activateDialog.loading = false;
          this.activateDialog.enabled = false;
        })
    },
    getPlanogramDescription(planogram) {
      if (planogram.status === 'Draft' || planogram.status === 'Pending') return this.$t('getPlanogramDescriptionStatus')
      return PlanogramDates.getPlanogramDescriptionFromIsoDates(planogram);
    },
    deletePlanogram() {
      this.removePlanogramDialog.loading = true;
      const id = this.planogram.id;
      agent.Planogram.delete(id)
        .then(() => {
          this.planograms.splice(this.planogramIndex, 1);
          if (this.planograms.length > 0) {
            this.planogramId = this.planograms[0];
          }
          else {
            this.planogram = {
              id: null,
              entries: []
            }
          }
          this.$refs.feedback.handleSuccess(this.$t('planogramRemovedIndividual'));
        }).catch((error) => this.handleError(this.$t('errorRemovingPlanogramIndividual'), error))
        .then(() => {
          this.removePlanogramDialog.loading = false;
          this.removePlanogramDialog.enabled = false;
        })
    },
    onPlanogramCreated(planogram) {
      this.$refs.feedback.handleSuccess(this.$t('planogramCreatedIndividual'));
      this.planogram = planogram;
      this.clearPlanogramRoute();
      this.init();
    },
    onPlanogramCreationError(message, error) {
      this.$refs.feedback.handleError(message, error);
    },
    onEntryCreated() {
      this.getPlanogramPaginated(this.planogramId);
      this.handleSuccess(this.$t('saveSuccessfulIndividual'));
    },
    onEntryUpdated(entry) {
      Object.assign(this.planogram.entries[this.editedIndex], entry);
      this.handleSuccess(this.$t('saveSuccessfulIndividual'));
    },
    onQuantityChanged(change) {
      if (this.planogram.status === 'Active') {
        if (this.planogram.entries.some(e => e.inventoryItemId == null)){
          ((this.planogram.entries||[]).find(e => e.selection == change.selection)||{}).currentQuantity = change.quantity;
        } else {
          ((this.planogram.entries||[]).find(e => e.inventoryItemId == change.inventoryItemId)||{}).currentQuantity = change.quantity;
        }
      }
    },
    onEntryError(message, error) {
      this.handleError(message, error);
    },
    getExcel() {
      this.loading.excel = true;
      this.axios.get(`api/planograms/${this.planogram.id}/excel`, {responseType: 'blob'})
        .then((response) => {
          this.loading.excel = false;
          download(response, 'Relatório_estoque.xlsx');
        })
        .catch(() => {
          this.loading.excel = false
        })
    },
    changeView() {
      this.tableView = !this.tableView;
      this.fastEditView = false;
    },
    checkSnack() {
      return this.planogram.entries.every(e => {
        return this.digitLength(e.selection) === 2;
      });
    },
    digitLength(int) {
      return Math.log(int) * Math.LOG10E + 1 | 0;
    },
    handleSuccess(message) {
      this.$refs.feedback.handleSuccess(message);
      this.itemDialog = false;
      this.removeDialog.enabled = false;
    },
    handleError(message, error) {
      this.$refs.feedback.handleError(message, error);
    },
    nextPlanogram() {
      if (this.planogramIndex != 0) {
        this.planogramId = this.planograms[--this.planogramIndex];
      }
    },
    previousPlanogram() {
      if (this.planogramIndex < this.planograms.length) {
        this.planogramId = this.planograms[++this.planogramIndex];
      }
    },
    clearPlanogramRoute() {
      let newQuery = Object.assign({}, this.$route.query);
      delete newQuery.pog;

      this.$router.replace({ query: newQuery });
    },
    replacePlanogramRoute() {
      if (this.$route.query.pog != this.planogramId) {
        let newQuery = Object.assign({}, this.$route.query);

        if (this.planogramId != null) {
          newQuery.pog = this.planogramId;
        } else {
          delete newQuery.pog;
        }

        this.$router.replace({ query: newQuery });
      }
    },
    contains(a, b){
      return b == null || (a && a.toUpperCase().includes(b.toUpperCase()));
    },
    editQuantity(item) {
      this.editedIndex = this.planogram.entries.indexOf(item);
      this.editedEntry = Object.assign({}, item);
      this.quantityDialog = true;
    },
    getCardColor(fillRatio) {
      if (isNaN(fillRatio)) {
        return '#E0E0E0'
      }
      var color1 = [179,229,252];
      var color2 = [239,154,154];
      var w1 = fillRatio;
      var w2 = 1 - w1;
      var result = [Math.round(color1[0] * w1 + color2[0] * w2),
        Math.round(color1[1] * w1 + color2[1] * w2),
        Math.round(color1[2] * w1 + color2[2] * w2)];
      return `rgb(${result.join()})`;
    },
    editItem(item) {
      this.editedIndex = this.planogram.entries.indexOf(item);
      this.editedEntry = Object.assign({}, item);
      this.itemDialog = true;
    },
    confirmRemoval(item) {
      this.removeDialog.item = item;
      this.removeDialog.enabled = true;
    },
    deleteItem(item){
      this.loading.removeItem = true;
      const planogramId = this.planogram.id;
      const inventoryItemId = item.inventoryItemId;
      const selection = item.selection;
      agent.Planogram.deleteItem(planogramId, inventoryItemId, selection)
        .then(() => {
          const entryIndex = this.planogram.entries.indexOf(item);
          this.planogram.entries.splice(entryIndex, 1);
          this.updateNextSelection();
          this.handleSuccess(this.$t('itemRemovedIndividual'));
          this.totalItems--;
        })
        .catch(error => this.handleError(this.$t('errorRemovingItemIndividual'), error))
        .then(() => {
          this.loading.removeItem = false;
          this.removeDialog.enabled = false;
        });
    },
    addNewItem() {
      if(this.fastEditView == false) {
        this.editedIndex = -1;
        this.editedEntry = {};
        this.itemDialog = true;
      }
      else {
        this.$refs.draftEdition.addNewItem();
      }
    },
    changeFastEdit() {
      if (this.fastEditView == true) {
        this.$refs.draftEdition.openCancelDialog();
      }
      else {
        this.fastEditView = !this.fastEditView;
      }
    },
    endDraftview() {
      this.fastEditView = !this.fastEditView;
      this.init();
    },
    updateDraftView() {
      this.init();
    },
    getWarningColor(planogram, entry) {
      if(planogram.status == "Active") {
        return this.getCardColor(entry.currentQuantity/entry.quantityToSupply)
      }else if(planogram.status == "Draft" && entry.quantityToSupply == null) {
        return "rgb(239,154,154)"
      }
      return "white"
    },
    openProductUnusedDialog(){
      this.productUnused.unusedProductDialog = true;
    },
    closeProductUnusedDialog(){
      this.productUnused.unusedProductDialog = false;
    },
    openCreatePlanogramUnusedProduct(dateInterval, selectedItems){
      this.dateInterval = dateInterval;
      this.selectedItems = selectedItems;
      this.productUnused.createPlanogramDraftDialog = true;
    },
    closeCreatePlanogramUnusedProduct(){
      this.productUnused.createPlanogramDraftDialog = false;
    }
  },
  components: {
    'planogram-creation': PlanogramCreationDialog,
    'planogram-entry': PlanogramEntryDialog,
    'planogram-status': PlanogramStatusChip,
    'planogram-status-description': PlanogramStatusDescriptionDialog,
    'inventory-change': InventoryChangeDialog,
    'planogram-draft-edition': PlanogramDraftEdition,
    'dialog-products-unused': DialogProductsUnused,
    'dialog-create-draft-product-unused': DialogCreateDraftProductUnused
  }
};
</script>

<style scoped>
.text-formatting {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
