export type ChangeOwnershipNewRequest =
  {
    destinationCnpj : string;
    originTenantId: number;
    originGroupId: number;
    destinationClientName?: string;
    destinationClientPhoneNumber? : string;
    pointOfSaleIds: string[];
  }

  export type QuantityOfEnvironmentByCnpjResponse =
    {
      quantity: number;
      tenantId?: number;
      groupId?: number;
    }
  export type PointOfSaleSimplifiedDto ={
    id: number;
    completeName: string;

  }
  export type ChangeOwnershipDto = {
    id: number;
    origincnpj: string;
    destinationCnpj: string;
    requestDate: Date;
    originTenantId: number;
    originGroupId: number;
    destinationTenantId: number;
    destinationGroupId: number;
    adminApprovalStatus: ChangeOwnershipStatus;
    destinationApprovalStatus: ChangeOwnershipStatus;
    adminComment?: string;
    destinationComment?: string;
    pointOfSaleIds: number[];
    pointOfSaleBoxInformation: PointOfSaleBoxInformation[];
    comment?: string;
  }

export type PointOfSaleBoxInformation = {
    customer: string;
    local: string;
    indoorLocation: string;
    systemCode: string;
    serialNumber: string;
    groupId: number;
}

export type ApprovedOwnership ={
  changeOwnershipRequestId: number;
  status: ChangeOwnershipStatus,
  userRequesting: UserRequestingApproval,
  comment?: string;
}

export enum ChangeOwnershipStatus {
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
  NotNeeded = 'NotNeeded',
  DestinationNotCreated = 'DestinationNotCreated',
  CancelledByOrigin = 'CancelledByOrigin'
}

export enum UserRequestingApproval{
  destination,
  franchise
}








