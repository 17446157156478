<template>
  <v-dialog persistent v-model="itemDialog" max-width="1280" @keydown.esc="itemDialog = false">
    <template #activator="{ on: dialog }">
      <v-btn v-on="dialog" color="success" class="ml-2" :loading="loading.newProduct">
        <v-icon left>add</v-icon>{{ $t('dialogTitleNewGrouping') }}
      </v-btn>
    </template>
    <v-card>
      <v-form ref="form" lazy-validation v-model="valid" @submit.prevent="saveItem">
        <v-card-title>
          <span class="title">{{dialogTitle}}</span>
          <product-group-dialog-help></product-group-dialog-help>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-lg>
            <v-layout row wrap>
              <v-flex xs12 md4>
                <editable-image
                  v-if="editedIndex != -1"
                  :image-url="editedEntry.imageUrl"
                  entity-type="product"
                  :entity-id="(editedEntry.id || 0).toString()"
                  @create="(entityId, url) => $emit('image-updated', entityId, url)"
                  @delete="(entityId, url) => $emit('image-updated', entityId, null)"
                ></editable-image>
              </v-flex>
              <v-flex xs12 :md8="editedIndex != -1">
                <v-layout row wrap>
                <v-flex xs12 md6>
                  <v-text-field
                    :label="$t('codeProductGroup')"
                    v-model="editedEntry.code"
                    :rules="[rules.validCharacters]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md6>
                  <v-text-field
                  :label="$t('descriptionProductGroup')"
                    v-model="editedEntry.description"
                    :rules="[rules.required, rules.nonEmpty]"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md6>
                  <crud-select
                  :label="$t('categoryProductGroup')"
                    api-url="api/ProductCategories"
                    v-model="editedEntry.categoryId"
                    :rules="[rules.nonEmpty]"
                    removable
                    clearable
                    item-text="name"
                    item-value="id"
                  ></crud-select>
                </v-flex>
                <v-flex xs12 md6>
                  <v-text-field
                    type="number"
                    min="0"
                    step="0.01"
                    placeholder="0.00"
                    :label="$t('defaultPriceProductGroup')"
                    v-model="editedEntry.defaultPrice"
                    :rules="[rules.float]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-autocomplete
                    :label="$t('associatedProductsProductGroup')"
                    v-model="editedEntry.productIds"
                    :loading="loading.products"
                    :items="products"
                    item-text="searchText"
                    item-value="id"
                    multiple
                    chips
                  ></v-autocomplete>
                </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click.native="itemDialog = false">{{ $t('closeProductGroup') }}</v-btn>
          <v-btn color="primary" type="submit" :disabled="!valid" :loading="loading.newItem">{{ $t('saveProductGroup') }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import CrudSelect from '@/components/CrudSelect';
import EditableImage from '@/components/EditableImage';
import ProductGroupDialogHelp from '@/views/products/ProductGroupDialogHelp';

export default {
  props: {
    value: { type: Boolean, required: true },
    editedIndex: { type: Number, required: true },
    editedEntry: { type: Object },
    posLink: { type: String, default: null },
    planogramLink: { type: String, default: null },
  },
  computed: {
    itemDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    dialogTitle() {
      return this.editedIndex === -1 ? this.$t('dialogTitleNewGrouping') : this.$t('dialogTitleEditProductGroup');
    }
  },
  watch: {
    itemDialog(isOpen) {
      if (!isOpen) {
        this.$refs.form.reset();
        this.editedEntry.ageRestricted = false;
        this.valid = true;
      }
    }
  },
  data() {
    return {
      products: [],
      rules: {
        nonEmpty: (v) => /\S/.test(v) || this.$t('nonEmptyRules'),
        required: (v) => !!v || this.$t('requiredRules'),
        float: (v) => !isNaN(v) || this.$t('floatRules'),
        validCharacters: (v) => /^[\w.\-+ ]*$/.test(v) || this.$t('validCharactersRules'),
        barcode: (v) => /^[A-Za-z0-9]*#*\*?$/.test(v) || this.$t('barCodeRules')
      },
      valid: true,
      loading: {
        newItem: false,
        products: true
      }
    }
  },
  mounted() {
    this.axios
      .get("api/Products", {
        params: { page: 1, pageSize: -1, sortOrder: "id", descending: false },
      })
      .then((response) => {
        this.loading.products = false;
        this.products = response.data.items.map((prod) => {
          prod.searchText =
            (prod.code !== null ? prod.code + " - " : "") + prod.description;
          return prod;
        });
      });
  },
  methods: {
    saveItem() {
      if(this.$refs.form.validate()) {
        this.loading.newItem = true;
        const editedIndex = this.editedIndex;
        const editedEntry = this.editedEntry;
        if(editedIndex === -1) { // is new item
          this.axios.post('api/ProductGroups', editedEntry)
            .then((response) => {
              this.$emit('add', response.data);
            }).catch((error) => this.$emit('error', this.$t('saveProductGroupError'), error))
              .then(() => this.loading.newItem = false);
        } else {
          this.axios.put(`api/ProductGroups/${editedEntry.id}`, editedEntry)
            .then((response) => {
              this.$emit('update', response.data);
            }).catch((error) => this.$emit('error', this.$t('saveItemsProductGroupError'), error))
              .then(() => this.loading.newItem = false);
        }
      }
    }
  },
  components: {
    CrudSelect,
    EditableImage,
    ProductGroupDialogHelp
  }
}
</script>
