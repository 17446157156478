export default {
  clientRequester: 'Cliente Solicitante',
  paymentProcessorAccounts: 'Contas de Adquirentes',
  apiToken: 'Token da API',
  activationCode: 'Código de Ativação',
  noPaymentProcessorAlert: 'Nenhuma conta de adquirente disponível. Cadastre uma para selecionar.',
  registerNewAccount: 'Cadastrar Nova Conta',
  deliveryAddress: 'Endereço para Entrega',
  registerNewAddress: 'Cadastrar Novo ou Editar Endereço',
  address: 'Endereço',
  editAddress: 'Editar Endereço',
  termsAndConditions: 'Termos e Condições',
  termsAndConditionsText: 'Ao fazer seu pedido, você concorda com as Condições de Uso da AMLabs',
  finishOrder: 'Finalizar Pedido',
  fantasyName: 'Nome Fantasia',
  billingDocumentId: 'CNPJ da Empresa',
  customerEmail: 'Email do Cliente',
  customerPhone: 'Telefone do Cliente',
  productAddedToPurchase: 'Produto adicionado para compra',
  midasCloseOrderAddressNumber: "Numero do endereço",
  midasCloseOrderAddress: "Endereço",
  midasCloseOrderAddressComplement: "Complemento",
  midasCloseOrderCep: "CEP",
  midasCloseOrderCity: "Cidade",
  midasCloseOrderState: "Estado",
  noAddressAvailableAlert: "Nenhum endereço disponível. Cadastre um para selecionar.",
  withdrawAtCompany: 'Retirar na empresa: AV. Monte Castelo, 575 - Jardim Proença, Campinas - SP, CEP: 13026-241',
  productSoldByAmLabs: 'Produto vendido pela AMLabs',
  midasCloseOrderQuantity: 'Quantidade',
  midasCloseOrderSave: 'Salvar',
  missingPaymentProcessorError: 'Selecione uma conta PagSeguro para finalizar o pedido',
  missingAddressInformationError: 'Há informações faltando no endereço de entrega. Preencha-as para finalizar o pedido',
  missingPhoneInformationError: 'Há informações faltando no telefone do cliente. Preencha-as para finalizar o pedido',
  missingEmailInformationError: 'Há informações faltando no email do cliente. Preencha-as para finalizar o pedido',
  missingApiTokenInformationError: 'Há informações faltando no token da API. Preencha-as para finalizar o pedido',
  missingActivationCodeInformationError: 'Há informações faltando no código de ativação. Preencha-as para finalizar o pedido',
  cepRules: 'O CEP deve conter 8 dígitos',
  cepNeeded: 'Preencha o CEP',
  midasCloseOrderAddressComplementHint: 'Apartamento/Bloco. Caso contrário, coloque N/A',
  cep:'CEP',
  cepError: 'CEP inválido, Por favor, insira um CEP válido',
  editPagseguroAccount: 'Editar Conta Pagseguro',
  documentIdIsOk:'CNPJ Válido na Receita Federal e Na Inscrição Estadual',
  documentIdIsNotOk:"CNPJ irregular - {documentId} - Status na Receita Federal: {status} e Inscrição Estadual: {ieStatus} Regularize com seu contador/Receita/Sefaz antes de prosseguir com o pedido.",
  missingDocumentIdError:'CNPJ Incorreto ou Inexistente. Por gentileza contate o nosso suporte para verificar',
  statusEnum: {
    Active: "ATIVA",
    Suspended: "SUSPENSA",
    Unqualified: "INAPTA",
    Down: "BAIXADA",
    Null: "NULA",
  },
  ieStatus: {
    Ok: "ATIVA",
    NotOk: "IRREGULAR"
  }
};
