export default {
  security: {
    deviationAlert: {
      title: "Shrink Alerts",
      suspiciousAccess: {
        title: "Suspicious Access",
      },
      suspiciousTransactions: {
        title: "Suspicious Transactions",
        accessDetailsDialogSubtitle: "Suspicious transactions on {date} from {timeStart} to {timeEnd}"
      },
      videoAlerts: {
        title: "Video Alerts",
        videos: "Videos",
      },
      messages: {
        tokenNotActivated: `This environment does not have transaction synchronization with the acquirer enabled.
          Therefore, there may be transactions with results that differ from the acquirer's statement.
          To enable transaction synchronization, please contact our support.`,
        success: {
          updateStatusTransaction: "Status updated transaction successfully",
          downloadRealizeSuccess: "The download is underway and can be downloaded soon ",
        },
        error: {
          choosePosInitialSearch: "Choose a point of sale to start the search",
        },
      },
      tooltips: {
        relatedSuspiciuosTransactions: "Related suspicious transactions",
      },
      resultDeviationStatus: {
        pending: "Pending",
        verifiedOk: "Verified Ok",
        deviation: "Has Shrink",
        refunded: "Refunded",
        lost: "Lost",
      },
      help: {
        description: `
          <p>
           On this page, you will find a record of all interactions made with the payment terminal, including paid, canceled, or unsuccessful payment transactions, such as in cases of connection drops or incorrect payment method selection.
It is also possible to adjust the verification status for control and shrinkage analysis, and if the integration is enabled, access transaction videos.
          </p>
          <p>
            <strong>Suspicious Transactions:</strong>
          </p>
          <ul>
            <li>
              In suspicious transactions, you have a view of all transaction attempts at the POS, showing canceled transactions
              <i class="mdi mdi-minus-circle error--text" style="font-size:22px"></i>
              and paid transactions
              <i class="mdi mdi-check-circle success--text" style="font-size:22px"></i>;
            </li>
            <li>
              You can see more details of the attempts by clicking to expand the transaction, where you can see the products
              added to the cart at the time of purchase, along with products later removed, marked in red;
            </li>
            <li>
              Transactions will appear by default with the verification status "Pending," and depending on the action you select,
              this status will change. The actions are:
              <ul>
                <li>
                  <i class="mdi mdi-check-circle success--text" style="font-size:22px"></i>
                  : indicates that there were no shrinkage and the transaction is OK.
                </li>
                <li>
                  <i class="mdi mdi-alert error--text" style="font-size:22px"></i>
                  : indicates that a shrinkage was identified.
                </li>
                <li>
                  <i class="mdi mdi-cash-multiple success--text" style="font-size:22px"></i>
                  : indicates that the shrinkage was refunded.
                </li>
                <li>
                  <i class="mdi mdi-close-circle error--text" style="font-size:22px"></i>
                  : indicates that the shrinkage will not be refunded and is considered a loss.
                </li>
                <li>
                  <i class="mdi mdi-download success--text" style="font-size:22px"></i>
                  : when triggered, starts the attempt to download the video.
                  <strong>It is very important that this button is pressed only once to avoid system slowdown.</strong>
                </li>
                <li>
                  <i class="mdi mdi-video info--text" style="font-size:22px"></i>
                  : will be displayed if the download is completed successfully, and when triggered,
                  allows viewing of the downloaded video.
                </li>
              </ul>
            </li>
          </ul>
          <br>
          <p>
            <strong>Suspicious Access:</strong>
          </p>
          <ul>
            <li>
             In suspicious accesses, all times when there was access via the access control module and no transaction was
              notified within 5 minutes will appear;
            </li>
            <li>
               You can perform the same actions as Suspicious Transactions, with the option to download the video from the access moment;
            </li>
            <li>
              Clicking the <i class="mdi mdi-magnify info--text" style="font-size:22px"></i> button
              next to the date, you can view the 5 transactions that occurred after the access within a period of 1 hour,
              allowing you to download the video, take actions, and check transaction details.
            </li>
          </ul>
          <br>
          <p>
            <strong>Video Alerts:</strong>
          </p>
          <ul>
            <li>
              You can access recordings from each POS camera separately.
            </li>
          </ul>
          <br>
        `,
        alert: 'To access videos of a point-of-sale transaction, video integration must be enabled.'
      },
    },
    accessControl: {
      title: "Access Control",
      help: {
        description: `
        <p>
          Here, all access to each lock is displayed, along with the telemetry of each device, all in separate tabs.
        </p>
        <p>
           Starting with the <strong>Access List</strong> tab, you will see a list of the accesses made, along with the result of the action, which can be <strong style='color: red'>Error</strong> or <strong style='color: green'>Success</strong>.
          By expanding the row by clicking on <i class="material-icons" style="vertical-align: middle; margin-left: 2px;">keyboard_arrow_down</i>, you will see a list with information about the person responsible for opening the lock, the name of the lock and the device, as well as the specific error that occurred during the opening, if any.
        </p>
        <p>
           Moving to the <strong>Telemetry</strong> tab, you will see relevant telemetry data with information about the customer, device, and its status.
        </p>
        `,
      },
      tooltips: {
        blockedUser: "Block user in store",
        unblockedUser: "Unblock user in store",
      },
      messages: {
        blocked: "Blocked",
        unblocked: "Unblocked",
        success: "Success while performing {action} for {fullName}.",
        error: "Error while performing {action}."
      },
    }
  }
}
