import {ref} from "vue";

export type Catalog = {
  description: string;
  tenantId: number;
  items: CatalogItem[];
}

export type CatalogItem = {
  id: number;
  name: string;
  description: string;
  technicalInformation: string;
  imageUrl: string;
}

export const catalogItemInitialState = ref<CatalogItem>({
  id: 0,
  name: '',
  description: '',
  technicalInformation: '',
  imageUrl: ''
});

export type EnvironmentInformation = {
  contractId: number;
  billingDocumentId: string | null;
  customerInfo: CustomerInfo;
}

export const environmentInfoInitialState = ref<EnvironmentInformation>({
  contractId: 0,
  billingDocumentId: null,
  customerInfo: {
    city: null,
    cep: null,
    address: null,
    addressNumber: null,
    brazilState: null,
    phone: null,
    fantasyName: null,
    email: null
  },
});

export type CustomerInfo = {
  city: string | null;
  cep: string | null;
  address: string | null;
  addressNumber: number | null;
  addressComplement?: string | null;
  brazilState: string | null;
  phone: string | null;
  fantasyName: string | null;
  email: string | null;
}

export type CustomerInfoForProfileDto = {
  Email: string | null;
  Phone: string | null;
  DateOfBirth: Date | null;
  AddressTypes: number[] | null;
  NeedsToUpdateInfo: boolean;
}

export enum AddressTypes {
  Street = 0,
  Condominium = 1,
  Company = 2,
  Other = 3
}

export type AcquisitionOrderRequest = {
  orderItems: AcquisitionOrderItemRequest[];
  tenantId: number;
  groupId?: number;
  documentId?: string | null;
  fantasyName: string | null;
  email: string | null;
  phoneNumber: string | null;
  deliveryAddress: DeliveryAddress | null;
  paymentProcessorDto: PaymentProcessor;
  paymentProcessorId: number | null;
}

export const acquisitionOrderRequestInitialState = ref<AcquisitionOrderRequest>({
  orderItems: [],
  tenantId: 0,
  groupId: 0,
  fantasyName: null,
  email: null,
  phoneNumber: null,
  deliveryAddress: null,
  paymentProcessorDto: {id: 0, email: null, activationCode: null, apiToken: null, processorCompany: null},
  paymentProcessorId: 0
});

export type ValidateDocumentIdDto = {
  documentId: string;
  status: CnpjStatus;
  ieIsValid: boolean;
}

export type DeliveryAddress = {
  city: string | null;
  cep: string | null;
  address: string | null;
  addressNumber: number | null;
  addressComplement: string | null;
  brazilState: string | null;
}

export type AcquisitionOrderItemRequest = {
  quantity: number;
  catalogItemId: number;
}

export type PaymentProcessor = {
  id: number | null;
  email: string | null;
  activationCode: string | null;
  apiToken: string | null;
  processorCompany: PaymentProcessorCompany | null;
}

export enum PaymentProcessorCompany {
  PagSeguro,
  Rede,
  Stone,
}

export const paymentProcessorInitialState = ref<PaymentProcessor>({ id: 0, email: null, activationCode: null, apiToken: null, processorCompany: null });

export enum CnpjStatus {
  Active,
  Suspended,
  Unqualified,
  Down,
  Null,
}

