<template>
  <v-dialog persistent v-model="itemDialog" max-width="960" @keydown.esc="itemDialog = false">
    <template #activator="{ on: dialog }">
      <v-btn :loading="loading.button" class="ml-2" color="success" v-on="dialog">
        <v-icon left>add</v-icon> {{ translations.promotionNew }}
      </v-btn>
    </template>

    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="savePromotion">
      <v-card class="pa-5">
        <v-card-text class="pl-5 pr-5">
          <v-card-title class="ma-1 pa-0">
            <span class="title">{{ dialogTitle }}</span>
          </v-card-title>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field :label="translations.promotionName" v-model="form.description"
                :rules="[$validations.required, $validations.nonEmpty]" counter maxlength="30" />
            </v-col>
            <v-col cols="12" md="6" >
              <DiscountType
                v-model="form.type"
                prependIcon=""
                :rules="[$validations.required, $validations.nonEmpty]"
                :clearable="false"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <SingleDatePicker
                v-model="form.startsOn"
                :label="translations.promotionStartsOn"
                :rules="[$validations.required]"
                :min-date="$moment().format('YYYY-MM-DD')"
                @input="onStartDate"
              />
            </v-col>
            <v-col cols="12" md="6">
              <SingleDatePicker
                v-model="form.expiresOn"
                :label="translations.promotionExpiresOn"
                :min-date="minExpireDate"
                @input="onEndDate"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="5" class="ma-0 pt-0">
              <v-checkbox v-model="applyToEveryPointOfSale" :label="translations.pointOfSaleForAllLabel" />
            </v-col>
            <v-col cols="12" md="5" class="ma-0 pt-0">
              <v-checkbox v-model="applyToSpecificPointsOfSale" :label="translations.pointOfSaleForSpecificLabel" />
            </v-col>
          </v-row>
          <v-row v-if="applyToSpecificPointsOfSale">
            <v-col cols="12" class="ma-0 pt-0 pb-0">
              <PointOfSalesMultipleSelector
                v-model="selectedPointsOfSaleIds"
                :points-of-sale-data="pointsOfSale"
                :label="translations.pointOfSaleChooseLabel"
                :rules="[validatePointOfSales]"
                chips
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text class="pl-5 pr-5 pa-0 mb-0">
          <v-card-title class="ma-1 pa-0">
            <span class="title">{{ translations.productSectionTitle }}</span>
          </v-card-title>
          <v-row class="px-2">
            <v-col>
              <v-data-table
                class="pb-4"
                :headers="headers"
                :items="collectionOfRulesPerProduct"
                hide-default-footer
                :no-data-text="$t('tables.noData')">
                <template cols="12" md="6" class="search-field" #item.productId="{ item }">
                  <ProductDiscountFilter
                    v-model="item.productId"
                    :item-id="item.id"
                    :rules="[rules.nonEmpty, rules.required]"
                    :label="translations.productLabel"
                    :discount-products="discountProducts"
                    @product-changed="onProductSelected"
                    @products-searched="onProductSearched"
                  />
                </template>
                <template cols="12" md="6" #item.quantity="{ item }">
                  <v-tooltip :disabled="!item.disabled" bottom open-delay="50">
                    <template #activator="{ on: tooltip }">
                      <div v-on="tooltip">
                        <v-text-field
                          v-model="item.quantity"
                          type="number"
                          min="1"
                          required
                          :label="translations.productItemsLabel"
                          :rules="[$validations.integer]"
                          :disabled="item.disabled"
                          @input="changeItemQuantity(item)"
                        />
                      </div>
                    </template>
                    {{ translations.chooseAProduct }}
                  </v-tooltip>
                </template>
                <template cols="12" md="6" #item.amount="{ item }">
                  <v-tooltip :disabled="!item.disabled" bottom open-delay="50">
                    <template #activator="{ on: tooltip }">
                      <div v-on="tooltip">
                        <v-text-field
                          v-model="item.amount"
                          type="number"
                          required
                          min="0"
                          :label="translations.productDiscountAmountLabel"
                          :rules="[$validations.positiveFloat, validateDiscount(item)]"
                          :disabled="item.disabled"
                          @input="changeAmount(item)"
                        />
                      </div>
                    </template>
                      {{ translations.chooseAProduct }}
                  </v-tooltip>
                </template>
                <template cols="12" md="6" #item.delete="{ item }">
                  <v-icon @click="removeProduct(item)" color="error">mdi-delete</v-icon>
                </template>
                <template cols="12" md="6" #footer>
                  <v-btn text color="success" class="ml-3 font-weight-bold" @click="addNewProduct()">
                    <v-icon>mdi-plus-circle</v-icon>{{ translations.addAProduct }}
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-row justify="end">
            <v-btn color="primary" text @click.native="itemDialog = false">{{ $t('buttons.cancel') }}</v-btn>
            <v-tooltip top :disabled="valid && collectionOfRulesPerProduct.length != 0">
              <template #activator="{ on }">
                <div v-on="on">
                  <v-btn class="ml-2" type="submit" color="primary"
                    :disabled="!valid || collectionOfRulesPerProduct.length == 0"
                    :loading="loading.button">
                    {{ translations.saveButton }}
                  </v-btn>
                </div>
              </template>
              <span v-if="collectionOfRulesPerProduct.length === 0"> {{ translations.errorMessageProductRequired }} </span>
              <span v-else-if="!valid"> {{ translations.errorMissingData }} </span>
            </v-tooltip>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import agent from '@/api/agent';
import ProductDiscountFilter from '@/discounts/filter/ProductDiscountFilter.vue';
import DiscountType from '@/components/filters/DiscountType.vue';
const _ = require('lodash');
import PointOfSalesMultipleSelector from '@/components/filters/PointOfSalesMultipleSelector.vue';
import SingleDatePicker from '@/components/filters/SingleDatePicker.vue';

const translations_root = "promotion";
const translations_path = `${translations_root}.details`;
const productRuleModel = {
  id: 0,
  productId: '0',
  productCode: '',
  productDescription: '',
  productCategory: '',
  productDefaultPrice: 0,
  quantity: 0,
  amount: 0,
  totalPrice: 0,
  finalPrice: 0,
  assigned: false,
  disabled: true,
};

export default {
  components: { ProductDiscountFilter, PointOfSalesMultipleSelector, SingleDatePicker, DiscountType },
  props: {
    value: { type: Boolean, required: true },
    editedIndex: { type: Number, required: true },
    promotion: { type: Object, required: true },
    pointsOfSale: { type: Array, required: true }
  },
  mounted() {
    this.newForm();
    this.mapPointsOfSale(this.pointsOfSale);
    this.mapPromotion();
  },
  data() {
    return {
      valid: false,
      headers: [
        { text: this.$t(`${translations_path}.product.name`), value: 'productId', align: 'left', sortable: false },
        { text: this.$t(`${translations_path}.product.items`), value: 'quantity', align: 'left', sortable: false },
        { text: this.$t(`${translations_path}.discount.amount.label`), value: 'amount', align: 'left', sortable: false },
        { text: this.$t(`${translations_root}.remove.label`), value: 'delete', align: 'left', sortable: false },
      ],
      rules: {
        nonEmpty: (v) => {
          return /\S/.test(v) && v !== '-1' || this.$t('requiredRules')
        },
        required: (v) => !!v && v !== '-1' || this.$t('requiredRules')
      },
      form: {
        type: '',
        startsOn: null,
        expiresOn: null,
        description: '',
        discountProductRules: [],
        discountPointOfSaleRules: [],
        usage: 0,
      },
      loading: {
        button: false
      },
      applyToEveryPointOfSale: true,
      applyToSpecificPointsOfSale: false,
      selectedPointsOfSaleIds: [],

      collectionOfRulesPerProduct: [],
      collectionOfDiscountPointOfSaleRules: [],
      discountProducts: [],
      translations: {
        promotionName: this.$t(`${translations_root}.name`),
        promotionType: this.$t(`${translations_path}.discount.type.text`),
        promotionNew: this.$t(`${translations_path}.new.text`),
        promotionNewError: this.$t(`${translations_path}.new.error`),
        promotionEdit: this.$t(`${translations_path}.edit.text`),
        promotionEditError: this.$t(`${translations_path}.edit.error`),
        promotionTitle: this.$t(`${translations_path}.title`, { description: this.form?.description }),
        promotionStartsOn: this.$t(`${translations_path}.starts-on-label`),
        promotionExpiresOn: this.$t(`${translations_path}.expires-on-label`),
        promotionExpiresOnNoDate: this.$t(`${translations_path}.expires-on-no-date`),
        pointOfSaleForAllLabel: this.$t(`${translations_path}.points-of-sale.all`),
        pointOfSaleForSpecificLabel: this.$t(`${translations_path}.points-of-sale.specific`),
        pointOfSaleChooseLabel: this.$t(`${translations_path}.points-of-sale.choose`),
        pointOfSaleForSpecificError: this.$t(`${translations_path}.points-of-sale.specific-error`),
        productSectionTitle: this.$t(`${translations_path}.product.add`),
        productLabel: this.$t(`${translations_path}.product.name-label`),
        productItemsLabel: this.$t(`${translations_path}.product.items-label`),
        productDiscountAmountLabel: this.$t(`${translations_path}.discount.amount.label`),
        amountPercentage: this.$t(`${translations_path}.discount.amount.percentage`),
        amountFixed: this.$t(`${translations_path}.discount.amount.fixed`),
        discountInvalid: this.$t(`${translations_path}.discount.invalid`),
        addAProduct: this.$t(`${translations_path}.product.button-add`),
        chooseAProduct: this.$t('chooseAProduct'),
        errorMessageProductRequired: this.$t(`${translations_path}.product.error.required`),
        errorMissingData: this.$t(`${translations_path}.missing-data`),
        clearButton: this.$t('buttons.clear'),
        saveButton: this.$t('saveButton')
      },
    }
  },
  watch: {
    itemDialog(isOpen) {
      if (!isOpen) {
        this.newForm();
        this.discountProducts = [];
        this.selectedPointsOfSaleIds = [];
        this.applyToEveryPointOfSale = true;
        this.collectionOfRulesPerProduct = [];
        this.$refs.form.resetValidation();
        this.valid = true;
        return;
      } else if (this.promotion) {
        this.mapPromotion();
      }
    },
    applyToEveryPointOfSale(value) {
      this.applyToSpecificPointsOfSale = !value;
    },
    applyToSpecificPointsOfSale(value) {
      this.applyToEveryPointOfSale = !value;
    },
    'form.type'(value) {
      let headerLabel;
      if (value === 'Percentage') {
        headerLabel = this.translations.amountPercentage;
      } else {
        headerLabel = `${this.translations.amountFixed} ${this.$currencyFormatter.getSign()}`;
      }
      this.headers[2].text = headerLabel;

    },
    pointsOfSale(value) {
      this.mapPointsOfSale(value);
    },
  },
  computed: {
    itemDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    dialogTitle() {
      return this.editedIndex === -1 ? this.translations.promotionNew : this.translations.promotionTitle;
    },
    validatePointOfSales() {
      if (this.applyToSpecificPointsOfSale && this.selectedPointsOfSaleIds.length === 0) {
        return this.translations.pointOfSaleForSpecificError;
      }
      return true;
    },
    minExpireDate() {
      const minimumDate = this.$moment(this.form.startsOn).isSameOrAfter(this.$moment()) ? this.form.startsOn : this.$moment();
      return this.$moment(minimumDate).format("YYYY-MM-DD");
    },
  },
  methods: {
    mapPointsOfSale(incomingPointsOfSale) {
      if(!incomingPointsOfSale) {
        return;
      }
      let pointsOfSale = [];
      pointsOfSale = _.cloneDeep(incomingPointsOfSale);
      this.collectionOfDiscountPointOfSaleRules = pointsOfSale.map(item => {
        item.text = `(PDV ${item.id})`
          + (item.localCustomerName != null ? ` ${item.localCustomerName}` : '')
          + ` - ${item.localName}`
          + (item.specificLocation != null ? ` - ${item.specificLocation}` : '');
        return item;
      });
    },
    newForm() {
      this.form = {
        type: 'Percentage',
        startsOn: this.$moment().format('YYYY-MM-DD'),
        expiresOn: null,
        description: '',
        discountProductRules: [],
        discountPointOfSaleRules: [],
        usage: 0,
      };
    },
    mapPromotion() {
      const promotion = _.cloneDeep(this.promotion);
      if (!Object.values(promotion).length > 0) {
        return;
      }
      const form = {
        id: promotion.id,
        type: promotion.type,
        startsOn: promotion.startsOn && this.$moment(promotion.startsOn).format('YYYY-MM-DD') || this.form.startsOn,
        expiresOn: promotion.expiresOn && this.$moment(promotion.expiresOn).format('YYYY-MM-DD') || this.form.expiresOn,
        description: promotion.description,
        discountProductRules: [...promotion.discountProductRules],
        discountPointOfSaleRules: [...promotion.discountPointOfSaleRules],
        usage: promotion.usage,
      };
      Object.assign(this.form, form);

      this.selectedPointsOfSaleIds = form.discountPointOfSaleRules.map(pointOfSaleRule => pointOfSaleRule.pointOfSaleId);
      this.applyToSpecificPointsOfSale = form.discountPointOfSaleRules.length > 0;
      this.applyToEveryPointOfSale = !this.applyToSpecificPointsOfSale;
      this.collectionOfRulesPerProduct = _.cloneDeep(form.discountProductRules);

      form.discountProductRules.forEach(productRule => this.mapProduct(productRule));
      this.discountProducts = this.collectionOfRulesPerProduct.map(productRule => ({
        id: productRule.productId,
        code: productRule.productCode,
        description: productRule.productDescription,
        category: productRule.ProductCategory,
        assigned: true,
      }));
    },
    cancelDialog() {
      this.newForm();
      this.discountProducts = [];
      this.selectedPointsOfSaleIds = [];
      this.applyToEveryPointOfSale = true;
      this.collectionOfRulesPerProduct = [];
      this.show = false;
    },
    changeType() {
      this.collectionOfRulesPerProduct.forEach((item) => {
        this.calculateFinalPrice(item);
        if(this.validateDiscount(item) !== true) {
          this.$refs.form.validate();
        }
      });
    },

    onStartDate(date) {
      if(!date) {
        return;
      }
      this.form.startsOn = date;
    },
    onEndDate(date) {
      this.form.expiresOn = date;
    },

    mapProduct(product) {
      if (product.productId === null) {
        return;
      }

      const productRule = this.collectionOfRulesPerProduct.find(rule => rule.id === product.id && product.productId !== '-1');
      product.assigned = product.productId !== '-1';
      if (!product.totalPrice || !product.finalPrice) {
        product.finalPrice = product.productDefaultPrice * product.quantity;
        product.totalPrice = product.productDefaultPrice * product.quantity;
      }
      this.calculateFinalPrice(product);
      if (productRule) {
        product.id = productRule.id;
        Object.assign(productRule, product);
      } else {
        this.collectionOfRulesPerProduct.push(product);
      }
    },

    onProductSearched(products) {
      this.discountProducts = [...this.discountProducts, ...products];
    },
    onProductSelected(product) {
      if (product === null || !product.id) {
        return;
      }
      const mapOfProduct = {
        ...productRuleModel,
        productId: `${product.id}`,
        productCode: product.code,
        productDescription: product.description,
        productCategory: product.category,
        productDefaultPrice: product.defaultPrice,
        quantity: 1,
        finalPrice: product.defaultPrice * 1,
        totalPrice: product.defaultPrice * 1,
        disabled: false,
        id: product.itemId,
      };
      this.mapProduct(mapOfProduct);
    },
    addNewProduct() {
      const collectionLength = this.collectionOfRulesPerProduct.length - 1;
      this.mapProduct({
        ...productRuleModel,
        id: collectionLength >= 0 ? collectionLength + 1 : 0,
        productId: `-1`
      });
    },
    removeProduct(toDelete) {
      this.collectionOfRulesPerProduct = this.collectionOfRulesPerProduct.filter(ruleProduct => ruleProduct !== toDelete);
      this.collectionOfRulesPerProduct.forEach((rule, i) => rule.id = i);
    },
    changeAmount(product) {
      if(this.validateDiscount(product) !== true) {
        return;
      }
      this.mapProduct(product);
    },
    changeItemQuantity(productRule) {
      productRule.totalPrice = productRule.productDefaultPrice * productRule.quantity;
      this.mapProduct(productRule);
    },
    validateDiscount(item) {
      let isDiscountValid;
      if (this.form.type === 'Percentage') {
        isDiscountValid = item.amount <= 100 ? true : this.translations.discountInvalid;
      }
      else {
        isDiscountValid = item.amount <= item.totalPrice ? true : this.translations.discountInvalid;
      }
      return isDiscountValid;
    },
    calculateFinalPrice(product) {
      if (this.form.type === 'Fixed') {
        product.finalPrice = product.totalPrice - product.amount;
      }
      else {
        product.finalPrice = product.totalPrice * (100 - product.amount) / 100;
      }
    },

    savePromotion() {
      if (this.$refs.form.validate()) {
        this.loading.button = true;
        const form = _.cloneDeep(this.form);
        form.startsOn = this.$moment(this.form.startsOn).format('YYYY-MM-DD');
        form.expiresOn = this.form.expiresOn && this.$moment(this.form.expiresOn).format('YYYY-MM-DD') || null;
        this.collectionOfRulesPerProduct = this.collectionOfRulesPerProduct.filter(ruleProduct => ruleProduct.productId !== '-1');
        this.collectionOfRulesPerProduct.forEach((rule, i) => rule.id = i);
        form.discountProductRules = this.collectionOfRulesPerProduct.map((productRule) => {
          const id = form.discountProductRules.find(savedRule => savedRule.productId === productRule.productId && savedRule.id === productRule.id)?.id;
          return {
            id: id || 0,
            productId: productRule.productId,
            quantity: Number(productRule.quantity) || 0,
            amount: Number(productRule.amount) || 0
          }
        });
        if (this.applyToSpecificPointsOfSale) {
          form.discountPointOfSaleRules = this.selectedPointsOfSaleIds.map((pointOfSaleId) => {
            const pointOfSale = form.discountPointOfSaleRules.find(rule => rule.pointOfSaleId === pointOfSaleId);
            if (pointOfSale !== undefined) {
              return {
                id: pointOfSale.id,
                pointOfSaleId: pointOfSaleId,
                discountBaseId: form.id
              }
            } else {
              return {
                id: 0,
                pointOfSaleId: pointOfSaleId,
                discountBaseId: 0
              }
            }
          });
          this.form.discountPointOfSaleRules = form.discountPointOfSaleRules;
        } else {
          form.discountPointOfSaleRules = [];
          this.form.discountPointOfSaleRules = [];
        }
        if(form.discountProductRules.findIndex(p => p.quantity < 1) !== -1) {
          this.$refs.form.validate();
          this.loading.button = false;
          return;
        }
        const editedIndex = this.editedIndex;
        if (editedIndex === -1) {
          agent.Discounts.create(form)
            .then((response) => {
              this.form.discountProductRules = this.collectionOfRulesPerProduct;
              this.form.id = response.id;
              this.$emit('created', this.form);

              this.discountProducts = [];
              this.collectionOfRulesPerProduct = [];
            })
            .catch((error) => this.$emit('error', this.translations.promotionNewError, error))
            .then(() => this.loading.button = false);
        } else {
          agent.Discounts.update(form.id, form)
            .then(() => {
              this.form.discountProductRules = this.collectionOfRulesPerProduct;
              this.$emit('edited', this.form);

              this.discountProducts = [];
              this.collectionOfRulesPerProduct = [];
            })
            .catch((error) => this.$emit('error', this.translations.promotionEditError, error))
            .finally(() => this.loading.button = false);
        }
      }
    },
  },
}
</script>

<style></style>
