export default {
  approvalTab: 'PEDIDO PARA APROVAÇÃO',
  search: 'Pesquisar',
  idAcquisitionOrder: 'Código da Solicitação',
  fantasyNameAcquisitionOrder: 'Nome Fantasia',
  documentIdAcquisitionOrder: 'CNPJ',
  emailAcquisitionOrder: 'E-mail',
  phoneNumberAcquisitionOrder: 'Telefone',
  dateCreatedAcquisitionOrder: 'Data de Criação',
  taxArrangementAcquisitionOrder: 'Regime Tributário',
  statusAcquisitionOrder: 'Status',
  itensDetailsAcquisitionOrder: 'Detalhes dos Itens',
  commentsAcquisitionOrder: 'Comentarios',
  attention: 'ATENÇÃO',
  image: 'Imagem',
  name: 'Nome',
  quantity: 'Quantidade',
  description: 'Descrição',
  technicalInformation: 'Informações Técnicas',
  itemType: 'Tipo do Item',
  Leased: 'Comodato',
  Owned: 'Venda',
  PessoaFisica: 'Pessoa Física',
  SimplesNacional: 'Simples Nacional',
  Mei: 'MEI',
  Other: 'Outro',
  NotSpecified: 'Não Especificado',
  ErrorOnCheck: 'Erro na Verificação',
  areYouSureReject: 'Tem certeza de que deseja reprovar este pedido? Essa ação é irreversível e exigirá que o solicitante reinicie todo o processo.',
  areYouSureApprove: 'Tem certeza de que deseja aprovar este pedido? Essa ação é irreversível.' ,
  areYouSurePending: 'Adicionar um Comentario?',
  reasonLabel: 'Deixe um comentário relatando o motivo',
  reasonText: 'Digite aqui o motivo',
  Approved: 'Aprovado',
  Cancelled: 'Rejeitado',
  requiredRules: 'Campo obrigatório Para Rejeitar',
  errorLoadingData: 'Erro ao carregar dados',
  errorUpdatingAcquisitionOrder: 'Erro ao atualizar a solicitação',
  sucessUpdatingAcquisitionOrder: 'Solicitação atualizada com sucesso',
  save: 'Salvar',
  cancel: 'Cancelar',
};
