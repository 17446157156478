<template>
  <v-expansion-panels v-model="panel" hover>
    <v-expansion-panel>

      <v-expansion-panel-header>
        <div><v-icon>filter_list</v-icon> {{ $t('filters.filtersActions') }}</div>
        <v-spacer></v-spacer>
      </v-expansion-panel-header>

      <v-expansion-panel-content class="pb-2">
        <v-form ref="form" v-model="valid">

          <!-- Filters Row -->
          <v-row class="pt-5">

            <!-- 1st collumn -->
            <v-col cols="12" md="3" class="search-field">

              <v-col class="search-field">
                <v-menu
                  class="pr-2"
                  :close-on-content-click="true"
                  v-model="hasMinDate"
                  transition="scale-transition"
                  offset-y
                  :nudge-right="40"
                  max-width="290px"
                  min-width="290px"
                >
                  <template #activator="{ on }">
                    <v-text-field
                      v-on="on"
                      :label="$t('filters.labels.initialDate') "
                      :rules="[rules.required]"
                      required
                      v-model="formattedMinDate"
                      prepend-icon="date_range"
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.minDate"
                    no-title
                    scrollable
                    actions
                    locale="pt-br"
                    :allowed-dates="getAllowedMinDates"
                  >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="clearMinDate"
                        >{{ $t('buttons.clear') }}</v-btn
                      >
                    </v-card-actions>
                  </v-date-picker>
                </v-menu>
              </v-col>

              <v-col class="search-field">
                <v-menu
                  :close-on-content-click="true"
                  v-model="hasMaxDate"
                  transition="scale-transition"
                  offset-y
                  :nudge-right="40"
                  max-width="290px"
                  min-width="290px"
                >
                  <template #activator="{ on }">
                    <v-text-field
                      v-on="on"
                      :label="$t('filters.labels.finalDate') "
                      :rules="[rules.required]"
                      required
                      v-model="formattedMaxDate"
                      prepend-icon="event_available"
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.maxDate"
                    no-title
                    scrollable
                    actions
                    locale="pt-br"
                    :allowed-dates="getAllowedMaxDates"
                  >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="clearMaxDate"
                        >{{ $t('buttons.clear') }}</v-btn
                      >
                    </v-card-actions>
                  </v-date-picker>
                </v-menu>
              </v-col>

            </v-col>

            <!-- 2nd collumn -->
            <v-col cols="12" md="3" class="search-field">

              <v-col class="search-field">
                <inventories-drop-down
                  v-model="form.inventoryIds"
                  :multiple="true"
                ></inventories-drop-down>
              </v-col>

              <v-col class="search-field">
                <products-filter
                  icon="shopping_cart"
                  v-model="form.productId"
              ></products-filter>
              </v-col>

            </v-col>

            <!-- 3rd collumn -->
            <v-col cols="12" md="6" class="search-field">
              <operations-drop-down
                v-model="form.operations"
                @input="changeOperations"
              ></operations-drop-down>
            </v-col>
          </v-row>

          <!-- Buttons Row -->
          <v-row justify="end">
            <v-btn
              class="mx-2"
              text
              @click.native="clearQuery"
              color="error"
              :disabled="!valid"
            >
              <v-icon left>clear</v-icon>{{ $t('buttons.clear') }}
            </v-btn>
            <v-btn
                :loading="loading.excel"
                class="me-2"
                color="info"
                @click.native="getExcel"
            >
            <v-icon left>mdi-file-excel</v-icon>
            {{ $t('buttons.excel') }}
            </v-btn>
            <v-btn
              color="success"
              :disabled="!valid"
              v-on:click.native="emitModification"
            >
              <v-icon left>refresh</v-icon>{{ $t('buttons.update') }}
            </v-btn>
          </v-row>
        </v-form>
        <download-dialog
          v-model="downloadDialog.show"
          :fileName="downloadDialog.fileName"
          @update="(value) => downloadDialog.show = value"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import moment from "moment";
import { getColorInventoryTransactionStatus } from '../utility/inventoryUtility';
import { inventoryOperationTypes } from '@/constants/InventoryOperationTypes';
import OperationsDropDown from '@/components/filters/OperationsDropDown';
import InventoriesDropDown from '@/components/filters/InventoriesDropDown.vue';
import { Inventory } from '@/api/agent';
import DownloadDialog from "@/components/common/DownloadDialog.vue";
import ProductsFilter from '@/components/filters/ProductsFilter.vue';


export default {
  components: {
    OperationsDropDown,
    InventoriesDropDown,
    DownloadDialog,
    ProductsFilter
  },
  data() {
    return {
      valid: false,
      hasMinDate: null,
      hasMaxDate: null,
      products: [],
      panel: null,
      form: {
        minDate: null,
        maxDate: null,
        inventoryIds: [],
        productId: null,
        operations: [],
      },
      loading: {
        excel: false
      },
      rules: {
        required: (v) => !!v || this.$t('validations.requiredField'),
      },
      downloadDialog: {
        show: false,
        fileName: ""
      },
    };
  },
  computed: {
    formattedMinDate: function() {
      return this.formatDate(this.form.minDate);
    },
    formattedMaxDate: function() {
      return this.formatDate(this.form.maxDate);
    },
    operations: function() {
      return Object.keys(inventoryOperationTypes)
        .map(k => ({ text: inventoryOperationTypes[k], value: k }));
    }
  },
  watch: {
  },
  mounted(){
    const maxDate = new Date();
    const minDate = new Date(maxDate.getTime());
    minDate.setDate(minDate.getDate() - 30);
    this.form.maxDate = moment(maxDate).format("YYYY-MM-DD");
    this.form.minDate = moment(minDate).format("YYYY-MM-DD");

    let params = Object.assign({}, this.$route.query);
    if (Object.keys(params).length != 0) {

      this.panel = 0;
      this.form.productId = params.productId && parseInt(params.productId);
      this.form.inventoryIds = [params.inventoryId && parseInt(params.inventoryId)]

      delete params.productId;
      delete params.inventoryId;
      this.$router.replace({ query: params });
    }

    this.$emit("modify", this.form);

  },
  methods: {

    getColor(status) {
      return getColorInventoryTransactionStatus(status)[0];
    },
    isDarkChip(status) {
      return getColorInventoryTransactionStatus(status)[1];
    },
    emitModification() {
      if (this.$refs.form.validate()) {
        this.$emit("modify", this.form);
      }
    },
    newForm() {
      return {
        minDate: this.form.minDate,
        maxDate: this.form.maxDate,
        inventoryIds: [],
        productId: null,
      };
    },
    clearQuery() {
      this.form = this.newForm();
      this.emitModification();
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    clearMinDate() {
      this.form.minDate = null;
      this.formattedMinDate = null;
    },
    clearMaxDate() {
      this.form.maxDate = null;
      this.formattedMaxDate = null;
    },
    getAllowedMinDates(minDate) {
      if (this.form.maxDate !== null) {
        return this.isDateRangeAllowed(
          new Date(minDate),
          new Date(this.form.maxDate)
        );
      }
      return true;
    },
    getAllowedMaxDates(maxDate) {
      if (this.form.minDate !== null) {
        return this.isDateRangeAllowed(
          new Date(this.form.minDate),
          new Date(maxDate)
        );
      }
      return true;
    },
    isDateRangeAllowed(min, max) {
      return max >= min && Math.round(max - min) / (1000 * 60 * 60 * 24) < 90;
    },
    changeOperations(value) {
      this.form.operations = value;
    },
    async getExcel() {
      this.loading.excel = true;
      let params = {
        minDate: this.form.minDate,
        maxDate: this.form.maxDate,
        inventoryIds: this.form.inventoryIds,
        productId: this.form.productId,
        operations: this.form.operations,
        timezoneOffset: new Date().getTimezoneOffset(),
      };
      try {
        const response = await Inventory.getExcelInventoryTransactions(params);
        this.downloadDialog.show = true;
        this.downloadDialog.fileName = response;
      } 
      catch(error){
        (this).$refs.feedback.handleError(this.$t('inventory.inventoryTransaction.messages.error.generateFile'), error);
      }
      finally {
        this.loading.excel = false;
      }
    }
  },
};
</script>