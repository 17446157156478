export default {
  editLockLockForm: 'Editar trava',
  deviceNameLockForm: 'Nome do dispositivo',
  deviceNameLockErrorForm: 'Padrão incorreto! Exemplos com o padrão correto: "TPLockAA00", "MMD-1234"',
  deviceNameHintLockForm: 'O nome deve ser exatamente igual ao do dispositivo Bluetooth',
  lockNameLockForm: 'Nome da trava',
  lockNameHintLockForm: 'Nome para você identificar a trava, ex: Geladeira bebidas, Porta principal',
  ageRestrictedLockForm: 'Acesso restrito +18',
  ageRestrictedHint: 'Desbloqueável somente por maiores de 18 anos',
  closeLockForm: 'Fechar',
  saveLockForm: 'Salvar',
  registerLockForm: 'Cadastrar',
  successEditMessage: 'Cadastro da trava editado!',
  successAddMessage: 'Trava cadastrada!',
  errorMessageEdit: 'Não foi possível atualizar o cadastro da trava',
  errorMessageAdd: 'Não foi possível cadastrar a trava',
  addLockLockForm: 'Cadastrar trava',
  deviceUnlockTime: 'Tempo de abertura do dispositivo',
  deviceUnlockTimeSeconds: '{time} segundos',
  deviceUnlockTimeMinute: '{time} minuto',
  deviceUnlockPulse: 'Instantânea (pulso)',
  deviceUnlockBehavior: 'O que fazer quando o modulo estiver desconectado',
  keepOpenedOnDisconnect: 'Manter trava aberta ao desconectar',
  keepClosedOnDisconnect: 'Manter trava fechada ao desconectar',
  deviceUnlockBehaviorTitle: 'Configuração da Trava em Caso de Falha',
  deviceUnlockBehaviorHint: 'Esta função permite definir o comportamento da trava em situações em que o módulo de acesso perde a comunicação.',
  deviceUnlockBehaviorTypesTitle: 'Você pode escolher:',
  deviceUnlockBehaviorTypesKeepClosed: 'Manter a trava ativa: A porta permanecerá travada, garantindo segurança, mas impedindo o acesso.',
  deviceUnlockBehaviorTypesKeepOpened: 'Desativar a trava: A porta ficará destravada, permitindo acesso mesmo sem comunicação com o módulo.',
}
