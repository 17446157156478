<template>
  <v-expansion-panels v-model="expansionIndex" hover>
    <v-expansion-panel>

      <v-expansion-panel-header>
        <template>
          <div><v-icon>filter_list</v-icon>{{ $t('titleTransactionsReportsFilters') }}</div>
          <v-spacer></v-spacer>
        </template>
      </v-expansion-panel-header>

      <v-expansion-panel-content class="pb-2">
        <v-form ref="form" v-model="valid">

          <!-- Date Row -->
          <v-row class="pt-5">

            <!-- MinDate column -->
            <v-col cols="12" md="3">
              <v-menu
                class="pr-2"
                :close-on-content-click="true"
                v-model="hasMinDate"
                transition="scale-transition"
                offset-y
                :nudge-right="40"
                max-width="290px"
                min-width="290px"
              >
                <template #activator="{ on }">
                  <v-text-field
                    v-on="on"
                    :label="$t('startDateTransactionsReportsFilters')"
                    :rules="[rules.required]"
                    required
                    v-model="formattedMinDate"
                    prepend-icon="date_range"
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.minDate"
                  no-title
                  scrollable
                  actions
                  locale="pt-br"
                  :allowed-dates="getAllowedMinDates"
                  @input="changeDate()"
                >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="clearMinDate"
                      >{{ $t('clearTransactionsReportsFilters') }}</v-btn
                    >
                  </v-card-actions>
                </v-date-picker>
              </v-menu> 
            </v-col>

            <!-- MaxDate column  -->
            <v-col cols="12" md="3">
              <v-menu
                :close-on-content-click="true"
                v-model="hasMaxDate"
                transition="scale-transition"
                offset-y
                :nudge-right="40"
                max-width="290px"
                min-width="290px"
              >
                <template #activator="{ on }">
                  <v-text-field
                    v-on="on"
                    :label="$t('endDateTransactionsReportsFilters')"
                    :rules="[rules.required]"
                    required
                    v-model="formattedMaxDate"
                    prepend-icon="event_available"
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.maxDate"
                  no-title
                  scrollable
                  actions
                  locale="pt-br"
                  :allowed-dates="getAllowedMaxDates"
                  @input="changeDate()"
                >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="clearMaxDate"
                      >{{ $t('clearTransactionsReportsFilters') }}</v-btn
                    >
                  </v-card-actions>
                </v-date-picker>
              </v-menu>
            </v-col>

            <!-- Date intervals chips -->
            <v-col cols="12" md="6" class="search-field px-2">
              <v-row class="ma-0">
                <p class="search-field my-0">
                  {{ $t('dateIntervalFilterNamesTranslations') }}
                </p>
              </v-row>
              <div class="ma-0 pa-0">
                <v-chip-group
                  active-class="deep-purple--text text--accent-4"
                  show-arrows
                  v-model="definedDateInterval"
                  @change="changeDateInterval(); selectedSavedReport = null"
                >
                  <v-chip
                    outlined
                    filter
                    v-for="(interval, index) in dateIntervals"
                    :key="index"
                    :value="interval.value"
                  >
                    {{ interval.text }}
                  </v-chip>
                </v-chip-group>
              </div>
            </v-col>
          
          </v-row>

          <!-- Location Row -->
          <v-col class="px-0">
            <v-expansion-panels flat hover>
              <v-expansion-panel class="px-0">
                <v-expansion-panel-header class="search-field px-0">
                  <template>
                  <div>
                    <v-icon>mdi-map-marker</v-icon>{{ $t('locationTransactionsReportsFilters') }}
                  </div>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="px-0">
                  <v-row cols="12" class="px-0">
                    <v-col cols="3">
                      <v-autocomplete
                        prepend-icon="mdi-earth"
                        :items="brazilStates"
                        v-model="form.addressState"
                        :label="$t('stateTransactionsReportsFilters')"
                        item-text="name"
                        item-value="value"
                        clearable
                        @input="selectedSavedReport = null"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="3">
                      <v-autocomplete
                        prepend-icon="account_box"
                        :items="customers"
                        v-model="form.customerId"
                        :label="$t('customerTransactionsReportsFilters')"
                        item-text="name"
                        item-value="id"
                        clearable
                        @input="form.localId = null; selectedSavedReport = null"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="3">
                      <v-autocomplete
                        prepend-icon="mdi-map-marker"
                        :label="$t('locationTransactionsReportsFilters')"
                        :items="locations"
                        v-model="form.localId"
                        item-text="name"
                        item-value="id"
                        :loading="loading.location"
                        :hint="form.customerId == null ? $t('selectCustomerFirstTransactionsReportsFilters') : ''"
                        :disabled="form.customerId == null"
                        clearable
                        @change="form.posId = null"
                        @input="form.posId = null; selectedSavedReport = null;"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="3">
                      <v-autocomplete
                        v-model="form.posId"
                        :items="pointsOfSale"
                        prepend-icon="mdi-domain"
                        :label="$t('pointOfSaleTransactionsReportsFilters')"
                        clearable
                        :hint="form.customerId == null ? $t('selectCustomerFirstTransactionsReportsFilters') : ''"
                        :disabled="form.customerId == null"
                        @input="selectedSavedReport = null"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>

          <!-- Product And Saved Reports Row -->
          <v-row cols="12" class="px-0">

            <!-- Products Column -->
            <v-col class="px-0" cols="12" md="12">
              <v-expansion-panels flat hover>
                <v-expansion-panel class="px-3">
                  <v-expansion-panel-header class="search-field px-0">
                    <template>
                    <div>
                      <v-icon>mdi-cart</v-icon>{{ $t('productTransactionsReportsFilters') }}
                    </div>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="px-0">
                    <v-row cols="12" class="px-0">
                      <v-col cols="3">
                        <products-filter
                          icon="shopping_cart"
                          v-model="form.productId"
                          @input="selectedSavedReport = null"
                        ></products-filter>
                      </v-col>
                      <v-col cols="3">
                        <categories-drop-down
                          v-model="form.productCategoryId"
                          ref="categoriesDropDown"
                          @input="selectedSavedReport = null"
                        >
                        </categories-drop-down>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>

          <!-- Group Row -->
          <v-row>
            
            <v-subheader class="pt-5">{{ $t('groupByTransactionsReportsFilters') }}</v-subheader>

            <v-col cols="12" md="12" class="search-field">
              <v-chip-group
                v-model="form.group"
                column
                active-class="deep-purple--text text--accent-4"
                mandatory
                multiple
                show-arrows
                @change="selectedSavedReport = null"
              >
                <v-chip
                  outlined
                  v-for="(group, index) in groups"
                  :key="index"
                  :value="group.value"
                >
                  <v-avatar
                    v-if="getGroupIndex(group.value) >= 1"
                    left
                    class="deep-purple  lighten-4"
                  >
                    {{ getGroupIndex(group.value) }}
                  </v-avatar>
                  {{ group.text }}
                </v-chip>
              </v-chip-group>
            </v-col>

          </v-row>

          <!-- Buttons Row -->
          <v-row class="pt-5" justify="end">
            <v-btn 
              class="mx-2" 
              text 
              @click.native="clearQuery" 
              color="error" 
              :disabled="!valid"
            >
              <v-icon left>clear</v-icon>{{ $t('toCleanTransactionsReportsFilters') }}
            </v-btn>
            <save-report-menu 
              :value="saveReportMenu"
              :form="form"
              :defined-date-interval="definedDateInterval"
              :groups="form.group"
              :selected-saved-report="selectedSavedReport"
              type="Transactions"
              @input="changeSelectedSavedReport"
              @window="handleSavedReportMenu"
              @filterAdded="handleFilterAdded"
            ></save-report-menu>
            <v-btn 
              class="mx-2" 
              color="info" 
              @click.native="emitExcelModification" 
              :disabled="!valid" 
              :loading="loading.excel"
            >
            <v-icon left>mdi-file-excel</v-icon>{{ $t('excelTransactionsReportsFilters') }}
            </v-btn>
            <v-btn
              color="success"
              :disabled="!valid"
              v-on:click.native="emitTableModification"
            >
              <v-icon left>refresh</v-icon>{{ $t('updateTransactionsReportsFilters') }}
            </v-btn>
          </v-row>
          <!-- Feedback -->
          <feedback ref="feedback"></feedback>


        </v-form>

      </v-expansion-panel-content>
      
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import moment from "moment";
import ProductsFilter from '@/components/filters/ProductsFilter.vue';
import CategoriesDropDown from '@/components/filters/CategoriesDropDown'
import BrazilStates from '@/components/BrazilStates'
import SaveReportMenu from './SaveReportMenu'

export default {
  components: {
    ProductsFilter,
    CategoriesDropDown,
    SaveReportMenu
  },
  props: ["loadingExcelStop"],
  data() {
    return {
      resetComponent: false,
      valid: false,
      hasMinDate: null,
      hasMaxDate: null,
      dateIntervals: [{ text: this.$t('lastThirtyDaysTransactionsReportsFilters'), value: 30 },
                      { text: this.$t('lastFifteenDaysTransactionsReportsFilters'), value: 15 },
                      { text: this.$t('lastSevenDaysTransactionsReportsFilters'), value: 7 },
                      { text: this.$t('sameDayTransactionsReportsFilters'), value: 0 }],
      definedDateInterval: null,
      groups: [
        { text: this.$t('productsTransactionsReportsFilters'), value: "product" },
        { text: this.$t('categoryTransactionsReportsFilters'), value: "category" },
        { text: this.$t('customersTransactionsReportsFilters'), value: "customerName" },
        { text: this.$t('pointsOfSaleproductsTransactionsReportsFilters'), value: "pointOfSaleId" },
        { text: this.$t('statesTransactionsReportsFilters'), value: "addressState" },
        { text: this.$t('paymentMethodsTransactionsReportsFilters'), value: "paymentMethod" },
        { text: this.$t('cardFlagsTransactionsReportsFilters'), value: "cardBrand" },
        { text: this.$t('dayTransactionsReportsFilters'), value: "day" },
        { text: this.$t('monthTransactionsReportsFilters'), value: "month" },
        { text: this.$t('dayOfTheWeekproductsTransactionsReportsFilters'), value: "dayOfWeek" },
        { text: this.$t('hour'), value: "hour" }
      ],
      expansionIndex: 0,
      form: {
        minDate: null,
        maxDate: null,
        addressState: null,
        customerId: null,
        localId: null,
        posId: null,
        productCategoryId: null,
        productId: null,
        group: [],
      },
      customers: [],
      locations: [],
      pointsOfSale: [],
      brazilStates: BrazilStates.states,
      loading: {
        excel: false,
        location: false,
        pointsOfSale: false,
        saveReport: false,
        savedReports: false
      },
      rules: {
        required: (v) => !!v || this.$t('requiredRules'),
      },
      savedReports: [],
      newReportDescription: "",
      selectedSavedReport: null,
      saveReportMenu: false,
    };
  },
  computed: {
    formattedMinDate: function() {
      return this.formatDate(this.form.minDate);
    },
    formattedMaxDate: function() {
      return this.formatDate(this.form.maxDate);
    }
  },
  mounted() {
    const maxDate = new Date();
    const minDate = new Date(maxDate.getTime());
    minDate.setDate(minDate.getDate() - 30);
    this.form.maxDate = moment(maxDate).format("YYYY-MM-DD");
    this.form.minDate = moment(minDate).format("YYYY-MM-DD");
    this.axios.get('api/Customers/Active')
      .then((response) => {
        this.customers = response.data;
      });
  },
  watch: {
    loadingExcelStop: function() {
      this.loading.excel = false;
    },
    'form.customerId'(newCustomerId) {
      if (newCustomerId != null) {
        this.loading.location = true;
        this.axios.get('api/Customers/' + newCustomerId)
          .then((response) => {
            this.loading.location = false;
            this.locations = response.data.locals;
          });
      }
    },
    'form.localId'(newLocationValue) {
      if (newLocationValue != null) {
        this.loading.pointsOfSale = true;
        this.axios.get('api/Locals/' + newLocationValue + '/PointsOfSale')
          .then((response) => {
            this.loading.pointsOfSale = false;
            this.pointsOfSale = response.data.map((p) => ({ 
              text: p.id + (p.specificLocation !== null ? ' / ' + p.specificLocation : ''),
              value: p.id 
            }));
          });
      }
      else {
        this.form.posId = null;
      }
    },
    'form.productId'(newProductId) {
      if (newProductId != null) {
        this.form.productCategoryId = null;
      }
    },
    'form.productCategoryId'(newCategoryId) {
      if (newCategoryId) {
        this.form.productId = null;
      }
    },
  },
  methods: {
    emitTableModification() {
      if (this.$refs.form.validate()) {
        this.$emit("table", this.form);
        this.expansionIndex = undefined;
      }
    },
    emitExcelModification() {
      if (this.$refs.form.validate()) {
        this.$emit("excel", this.form);
        this.loading.excel = true;
      }
    },
    newForm() {
      return {
        minDate: this.form.minDate,
        maxDate: this.form.maxDate,
        productCategoryId: null,
        productId: null,
        group: []
      };
    },
    clearQuery() {
      this.form = this.newForm();
      this.selectedSavedReport = null;
      this.definedDateInterval = null;
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    clearMinDate() {
      this.form.minDate = null;
      this.formattedMinDate = null;
    },
    clearMaxDate() {
      this.form.maxDate = null;
      this.formattedMaxDate = null;
    },
    getAllowedMinDates(minDate) {
      if (this.form.maxDate !== null) {
        return this.isDateRangeAllowed(
          new Date(minDate),
          new Date(this.form.maxDate)
        );
      }
      return true;
    },
    getAllowedMaxDates(maxDate) {
      if (this.form.minDate !== null) {
        return this.isDateRangeAllowed(
          new Date(this.form.minDate),
          new Date(maxDate)
        );
      }
      return true;
    },
    isDateRangeAllowed(min, max) {
      return max >= min && Math.round(max - min) / (1000 * 60 * 60 * 24) < 180;
    },
    changeDateInterval() {
      this.form.minDate = moment(this.form.maxDate).subtract(parseInt(this.definedDateInterval), 'days')
      .format("YYYY-MM-DD");
    },
    changeDate() {
      this.definedDateInterval = null;
    },
    getGroupIndex(groupChip) {
      return this.form.group.indexOf(groupChip) + 1
    },
    changeSelectedSavedReport(selectedReport) {
      this.clearQuery();
      this.selectedSavedReport = selectedReport;
      if (this.selectedSavedReport != null) {
        this.form.group = this.selectedSavedReport.groupColumns;
        // This loop brings the filters in pairs from the SavedReport to the filter form in the page
        this.selectedSavedReport.savedReportFilters?.forEach(filterPair => {
          const filterString = filterPair.filterName;
          if(filterString != "dateInterval" && filterString != "customerId") {
            this.form[filterString] = Number(filterPair.filterValue) || filterPair.filterValue;
          }
          else if(filterString == "customerId") {
            this.form.customerId = Number(filterPair.filterValue);
          }
          else if(filterString == "dateInterval") {
            this.definedDateInterval = Number(filterPair.filterValue);
            this.changeDateInterval();
          }
        });
      }
    },
    handleSavedReportMenu(value) {
      this.saveReportMenu = value;
    },
    handleFilterAdded(value) {
      this.$refs.feedback.handleSuccess(value);
    }
  },
};
</script>
