<template>
  <v-dialog v-model="dialog" color="white" max-width="500px">
    <v-form ref="form" lazy-validation v-model="valid" @submit.prevent="saveLock">
      <v-card>
        <v-card-title>
          {{ lock.uuid ? $t('editLockLockForm') : $t('addLockLockForm') }}
        </v-card-title>
        <v-card-text>
          <v-text-field
              v-model="lock.deviceName"
              :label="$t('deviceNameLockForm')"
              prepend-icon="device_hub"
              :hint="$t('deviceNameHintLockForm')"
              class="pr-2"
              :rules="[deviceNameRule]"
          ></v-text-field>
          <v-text-field
              v-model="lock.friendlyName"
              :label="$t('lockNameLockForm')"
              prepend-icon="lock"
              :hint="$t('lockNameHintLockForm')"
              class="pr-2"
              required
              :rules="[(v) => !!v || $t('requiredRules')]"
          ></v-text-field>
          <v-checkbox
              v-model="lock.ageRestricted"
              :label="$t('ageRestrictedLockForm')"
              prepend-icon="liquor"
              :hint="$t('ageRestrictedHint')"
              class="pr-2"
          ></v-checkbox>
         <v-select
            v-model="lock.openTimeInMillis"
            :items="openTimeInMillisOptions"
            :label="$t('deviceUnlockTime')"
            prepend-icon="access_time"
            class="pr-2"
            required
            :rules="[(v) => !!v || $t('requiredRules')]"
          ></v-select>
          <v-select
            v-model="lock.keepOpenedOnDisconnect"
            :items="keepOpenedOnDisconnectOptions"
            :label="$t('deviceUnlockBehavior')"
            prepend-icon="mdi-lock-check-outline"
            class="pr-2"
            required
            :rules="[$validations.booleanOption]"
          >
            <template #append-outer>
              <help>
                <template #title>{{ $t('deviceUnlockBehaviorTitle') }}</template>
                <p v-html="$t('deviceUnlockBehaviorHint')"></p>
                <div>
                  {{ $t('deviceUnlockBehaviorTypesTitle') }}
                  <ul>
                    <li><p v-html="$t('deviceUnlockBehaviorTypesKeepClosed')"></p></li>
                    <li><p v-html="$t('deviceUnlockBehaviorTypesKeepOpened')"></p></li>
                  </ul>
                </div>
              </help>
            </template>
          </v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text color="primary" @click.native="dialog = false">{{ $t('closeLockForm') }}</v-btn>
          <v-btn type="submit" :loading="loading.saving" text color="primary">
            {{ lock.uuid ? $t('saveLockForm') : $t('registerLockForm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <feedback ref="feedback"></feedback>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: { type: Boolean, required: true },
    posId: { type: Number, required: true },
    lock: { type: Object, required: false },
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
  },
  data() {
    return {
      valid: true,
      form: {},
      loading: {
        saving: false
      },
      openTimeInMillisOptions: [
        { text: this.$t('deviceUnlockTimeSeconds', { time: 5 }), value: 5000 },
        { text: this.$t('deviceUnlockTimeSeconds', { time: 15 }), value: 15000 },
        { text: this.$t('deviceUnlockTimeSeconds', { time: 30 }), value: 30000 },
        { text: this.$t('deviceUnlockTimeMinute', { time: 1 }), value: 60000 },
        { text: this.$t('deviceUnlockPulse'), value: 300 }
      ],
      keepOpenedOnDisconnectOptions: [
        { text: this.$t('keepClosedOnDisconnect'), value: false },
        { text: this.$t('keepOpenedOnDisconnect'), value: true }
      ],
      deviceNameRule: (v) => {
        const regex = /^MMD-\d{4}$|^TPLock[A-Z]{2}[0-9]{2}$/;
        return regex.test(v) || this.$t('deviceNameLockErrorForm');
      }
    }
  },
  methods:
  {
    saveLock()
    {
      if (this.$refs.form.validate()) {
        if (this.lock.uuid) {
          this.loading.saving = true;
          this.axios.put(`api/AccessControl/${this.lock.uuid}`, this.lock)
          .then(response => {
            this.$refs.feedback.handleSuccess(this.$t('successEditMessage'));
            this.lock = response.data;
            this.$emit('updated');
            this.dialog = false;
          }).catch(error => {
            this.$refs.feedback.handleError(this.$t('errorMessageEdit'), error);
          }).then(() => {
            this.loading.saving = false;
          });
        }
        else {
          this.loading.saving = true;
          this.axios.post('api/AccessControl/newlock', this.lock)
            .then(response => {
              this.$refs.feedback.handleSuccess(this.$t('successAddMessage'));
              this.lock = response.data;
              this.$emit('updated');
              this.dialog = false;
            }).catch(error => {
              this.$refs.feedback.handleError(this.$t('errorMessageAdd'), error);
            }).then(() => {
              this.loading.saving = false;
          });
        }
      }
    }
  }
}
</script>
