<template>
  <v-expansion-panels v-model="expansionIndex" hover>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <template #default="{ open }">
          <v-row no-gutters>
            <v-col cols="2">{{ $t('filter') }}</v-col>
            <v-col cols="10" class="text--secondary">
              <span v-if="!open">
                {{$t('period')}}: <strong>{{ formattedMinDate }} - {{ formattedMaxDate }}</strong>,
                {{ $t('customer') }}: <strong>{{ (customers.find(c => c.id === form.customerId) || {}).name || $t('all') }}</strong>,
                {{ $t('location') }}: <strong>{{ (locations.find(l => l.id === form.localId) || {}).name || $t('all') }}</strong>,
                {{ $t('pointOfSale.data.title.pointOfSale') }}: <strong>{{ (pointsOfSale.find(p => p.value === form.pointOfSaleId) || {}).text || $t('all') }}</strong>,
                <template v-if="activeTab !== 3">{{ $t('productId') }}: <strong>{{ form.productId || $t('all') }}</strong>, </template>
                <template>{{ $t('category') }}: <strong>{{ form.categoryId || $t('allF') }}</strong>,</template>
                {{ $t('statePointOfSale') }}: <strong>{{ form.state || $t('all') }}</strong>
              </span>
            </v-col>
          </v-row>
        </template>
      </v-expansion-panel-header>
    
        <v-expansion-panel-content>
        <v-form ref="form" v-model="valid" @submit.prevent="getTransactions()">
          <v-row>
            <v-col cols="12" md="3">
              <v-menu class="pr-2" :close-on-content-click="true" v-model="hasMinDate" transition="scale-transition"
                offset-y :nudge-right="40" max-width="290px" min-width="290px">
                <template #activator="{ on }">
                  <v-text-field v-on="on" :label="$t('startDate')" :rules="[rules.required]" required
                  v-model="formattedMinDate" prepend-icon="date_range" readonly></v-text-field>
                </template>
                <v-date-picker v-model="form.minDate" no-title scrollable actions 
                        :allowed-dates="getAllowedMinDates">
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="clearMinDate">{{ $t('clear') }}</v-btn>
              </v-card-actions>
          </v-date-picker>
        </v-menu>

            </v-col>
             <!-- Start Time -->
            <v-col cols="12" md="3">
              <TimePicker
                v-model="form.minTime"
                :label="$t('initialHour')"
               
              />
            </v-col>
            <v-col cols="12" md="3">
              <v-menu :close-on-content-click="true" v-model="hasMaxDate" transition="scale-transition"
                  offset-y :nudge-right="40" max-width="290px" min-width="290px">
                <template #activator="{ on }">
                  <v-text-field v-on="on" :label="$t('endDate')" :rules="[rules.required]" required
            
                    v-model="formattedMaxDate" prepend-icon="event_available" readonly></v-text-field>
                </template>
                <v-date-picker v-model="form.maxDate" no-title scrollable actions
                   :allowed-dates="getAllowedMaxDates">
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="clearMaxDate">{{ $t('clear') }}</v-btn>
                  </v-card-actions>
                </v-date-picker>
              </v-menu>
            </v-col>
              <!-- End Time -->
              <v-col cols="12" md="3">
              <TimePicker
                v-model="form.maxTime"
                :label="$t('finalHour')"
                
              />
            </v-col>
            <v-col cols="12" md="3">
              <v-autocomplete
                prepend-icon="account_box"
                :items="customers"
                v-model="form.customerId"
                :label="$t('customer')"
                
                item-text="name"
                item-value="id"
                single-line
                clearable>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <categories-drop-down v-model="form.categoryId"></categories-drop-down>
            </v-col>
            <v-col cols="12" md="3" v-if="activeTab === 3">
              <v-autocomplete
                v-if="isBrazil"
                key="brazil-state"
                prepend-icon="mdi-map"
                :items="states"
                v-model="form.state"
                :label="$t('statePointOfSale')"
                item-text="name"
                item-value="value"
                clearable
              ></v-autocomplete>
              <v-autocomplete
                v-else-if="!isBrazil"
                key="usa-state"
                prepend-icon="mdi-map"
                :items="states"
                v-model="form.state"
                :label="$t('statePointOfSale')"
                item-text="name"
                item-value="value"
                clearable
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <v-autocomplete
                prepend-icon="location_on"
                :disabled="form.customerId === null || loading.locals"
                :hint="form.customerId === null ? 'Selecione antes um cliente' : ''"
                :items="locations"
                v-model="form.localId"
                :loading="loading.locals"
                item-text="name"
                item-value="id"
                :label="$t('location')"
                
                single-line
                persistent-hint
                clearable>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="3" v-if="activeTab !== 3">
              <products-filter
                  icon="shopping_cart"
                  v-model="form.productId"
              ></products-filter>            </v-col>
            <v-col cols="12" md="3">
              <v-autocomplete
                prepend-icon="business"
                :disabled="form.localId === null || loading.pointsOfSale"
                :hint="form.localId === null ? $t('selectALocation') : ''"
              
                :items="pointsOfSale"
                v-model="form.pointOfSaleId"
                :loading="loading.pointsOfSale"
                :label="$t('pointOfSale.data.title.pointOfSale')"
               
                single-line
                persistent-hint
                clearable>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="3" v-if="activeTab !== 3">
              <v-autocomplete
                v-if="isBrazil"
                key="brazil-state"
                prepend-icon="mdi-map"
                :items="states"
                v-model="form.state"
                :label="$t('statePointOfSale')"
                item-text="name"
                item-value="value"
                clearable
              ></v-autocomplete>
              <v-autocomplete
                v-else-if="isUSA"
                key="usa-state"
                prepend-icon="mdi-map"
                :items="states"
                v-model="form.state"
                :label="$t('statePointOfSale')"
                item-text="name"
                item-value="value"
                clearable
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row justify="end" class="mt-5 mr-6 mb-1">
            <v-col cols="12" sm="4" md="6" lg="2" v-if="activeTab !== 3 && activeTab !== 0">
              <v-switch :label="$t('showZeroedPOS')" v-model="form.viewZeroedPos"></v-switch>
            </v-col>
            <v-col cols="12" sm="2" md="6" lg="1" class="pl-0 ml-0">
              <slot name="excelButton"/>
            </v-col>
              <v-btn class="mt-4 mr-0 fixed-left" color="success" :disabled="!valid" v-on:click.native="emitModification">
                <v-icon left>refresh</v-icon>{{$t('update')}}
              </v-btn>
          </v-row>
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import moment from 'moment';
import BrazilStates from "@/components/BrazilStates";
import UsaStates from "@/components/UsaStates";
import ProductsFilter from '@/components/filters/ProductsFilter.vue';
import CategoriesDropDown from '@/components/filters/CategoriesDropDown';
import TimePicker from "@/components/filters/TimePicker.vue";

export default {
  props: {
    activeTab: { type: Number, required: true }
  },
  data(){
    return {
      valid: false,
      hasMinDate: null,
      hasMaxDate: null,
      customers: [],
      locations: [],
      pointsOfSale: [],
      expansionIndex: 0,
      form: {
        minDate: null,
        maxDate: null,
        customerId: null,
        localId: null,
        pointOfSaleId: null,
        viewZeroedPos: false,
        minTime: null,
        maxTime: null,
        timezoneOffset: new Date().getTimezoneOffset()
      },
      loading: {
        locals: false,
        pointsOfSale: false,
      },
      brazilStates: BrazilStates.states,
      usaStates: UsaStates.states,
      rules:{
          required: (v) => !!v || this.$t('requiredRules'),
      }
    }
  },
  computed: {
    formattedMinDate: function(){
      return this.formatDate(this.form.minDate);
    },
    formattedMaxDate: function(){
      return this.formatDate(this.form.maxDate);
    },
    states() {
      return this.isBrazil ? this.brazilStates : this.usaStates;
    },
    isBrazil() {
      return this.$auth.user().tenantCountry === 'BR';
    },
    isUSA(){
      return this.$auth.user().tenantCountry === 'US';
    },

  },
  watch: {
    'form.customerId': function(newId) {
      if(newId !== null){
        this.loading.locals = true;
        this.axios.get('api/Customers/' + newId)
          .then((response) => {
            this.loading.locals = false;
            this.locations = response.data.locals;
          });
      }
      else {
        this.form.localId = null;
      }
    },
    'form.localId': function(newId) {
      if(newId != null){
        this.loading.pointsOfSale = true;
        this.axios.get('api/Locals/' + newId + '/PointsOfSale')
          .then((response) => {
            this.loading.pointsOfSale = false;
            this.pointsOfSale = response.data.map((p) => ({ text: p.id + (p.specificLocation !== null ? ' / ' + p.specificLocation : ''), value: p.id }));
          });
      }
      else{
        this.form.pointOfSaleId = null;
      }
    },
  },
  mounted(){
    const maxDate = new Date();
    const minDate = new Date(maxDate.getTime());
    minDate.setDate(minDate.getDate() - 30);
    this.form.maxDate = moment(maxDate).format('YYYY-MM-DD');
    this.form.minDate = moment(minDate).format('YYYY-MM-DD');
    this.$emit('modify', this.form);
    this.axios.get('api/Customers/Active')
      .then((response) => {
        this.customers = response.data
      });
  },
  methods: {
    emitModification(){
      if(this.$refs.form.validate()){
        this.$emit('modify', this.form);
        this.expansionIndex = undefined;
      }
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    clearMinDate() {
      this.form.minDate = null;
      this.formattedMinDate = null;
    },
    clearMaxDate() {
      this.form.maxDate = null;
      this.formattedMaxDate = null;
    },
    getAllowedMinDates(minDate) {
      if (this.form.maxDate !== null) {
        return this.isDateRangeAllowed(new Date(minDate), new Date(this.form.maxDate));
      }
      return true;
    },
    getAllowedMaxDates(maxDate) {
      if (this.form.minDate !== null) {
        return this.isDateRangeAllowed(new Date(this.form.minDate), new Date(maxDate));
      }
      return true;
    },
    isDateRangeAllowed(min, max){
      return max >= min && Math.round(max - min)/(1000*60*60*24) < 90;
    }
  },
  components: {
    ProductsFilter,
    CategoriesDropDown,
    TimePicker,
  }
}
</script>
