<template>
  <v-row no-gutters>
    <portal to="toolbarTitle">
      <LossesHelp/>
    </portal>
    <v-col cols="12">
      <news v-if="isBrazil"></news>

      <ReportFilters
        v-if="activeTab === 1 || activeTab === 2"
        @modify="updateFilters"
      />

      <ProductsToExpireReportFilters
        v-if="activeTab === 0"
        @modify="updateFilters"
      />

      <v-tabs v-model="activeTab" grow background-color="grey lighten-4">
        <v-tab :key="0">{{ $t('inventory.report.operational.productsToExpire') }}</v-tab>
        <v-tab :key="1">{{ $t('inventory.report.operational.posLosses') }}</v-tab>
        <v-tab :key="2">{{ $t('inventory.report.operational.lossesByProduct') }}</v-tab>
        <v-tab-item :key="0" class="mb-16">
          <v-card>
            <v-card-title primary-title>
              <v-spacer></v-spacer>

              <ExcelButton
                @excel="getExcelExpire"
                :loading="loading.excel"
              />
            </v-card-title>
            <v-divider></v-divider>
            <v-data-table
              :loading="expiration.loading"
              :headers="expiration.headers"
              :items="expiration.items"
              :options.sync="expiration.paginationOptions"
              :server-items-length="expiration.totalItems"
              :footer-props="{ itemsPerPageOptions: [10, 20, 30] }"
              must-sort
            >
              <template #item.productExpirationDate="{ item }">
                <td>{{ $moment.utc(item.productExpirationDate).format('DD/MM/YYYY') }}</td>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
        <v-tab-item :key="1" class="mb-16">
          <v-card>
            <v-card-title primary-title>
              <v-spacer></v-spacer>

              <ExcelButton
                @excel="getExcelPoSLosses"
                :loading="loading.excel"
              />
            </v-card-title>
            <v-divider></v-divider>
            <v-data-table
              :loading="posLoss.loading"
              :headers="posLoss.headers"
              :items="posLoss.items"
              :options.sync="posLoss.options"
              :footer-props="{ itemsPerPageOptions: [10, 20, 30] }"
              must-sort
            >
              <template #item.loss.soldValue="{ item }">
                <td>{{ $currencyFormatter.format(item.loss.soldValue) }}</td>
              </template>
              <template #item.loss.loss.expiredValue="{ item }">
                <td>{{ $currencyFormatter.format(item.loss.loss.expiredValue) }}</td>
              </template>
              <template #item.loss.loss.damagedValue="{ item }">
                <td>{{ $currencyFormatter.format(item.loss.loss.damagedValue) }}</td>
              </template>
              <template #item.loss.loss.stolenValue="{ item }">
                <td>{{ $currencyFormatter.format(item.loss.loss.stolenValue) }}</td>
              </template>
              <template #item.loss.loss.otherValue="{ item }">
                <td>{{ $currencyFormatter.format(item.loss.loss.otherValue) }}</td>
              </template>
              <template #item.loss.loss.gainValue="{ item }">
                <td>{{ $currencyFormatter.format(item.loss.loss.gainValue) }}</td>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
        <v-tab-item :key="2" class="mb-16">
          <v-card>
            <v-card-title primary-title>
              <v-spacer></v-spacer>

              <ExcelButton
                @excel="getExcelProductLosses"
                :loading="loading.excel"

              />
            </v-card-title>
            <v-divider></v-divider>
            <v-data-table
              :loading="productLoss.loading"
              :headers="productLoss.headers"
              :items="productLoss.items"
              :options.sync="productLoss.options"
              :footer-props="{ itemsPerPageOptions: [10, 20, 30] }"
              must-sort
            >
              <template #item.product.defaultPrice="{ item }">
                <td>{{ $currencyFormatter.format(item.product.defaultPrice) }}</td>
              </template>
              <template #item.loss.soldValue="{ item }">
                <td>{{ $currencyFormatter.format(item.loss.soldValue) }}</td>
              </template>
              <template #item.loss.loss.expiredValue="{ item }">
                <td>{{ $currencyFormatter.format(item.loss.loss.expiredValue) }}</td>
              </template>
              <template #item.loss.loss.damagedValue="{ item }">
                <td>{{ $currencyFormatter.format(item.loss.loss.damagedValue) }}</td>
              </template>
              <template #item.loss.loss.stolenValue="{ item }">
                <td>{{ $currencyFormatter.format(item.loss.loss.stolenValue) }}</td>
              </template>
              <template #item.loss.loss.otherValue="{ item }">
                <td>{{ $currencyFormatter.format(item.loss.loss.otherValue) }}</td>
              </template>
              <template #item.loss.loss.gainValue="{ item }">
                <td>{{ $currencyFormatter.format(item.loss.loss.gainValue) }}</td>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-col>
  </v-row>
</template>

<script>
import News from '@/components/News.vue';
import LossesHelp from './helpers/LossesHelp.vue';
import ProductsToExpireReportFilters from './components/ProductsToExpireReportFilters.vue';
import ReportFilters from './components/ReportFilters.vue';
import download from '@/components/Download';
import { shouldRefreshData , toApiPagination } from '@/components/TableOptionsComparator';
import ExcelButton from "@/components/buttons/ExcelButton.vue";


export default {
  data() {
    return {
      expiration: {
        loading: true,
        headers: [
          { text: this.$t('tables.headers.inventory'), align: 'left', sortable: false, value: 'inventoryName' },
          { text: this.$t('tables.headers.productCode'), align: 'left', sortable: true, value: 'product.code' },
          { text: this.$t('tables.headers.productDescription'), align: 'left', sortable: true, value: 'product.description' },
          { text: this.$t('tables.headers.productCategory'), align: 'left', sortable: true, value: 'product.categoryName' },
          { text: this.$t('tables.headers.currentQty'), align: 'left', sortable: true, value: 'currentQuantity' },
          { text: this.$t('tables.headers.expires'), align: 'left', sortable: true, value: 'productExpirationDate' }
        ],
        items: [],
        paginationOptions: {
          page: 1,
          itemsPerPage: 10,
          sortBy: ['productExpirationDate'],
          sortDesc: [true]
        },
        totalItems: 0,
      },
      posLoss: {
        loading: true,
        headers: [
          { text: this.$t('tables.headers.pos'), align: 'left', sortable: true, value: 'pointOfSale.id' },
          { text: this.$t('tables.headers.customer'), align: 'left', sortable: true, value: 'pointOfSale.customerName' },
          { text: this.$t('tables.headers.location'), align: 'left', sortable: true, value: 'pointOfSale.localName' },
          { text: this.$t('tables.headers.specificLocation'), align: 'left', sortable: true, value: 'pointOfSale.specificLocation' },
          { text: this.$t('tables.headers.qtySold'), align: 'left', sortable: true, value: 'loss.soldCount' },
          { text: this.$t('tables.headers.soldValue'), align: 'left', sortable: true, value: 'loss.soldValue' },
          { text: this.$t('tables.headers.expiredQty'), align: 'left', sortable: true, value: 'loss.loss.expiredCount' },
          { text: this.$t('tables.headers.expiredValue'), align: 'left', sortable: true, value: 'loss.loss.expiredValue' },
          { text: this.$t('tables.headers.damagedQty'), align: 'left', sortable: true, value: 'loss.loss.damagedCount' },
          { text: this.$t('tables.headers.damagedValue'), align: 'left', sortable: true, value: 'loss.loss.damagedValue' },
          { text: this.$t('tables.headers.stolenQty'), align: 'left', sortable: true, value: 'loss.loss.stolenCount' },
          { text: this.$t('tables.headers.stolenValue'), align: 'left', sortable: true, value: 'loss.loss.stolenValue' },
          { text: this.$t('tables.headers.otherLossesQty'), align: 'left', sortable: true, value: 'loss.loss.otherCount' },
          { text: this.$t('tables.headers.otherLossesValue'), align: 'left', sortable: true, value: 'loss.loss.otherValue' },
          { text: this.$t('tables.headers.gainQty'), align: 'left', sortable: true, value: 'loss.loss.gainCount' },
          { text: this.$t('tables.headers.gainValue'), align: 'left', sortable: true, value: 'loss.loss.gainValue' },
        ],
        items: [],
        options: {
          page: 1,
          itemsPerPage: 10,
          sortBy: ['loss.loss.stolenValue'],
          sortDesc: [true]
        },
        totalItems: 0
      },
      productLoss: {
        loading: true,
        headers: [
          { text: this.$t('tables.headers.id'), align: 'left', sortable: false, value: 'product.id' },
          { text: this.$t('tables.headers.productCode'), align: 'left', sortable: false, value: 'product.code' },
          { text: this.$t('tables.headers.productDescription'), align: 'left', sortable: false, value: 'product.description' },
          { text: this.$t('tables.headers.productCategory'), align: 'left', sortable: false, value: 'product.categoryName' },
          { text: this.$t('tables.headers.defaultPrice'), align: 'left', sortable: false, value: 'product.defaultPrice' },
          { text: this.$t('tables.headers.qtySold'), align: 'left', sortable: true, value: 'loss.soldCount' },
          { text: this.$t('tables.headers.soldValue'), align: 'left', sortable: true, value: 'loss.soldValue' },
          { text: this.$t('tables.headers.expiredQty'), align: 'left', sortable: true, value: 'loss.loss.expiredCount' },
          { text: this.$t('tables.headers.expiredValue'), align: 'left', sortable: true, value: 'loss.loss.expiredValue' },
          { text: this.$t('tables.headers.damagedQty'), align: 'left', sortable: true, value: 'loss.loss.damagedCount' },
          { text: this.$t('tables.headers.damagedValue'), align: 'left', sortable: true, value: 'loss.loss.damagedValue' },
          { text: this.$t('tables.headers.stolenQty'), align: 'left', sortable: true, value: 'loss.loss.stolenCount' },
          { text: this.$t('tables.headers.stolenValue'), align: 'left', sortable: true, value: 'loss.loss.stolenValue' },
          { text: this.$t('tables.headers.otherLossesQty'), align: 'left', sortable: true, value: 'loss.loss.otherCount' },
          { text: this.$t('tables.headers.otherLossesValue'), align: 'left', sortable: true, value: 'loss.loss.otherValue' },
          { text: this.$t('tables.headers.gainQty'), align: 'left', sortable: true, value: 'loss.loss.gainCount' },
          { text: this.$t('tables.headers.gainValue'), align: 'left', sortable: true, value: 'loss.loss.gainValue' },
        ],
        items: [],
        options: {
          page: 1,
          itemsPerPage: 10,
          sortBy: ['loss.loss.stolenValue'],
          sortDesc: [true]
        },
        totalItems: 0
      },
      form: {},
      activeTab: 0,
      shouldLoadLosses: false,
      loading: {
        excel: false
      },
    };
  },
  watch: {
    'expiration.paginationOptions': {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.updateExpiration();
        }
      },
      deep: true
    },
    activeTab(tab) {
      if (this.shouldLoadLosses === false && tab > 0) {
        this.shouldLoadLosses = true;
        this.updateLosses(this.form);
      }
    }
  },
  methods: {
    updateFilters(form) {
      this.form = form;
      this.updateExpiration();
      this.updateLosses(form);
    },
    updateExpiration() {
      this.expiration.loading = true;
      let params = {
        ...toApiPagination(this.expiration.paginationOptions),
        ...this.insertFiltersFromForm(this.form),
      };

      this.axios.get('api/operationalReports/productsToExpire', { params: params })
        .then(response => {
          this.expiration.items = response.data.items;
          this.expiration.totalItems = response.data.totalItems;
        })
        .catch(() => {})
        .then(() => this.expiration.loading = false);
    },
    updateLosses(form) {
      if (this.shouldLoadLosses) {
        this.posLoss.loading = true;
        this.productLoss.loading = true;
        this.axios.get('api/operationalReports/losses', { params: form })
            .then(response => {
              this.posLoss.loading = false;
              this.productLoss.loading = false;
              this.posLoss.items = response.data.pointOfSaleLoss;
              this.productLoss.items = response.data.productLoss;
            }).catch(() => {})
            .then(() => {
              this.posLoss.loading = false;
              this.productLoss.loading = false;
            });
      }
    },
    getExcelExpire() {
      this.loading.excel = true;
      this.axios.get('api/operationalReports/productsToExpire/excel', {params: this.form, responseType: 'blob'})
        .then((response) => {
          this.loading.excel = false;
          download(response, this.$t('inventory.report.operational.productsToExpire'))
        }).catch(() => {
          this.loading.excel = false;
        });
    },
    getExcelPoSLosses() {
      this.loading.excel = true;
      this.axios.get('api/operationalReports/pointOfSaleLosses/excel', {params: this.form, responseType: 'blob'})
        .then((response) => {
          this.loading.excel = false;
          download(response, this.$t('inventory.report.operational.posLosses'))
        }).catch(() => {
          this.loading.excel = false;
        });
    },
    getExcelProductLosses() {
      this.loading.excel = true;
      this.axios.get('api/operationalReports/productLosses/excel', {params: this.form, responseType: 'blob'})
        .then((response) => {
          this.loading.excel = false;
          download(response, this.$t('inventory.report.operational.lossesByProduct'))
        }).catch(() => {
          this.loading.excel = false;
        });
    },
    insertFiltersFromForm(form) {
      let params = {};
      params.minDate = form.minDate;
      params.maxDate = form.maxDate;
      params.inventoryIds = form.inventoryIds;
      params.inventoryTypes = form.inventoryTypes;
      params.showZeroProducts = form.showZeroProducts;
      return params;
    },
  },
  computed: {
    isBrazil() {
      return this.$auth.user().tenantCountry == 'BR';
    },
  },
  components: {
    ProductsToExpireReportFilters,
    ReportFilters,
    News,
    LossesHelp,
    ExcelButton
  }
}
</script>
