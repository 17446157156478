
import Vue from "vue";

export default Vue.extend({
  name: "DatePickerMonth",
  props: {
    value: { type: String, default: null },
    max: { type: String, default: null },
  },
  computed: {
    selectedMonth: {
      get(): string | null {
        return this.value;
      },
      set(value: string | null): void {
        this.$emit("input", value);
      },
    },
  },
});
