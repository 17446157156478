
import Vue from 'vue';
import Hls from 'hls.js';

export default Vue.extend({
  props: {
    value: { type: Boolean, required: true, default: false },
    url: { type: String, default: null }
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean): void {
        (this as any).$emit('input', value);
        if (!value) {
          if (this.hls) {
            this.hls.detachMedia();
            this.hls.destroy();
            this.hls = null;
            console.log("hls destroyed")
          }
        }
      },
    }
  },
  data() {
    return {
      hls: null as Hls|null,
    }
  },
  watch: {
    url(newValue: string|null, oldValue: string|null) {
      if (newValue && newValue !== oldValue) {
        console.log("video url updated")
        this.playVideo(newValue);
      }
    }
  },
  methods: {
    playVideo(url: string) {
      if (this.hls == null) {
        this.hls = new Hls();
      }
      let video = this.$refs["video"] as HTMLMediaElement;
      this.hls.loadSource(url);
      this.hls.attachMedia(video);
      this.hls.on(Hls.Events.MANIFEST_PARSED, function () {
        (video as any).play();
        console.log("video should play")
      });
    }
  }
})
