import {defineStore} from 'pinia';
import {ref} from "vue";
import agent from "@/api/agent";

export const useFeatureFlagsStore = defineStore('featureFlags', () => {

  const flags = ref<TenantFeatureFlags>({
    acquisitionOrdersEnabled: false,
    changeOwnershipEnabled: false,
    paymentProcessorFlags: {
      existingPaymentProcessor: false,
      newPaymentProcessor: false,
      pagSeguro: false,
      rede: false,
      stone: false,
    }
  })

  async function loadFeatureFlags() {
    await agent.FeatureFlags.loadFeatureFlags()
      .then((response) => flags.value = response)
      .catch((error) => console.error(error));
  }

  return {
    flags,
    loadFeatureFlags,
  };
});

