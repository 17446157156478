import {defineStore} from "pinia";
import {ref} from "vue";
import {PaymentProcessor, PaymentProcessorCompany, paymentProcessorInitialState} from "@/types/AcquisitionOrders";
import agent from "@/api/agent";
import {useAlertsStore} from "@/stores/alertsStore";
import {useAcquisitionOrdersStore} from "@/stores/acquisitionOrdersStore";
import {useFeatureFlagsStore} from "@/stores/featureFlagsStore";

interface PaymentProcessorCardInformation {
  company: PaymentProcessorCompany;
  name: string;
  image: string;
  recommended: boolean;
  enabled: boolean;
}

export const usePaymentProcessorsStore = defineStore('paymentProcessors', () => {
  const alertsStore = useAlertsStore()
  const acquisitionOrdersStore = useAcquisitionOrdersStore();
  const featureFlagsStore = useFeatureFlagsStore();

  const paymentProcessorAccounts = ref<PaymentProcessor[]>([]);

  const paymentProcessor = ref<PaymentProcessor>({...paymentProcessorInitialState.value});

  const paymentProcessorCompanySelectionDialog = ref(false);
  const paymentProcessorChooseExistingDialog = ref(false);
  const paymentProcessorCreatingExistingDialog = ref(false);
  const paymentProcessorExistingCreatedDialog = ref(false);

  const loadingCreatingPaymentProcessor = ref(false);
  const loadingPaymentProcessorAccounts = ref(false);
  const loadingDeletingPaymentProcessors = ref<Record<number, boolean>>({});

  const showAdditionalCards = ref(false);

  async function getPaymentProcessorAccounts() {
    loadingPaymentProcessorAccounts.value = true;
    await agent.PaymentProcessorAccounts.getEnvironmentPaymentProcessorAccounts()
      .then((response) => paymentProcessorAccounts.value = response)
      .catch((error) => alertsStore.showErrorSnackbar(error.response.data))
      .finally(() => loadingPaymentProcessorAccounts.value = false);
  }

  async function createPaymentProcessor() {
    loadingCreatingPaymentProcessor.value = true;
    await agent.PaymentProcessorAccounts.postPaymentProcessorAccount(paymentProcessor.value)
      .then(() => {
        onCreatePaymentProcessorSuccess();
      })
      .catch((error) => alertsStore.showErrorSnackbar(error.response.data))
      .finally(() => loadingCreatingPaymentProcessor.value = false);
  }

  async function deletePaymentProcessor(id: number) {
    loadingDeletingPaymentProcessors.value[id] = true;
    await agent.PaymentProcessorAccounts.deletePaymentProcessorAccount(id)
      .then(() => getPaymentProcessorAccounts())
      .catch((error) => alertsStore.showErrorSnackbar(error.response.data))
      .finally(() => loadingDeletingPaymentProcessors.value[id] = false);
  }

  function resetPaymentProcessorFlow() {
    paymentProcessor.value = {...paymentProcessorInitialState.value};

    paymentProcessorChooseExistingDialog.value = false;
    paymentProcessorCompanySelectionDialog.value = false;
    paymentProcessorCreatingExistingDialog.value = false;
    paymentProcessorExistingCreatedDialog.value = false;
    showAdditionalCards.value = false;
  }

  function startPaymentProcessorFlow() {
    paymentProcessorCompanySelectionDialog.value = true;
  }

  function onPaymentProcessorCompanySelected(processorCompany: PaymentProcessorCompany) {
    paymentProcessor.value.processorCompany = processorCompany;
    paymentProcessorCompanySelectionDialog.value = false;
    paymentProcessorChooseExistingDialog.value = true;
  }

  function onExistingPaymentProcessorSelected(){
    paymentProcessorChooseExistingDialog.value = false;
    paymentProcessorCreatingExistingDialog.value = true;
  }

  function onCreateNewPaymentProcessorSelected(){
    alert('Create new payment processor selected');
  }

  function returnFromPaymentProcessorChooseExisting() {
    paymentProcessorChooseExistingDialog.value = false;
    paymentProcessorCompanySelectionDialog.value = true;
  }

  function returnFromPaymentProcessorCreatingExisting() {
    paymentProcessorCreatingExistingDialog.value = false;
    paymentProcessorChooseExistingDialog.value = true;
  }

  async function onCreatePaymentProcessorSuccess() {
    await getPaymentProcessorAccounts();
    paymentProcessorCreatingExistingDialog.value = false;
    paymentProcessorExistingCreatedDialog.value = true;
  }

  function paymentProcessorCompanyIsPagSeguro() {
    return paymentProcessor.value.processorCompany === PaymentProcessorCompany.PagSeguro;
  }

  function hasPaymentProcessors(): boolean {
    return paymentProcessorAccounts.value.length > 0;
  }

  function mapPaymentProcessorCompanyToName(company?: PaymentProcessorCompany) {
    const companyValue = company ?? paymentProcessor.value.processorCompany;
    switch (companyValue) {
      case PaymentProcessorCompany.PagSeguro:
        return 'PagSeguro';
      case PaymentProcessorCompany.Rede:
        return 'Rede';
      case PaymentProcessorCompany.Stone:
        return 'Stone';
    }
  }

  function getPaymentProcessorsCards() {
    const processors: PaymentProcessorCardInformation[] = [
      {
        company: PaymentProcessorCompany.PagSeguro,
        name: "PagSeguro",
        image: "/assets/paymentProcessors/payment-processor-pagseguro.png",
        recommended: false,
        enabled: featureFlagsStore.flags.paymentProcessorFlags.pagSeguro,
      },
      {
        company: PaymentProcessorCompany.Rede,
        name: "Rede",
        image: "/assets/paymentProcessors/payment-processor-rede.png",
        recommended: false,
        enabled: featureFlagsStore.flags.paymentProcessorFlags.rede,
      },
      {
        company: PaymentProcessorCompany.Stone,
        name: "Stone",
        image: "/assets/paymentProcessors/payment-processor-stone.png",
        recommended: false,
        enabled: featureFlagsStore.flags.paymentProcessorFlags.stone,
      },
    ];

    const enabledPaymentProcessors = processors.filter(p => p.enabled);

    let environmentPaymentProcessors;

    switch (acquisitionOrdersStore.environmentCatalog?.tenantId) {
      case 3144:
        environmentPaymentProcessors = [
          enabledPaymentProcessors.find(p => p.company === PaymentProcessorCompany.Stone),
          ...enabledPaymentProcessors.filter(p => p.company !== PaymentProcessorCompany.Stone),
        ];
        break;
        //Add more cases here
      default:
        environmentPaymentProcessors = [
          enabledPaymentProcessors.find(p => p.company === PaymentProcessorCompany.PagSeguro),
          ...enabledPaymentProcessors.filter(p => p.company !== PaymentProcessorCompany.PagSeguro),
        ];
        break;
    }

    return environmentPaymentProcessors.map((processor, index) => ({
      ...processor,
      recommended: index === 0,
    })) as PaymentProcessorCardInformation[];
  }

  return {
    createPaymentProcessor,
    getPaymentProcessorAccounts,
    getPaymentProcessorsCards,
    hasPaymentProcessors,
    loadingCreatingPaymentProcessor,
    loadingPaymentProcessorAccounts,
    mapPaymentProcessorCompanyToName,
    onCreateNewPaymentProcessorSelected,
    onExistingPaymentProcessorSelected,
    onPaymentProcessorCompanySelected,
    paymentProcessor,
    paymentProcessorAccounts,
    paymentProcessorChooseExistingDialog,
    paymentProcessorCompanyIsPagSeguro,
    paymentProcessorCompanySelectionDialog,
    paymentProcessorCreatingExistingDialog,
    paymentProcessorExistingCreatedDialog,
    resetPaymentProcessorFlow,
    returnFromPaymentProcessorChooseExisting,
    returnFromPaymentProcessorCreatingExisting,
    showAdditionalCards,
    startPaymentProcessorFlow,
    deletePaymentProcessor,
    loadingDeletingPaymentProcessors,
  }
});
