
import Vue from 'vue';

export default Vue.extend({
  name: "ExcelButton",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods:{
    getExcel() {
      this.$emit('excel');
    }
  }
});
