export default {
  franchisee: {
    response: {
      groups: {
        search: {
          error: "Erro ao buscar grupos"
        },
        access: {
          error: "Erro ao acessar grupos"
        }
      }
    },
    data: {
      title: "Fechamento Mensal"
    },
    help: {
      report: {
        description: `
        <p> Com esta funcionalidade, você pode gerar um relatório consolidado de faturamento mensal de todos os seus franqueados. Basta selecionar o ano e o mês desejado no filtro e gerar o relatório. </p>
        <p><strong>Importante </strong>: apenas meses já encerrados podem ser selecionados para o fechamento.</p>
        `
      },
      about: {
        title: "Ambientes",
        description: `
          <p>
            Nesta página, você pode visualizar as principais informações de cada ambiente, incluindo:
          </p>
          <p>
            <ul>
              <li>
                <strong>ID do Sub Ambiente</strong>: Número identificador do sub ambiente.
              </li>
              <li>
                <strong>Sub Ambiente</strong>: Nome do sub ambiente.
              </li>
              <li>
                <strong>CNPJ</strong>: Número de registro da empresa associada.
              </li>
              <li>
                <strong>Faturamento (últimos 30 dias)</strong>: Valor total faturado pelo sub ambiente nos últimos 30 dias.
              </li>
              <li>
                <strong>Quantidade de Máquinas</strong>: Total de máquinas associadas sub ambiente.
              </li>
              <li>
                <strong>Quantidade de Lojas</strong>: Total de lojas atreladas ao sub ambiente.
              </li>
              <li>
                <strong>Média de NPS</strong>: Avaliação de satisfação dos clientes com base no Net Promoter Score (NPS).
              </li>
            </ul>
          </p>
          <p>
            Utilizando o botão "Fechamento Mensal", você poderá visualizar as informações compiladas do mês selecionado.
          </p>
        `
      }
    }
  },
}
