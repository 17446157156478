export default {
  editLockLockForm: 'Edit lock',
  deviceNameLockForm: 'Device name',
  deviceNameLockErrorForm: 'Incorrect pattern! Examples with the correct pattern: "TPLockAA00", "MMD-1234"',
  deviceNameHintLockForm: 'The name must be exactly the same as the Bluetooth device',
  lockNameLockForm: 'Lock name',
  lockNameHintLockForm: 'Name for you to identify the lock, e.g. Drinks fridge, Main door',
  ageRestrictedLockForm: 'Restricted access +18',
  ageRestrictedHint: 'Only unlockable by people over 18',
  closeLockForm: 'Close',
  saveLockForm: 'Save',
  registerLockForm: 'Register',
  successEditMessage: 'Lock registration edited!',
  successAddMessage: 'Lock registered!',
  errorMessageEdit: 'Lock registration could not be updated',
  errorMessageAdd: 'Lock could not be registered',
  addLockLockForm: 'Register lock',
  deviceUnlockTime: 'Device unlock time',
  deviceUnlockTimeSeconds: '{time} seconds',
  deviceUnlockTimeMinute: '{time} minute',
  deviceUnlockPulse: 'Instantaneous (pulse)',
  deviceUnlockBehavior: 'What to do when the module is disconnected',
  keepOpenedOnDisconnect: 'Keep lock opened on disconnect',
  keepClosedOnDisconnect: 'Keep lock closed on disconnect',
  deviceUnlockBehaviorTitle: 'Lock Configuration in Case of Failure',
  deviceUnlockBehaviorHint: 'This function allows you to define the behavior of the lock in situations where the access module loses communication.',
  deviceUnlockBehaviorTypesTitle: 'You can choose:',
  deviceUnlockBehaviorTypesKeepClosed: 'Keep lock closed on disconnect: The door will remain locked, ensuring security but preventing access.',
  deviceUnlockBehaviorTypesKeepOpened: 'Keep lock opened on disconnect: The door will remain unlocked, allowing access even without communication with the module.',
}
