export const layoutConstants = {
  acquisitionOrdersColor: '#00889B',
  dialogMaxWidthUltra: '980px',
  dialogMaxWidthExtreme: '880px',
  dialogMaxWidthExtraLarge: '780px',
  dialogMaxWidthLarge: '680',
  dialogMaxWidthMedium: '580px',
  dialogMaxWidthSmall: '480px',
  dialogMaxWidthExtraSmall: '380px',
}
