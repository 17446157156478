
import Vue from 'vue';
export default Vue.extend({
  data() {
    return {
      translations: {
        data: {
          title: this.$t('franchisee.data.title').toString(),
        },
      }
    };
  },
})
