
import { shouldRefreshData, toApiPagination } from "@/components/TableOptionsComparator";
import SuspiciousTransactionsFilters from './components/SuspiciousTransactionsFilters.vue';
import { formatDateTimeSeconds, formatPercentage, formatCardHolder } from '@/utility/TextFormatting'
import {REMOTE_MONITORING_ROLE} from '@/constants/Roles';
import {Suspicious} from "@/api/agent";
import Vue from "vue";
import {TransactionsFilters} from "@/types/Suspicious";

const filtersInitialState = {
  minDate: null,
  maxDate: null,
  customerId: null,
  localId: null,
  pointOfSaleId: null,
  productId: null,
  minPrice: null,
  maxPrice: null,
  cardHolder: null,
  paymentMethod: null,
  status: null,
  result: null,
  results: null,
  subtractedItems: false,
  minTime: null,
  maxTime: null,
} as TransactionsFilters;
export default Vue.extend({
  components: {
    SuspiciousTransactionsFilters
  },
  props: {
    activeTab: { type: Number, default: 0 },
    customer: { type: Number, default: null },
    hasCameraHub: { type: Function as unknown as () => ((pointOfSaleId: number) => boolean) }
  },
  data() {
    const $currencyFormatter = (this as any).$currencyFormatter;
    return {
      headers: [
        {text: this.$t('tables.headers.result'), align: 'left', sortable: true, value: 'result' },
        { text: this.$t('tables.headers.pointOfSaleId'), align: 'left', sortable: true, value: 'pointOfSaleId' },
        { text: this.$t('tables.headers.customer'), align: 'left', sortable: false, value: 'pointOfSaleLocalCustomerName' },
        { text: this.$t('tables.headers.location'), align: 'left', sortable: false, value: 'pointOfSaleLocalName' },
        { text: `${this.$t('tables.headers.value')} (${$currencyFormatter.getSign()})`, align: 'left', sortable: true, value: 'paymentAmount' },
        { text: this.$t('tables.headers.card'), align: 'left', sortable: true, value: 'cardHolder' },
        { text: this.$t('tables.headers.dateHour'), align: 'left', sortable: true, value: 'date' },
        { text: this.$t('tables.headers.verificationStatus'), align: 'left', sortable: true, value: 'verificationStatus' },
        { text: this.$t('tables.headers.actions'), align: 'left', sortable: false, value: 'actions' }
      ],
      items: [] as any[],
      showProfits: true,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["date"],
        sortDesc: [true],
      },
      totalItems: 0,
      expanded: [] as any[],
      form: { ... filtersInitialState} ,
      shouldUpdate: false,
      loading: false,
      updateInterval: null as number|null,
      intervalTime: 15000
    }
  },
  computed: {
    isAllExpanded(): boolean {
      return this.items && this.items.length > 0 && this.expanded.length === this.items.length;
    },
    expandAllIcon(): string {
      return this.isAllExpanded ? 'mdi-arrow-collapse-all' : 'mdi-arrow-expand-all'
    },
    updateChange(): boolean {
      return this.shouldUpdate && (this.activeTab == 0);
    },
    userIsRemoteMonitoring(): boolean {
      return (this as any).$auth.check(REMOTE_MONITORING_ROLE);
    },
    canViewProfits(): boolean {
      return this.showProfits && !this.userIsRemoteMonitoring;
    },
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          const wasAllExpanded = this.isAllExpanded;
          this.getSuspiciousTransactions();
          setTimeout(() => {
            this.expanded = wasAllExpanded ? this.items : [];
          }, 700);
        }
      },
      deep: true
    },
    updateChange(hasToUpdate) {
      if (hasToUpdate == true) {
        this.getSuspiciousTransactions();
      }
    }
  },
  methods: {
    update(form: TransactionsFilters) {
      this.form = form;
      this.options.page = 1;
      this.setUpdateInterval();
      const wasAllExpanded = this.isAllExpanded;
      this.getSuspiciousTransactions();
      setTimeout(() => {
        this.expanded = wasAllExpanded ? this.items : [];
      }, 700);
    },
    updateByAction(form: TransactionsFilters){
      this.form = form;
      this.setUpdateInterval();
      this.getSuspiciousTransactions();
    },
    getSuspiciousTransactions() {
      this.loading = true;
      const paginationParams = toApiPagination(this.options);
      const params = { ...paginationParams, ...this.form };
      Suspicious.getSuspiciousTransactions(params)
        .then((response) => {
          this.items = response.items;
          this.totalItems = response.totalItems;
          this.shouldUpdate = false;
          this.items.forEach(i =>  {
              i.available = false;
              i.url = "";
            }
          );
          this.getTransactionsVideos();
          this.loading = false;
        })
        .catch((error) => {
          this.sendFeedBack('error', error);
          this.loading = false;
        })
    },
    getTransactionsVideos() {
      this.axios.get(`api/Video/Transactions`)
        .then((response) => {
          response.data.forEach((video: any) => {
            const index = this.items.findIndex(i => video.fileName.includes(i.uuid));
            if (index >= 0) {
              const item = this.items[index];
              item.available = true;
              item.url = video.url;
              this.$set(this.items, index, item);
            }
          });
        })
        .catch((error) => {
          this.sendFeedBack('error', error);
        })
        .then(() => {});
    },
    setUpdateInterval() {
      if (this.updateInterval) clearInterval(this.updateInterval);

      this.updateInterval = setInterval(() => {
        if (this.activeTab == 0) this.shouldUpdate = true;
      }, this.intervalTime);
    },
    onExpandClick() {
      this.expanded = this.isAllExpanded ? [] : this.items;
    },
    getActionsIcon(item: any) {
      if (item.available && item.url) {
        return 'mdi-video';
      } else if (this.hasCameraHub(item.pointOfSaleId)) {
        return 'mdi-video-check';
      } else {
        return 'mdi-download';
      }
    },
    getActionsColor(item: any) {
      if (item.available) {
        return 'green darken-2';
      } else if (this.hasCameraHub(item.pointOfSaleId)) {
        return 'green darken-2';
      } else {
        return 'blue';
      }
    },
    action(item: any) {
      if (item.available) {
        window.open(item.url, '_blank');
      } else if (this.hasCameraHub(item.pointOfSaleId)) {
        (this as any).$emit('camera-click', {
          pointOfSaleId: item.pointOfSaleId,
          initialDate: (this.$moment(item.date) as any).add(-1, 'minute').toISOString(),
          duration: 300,
        });
      } else {
        this.downloadVideo(item);
      }
    },
    getResultIcon(result: string|null) {
      if (result == 'Ok') {
        return 'mdi-check-circle';

      } else if( result == 'Cancelled') {
        return 'mdi-minus-circle';
      }else {
        return 'mdi-close-circle';
      }
    },
    getResultColor(result: string|null) {
      if (result == 'Ok' ) {
        return 'green darken-2';
      } else {
        return 'red';
      }
    },
    getResultDescription(result: string|null) {
      if (result == 'Cancelled') {
        return this.$t('transactions.status.cancelled');
      } else if (result == 'Ok') {
        return this.$t('transactions.status.paid');
      } else {
        return this.$t('transactions.status.paymentError');
      }
    },
    getStatusColor (status: string) {
      if (status == 'Opened') return 'orange lighten-1'
      else if (status == 'Verified') return 'green'
      else if (status == 'HasDeviations') return 'red lighten-1'
      else if (status == 'Lost') return 'grey darken-1'
      else return 'blue'
    },
    formatStatus(oldStatus: string) {
      if (oldStatus == 'Opened') return this.$t('security.deviationAlert.resultDeviationStatus.pending')
      else if (oldStatus == 'Verified') return this.$t('security.deviationAlert.resultDeviationStatus.verifiedOk')
      else if (oldStatus == 'HasDeviations') return this.$t('security.deviationAlert.resultDeviationStatus.deviation')
      else if (oldStatus == 'Refunded') return this.$t('security.deviationAlert.resultDeviationStatus.refunded')
      else if (oldStatus == 'Lost') return this.$t('security.deviationAlert.resultDeviationStatus.lost')
    },
    handleClick(uuid: string, action: number) {
      let newStatus = 'Opened';
      if (action == 1) {
        newStatus = 'Verified';
      } else if (action == 2){
        newStatus = 'HasDeviations';
      } else if (action == 3) {
        newStatus = 'Refunded';
      } else if (action == 4) {
        newStatus = 'Lost';
      }
      (this as any).axios.put(`api/Suspicious/transactions/${uuid}/Status?newStatus=${newStatus}`)
      .then(() => {
        const message = this.$t('security.deviationAlert.messages.success.updateStatusTransaction');
        this.sendFeedBack('success', message);
        this.updateByAction(this.form)
      }).catch((error: any) => this.sendFeedBack('error', error));
    },
    downloadVideo(item: any) {
      const timeSpan = 180;
      let startTime = new Date(item.date);
      let endTime = new Date(item.date);
      let params = {
        startTime: new Date(startTime.setSeconds(startTime.getSeconds() - timeSpan)),
        endTime: new Date(endTime.setSeconds(startTime.getSeconds() + timeSpan))
      };
      (this as any).axios.get(`api/Video/${item.pointOfSaleId}/${item.uuid + ".dav"}`, { params })
        .then(() => {
          const message = (this as any).$t('security.deviationAlert.messages.success.downloadRealizeSuccess');
          this.sendFeedBack('success', message);
        })
        .catch((error: any) => {
          this.sendFeedBack('error', error);
        })
    },
    sendFeedBack(type: any, content: any) {
      (this as any).$emit('feedbackSent', {
        type: type,
        content: content
      });
    },
    formatDateTimeSeconds,
    formatPercentage,
    formatCardHolder
  }
})
