
import Vue from 'vue';

export default Vue.extend({
  name: 'TransactionResultSelectorFilter',
  props: {
    value: { type: Array as () => string[], default: null},
    multiple: { type: Boolean, default: false},
  },
  data() {
    return {
      items: [
        { text: this.$t('transactions.status.paid'), value: 'Ok' },
        { text: this.$t('transactions.status.cancelled'), value: 'Cancelled' },
        { text: this.$t('transactions.status.paymentError'), value: 'PaymentError'}
      ],
    };
  },
  computed: {
    selectedValue: {
      get(): string[] {
        return this.value;
      },
      set(value: any) {
        this.$emit("input", value);
      }
    },
  },
});

