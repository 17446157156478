
import Vue from 'vue';
import FranchiseDialogHelp from '@/views/franchisee/components/FranchiseDialogHelp.vue';
import DownloadDialog from '@/components/common/DownloadDialog.vue';
import { FranchiseEnvironment } from '@/api/agent';
import moment from 'moment';
import DatePickerMonth from "@/components/filters/DatePickerMonth.vue";
import CloseButton from '@/components/buttons/CloseButton.vue';
import ExcelButton from "@/components/buttons/ExcelButton.vue";

export default Vue.extend({
  components: {
    CloseButton,
    FranchiseDialogHelp,
    DownloadDialog,
    DatePickerMonth,
    ExcelButton
  },
  props: {
    value: { type: Boolean, default: false }
  },
  data() {
    return {
      show: false,
      valid: false,
      loading: false,
      form: {
        minDate: '',
        maxDate: '',
        month:  moment().subtract(1, "month").format("YYYY-MM") as string | null,
      },
      downloadDialog: {
        show: false,
        fileName: ""
      },
      translations: {
        data: {
          title: this.$t('franchisee.data.title').toString(),
        },
        report: {
          response: {
            excel: {
              error: this.$t('report.response.excel.error').toString(),
            },
          }
        },
        button: {
          close: this.$t('buttons.close').toString(),
          excel: this.$t('buttons.excel').toString(),
          closing: this.$t('franchisee.data.title').toString(),
        }
      }
    };
  },
  computed: {
    calculatedMax(): string {
      const now = new Date();
      now.setMonth(now.getMonth() - 1);
      return now.toISOString().slice(0, 7);
    },
  },
  watch:{
    "form.month": {
      handler(month: string | null) {
        if (month === null) {
          this.form.minDate = "";
          this.form.maxDate = "";
        } else {
          const minDate = moment(month, "YYYY-MM").startOf("month");
          const maxDate = moment(month, "YYYY-MM").endOf("month");
          this.form.minDate = minDate.format("YYYY-MM-DD");
          this.form.maxDate = maxDate.format("YYYY-MM-DD");
        }
        this.valid = !!this.form.month;
      },
      immediate: true,
    },
  },
  methods: {
    closeDialog() {
      this.show = false;
      this.resetForm();
    },
    resetForm() {
      this.form.minDate = '';
      this.form.maxDate = '';
      this.form.month = moment().subtract(1, "month").format("YYYY-MM") as string | null;
    },
    async getExcel() {
      this.loading = true;
      let params = {
        minDate: this.form.minDate,
        maxDate: this.form.maxDate
      };
      try {
        const response = await FranchiseEnvironment.getFranchiseeAmbientExcel(params);
        this.downloadDialog.show = true;
        this.downloadDialog.fileName = response;
      }
      catch(error){
        (this as any).$refs.feedback.handleError(this.translations.report.response.excel.error, error);
      }
      finally {
        this.loading = false;
      }
    },

  }
});
