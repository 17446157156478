export default {
  approvalTab: 'PEDIDO PARA APROBACIÓN',
  search: 'Buscar',
  idAcquisitionOrder: 'Código de Solicitud',
  fantasyNameAcquisitionOrder: 'Nombre Comercial',
  documentIdAcquisitionOrder: 'CNPJ',
  emailAcquisitionOrder: 'Correo Electrónico',
  phoneNumberAcquisitionOrder: 'Número de Teléfono',
  dateCreatedAcquisitionOrder: 'Fecha de Creación',
  taxArrangementAcquisitionOrder: 'Régimen Tributario',
  statusAcquisitionOrder: 'Estado',
  itensDetailsAcquisitionOrder: 'Detalles de los Artículos',
  commentsAcquisitionOrder: 'Comentarios',
  attention: 'ATENCIÓN',
  image: 'Imagen',
  name: 'Nombre',
  quantity: 'Cantidad',
  description: 'Descripción',
  technicalInformation: 'Información Técnica',
  itemType: 'Tipo de Artículo',
  Leased: 'Comodato',
  Owned: 'Venta',
  PessoaFisica: 'Persona Física',
  SimplesNacional: 'Régimen Nacional Simplificado',
  Mei: 'Microempresario',
  Other: 'Otro',
  NotSpecified: 'No especificado',
  ErrorOnCheck: 'Error en la Verificación',
  areYouSureReject: '¿Está seguro de que desea rechazar este pedido? Esta acción es irreversible y requerirá que el solicitante reinicie todo el proceso.',
  areYouSureApprove: '¿Está seguro de que desea aprobar este pedido? Esta acción es irreversible.',
  areYouSurePending: '¿Agregar un Comentario?',
  reasonLabel: 'Deje un comentario explicando el motivo',
  reasonText: 'Escriba aquí el motivo',
  Approved: 'Aprobado',
  Cancelled: 'Rechazado',
  requiredRules: 'Campo obligatorio para Rechazar',
  errorLoadingData: 'Error al cargar datos',
  errorUpdatingAcquisitionOrder: 'Error al actualizar la solicitud',
  sucessUpdatingAcquisitionOrder: 'Solicitud actualizada con éxito',
  save: 'Guardar',
  cancel: 'Cancelar',
};
