export default {
    giveFeedback: 'Dar feedback de la página',
    feedbackDialogTitle: 'Feedback de la página',
    yourRatingForPage: 'Tu calificación para la página',
    selectOneOrMore: 'Selecciona uno o más',
    whatsWrong: '¿Qué está mal?',
    whatCanImprove: '¿Qué se puede mejorar?',
    whatPleasedYou: '¿Qué te gustó?',
    contactNumber: 'Número de contacto',
    weMayContactYou: 'Podemos ponernos en contacto contigo',
    contactNumberNote: 'Este número es para poder ponernos en contacto contigo. Si tu problema requiere seguimiento, comunícate con el soporte al: 19 99959-6630.',
    cancelConfirmation: '¿Estás seguro de que deseas cancelar? Los cambios se perderán.',
    cancel: 'Cancelar',
    submit: 'Enviar',
    thankYouForRating: 'Gracias por evaluar la página',
    yourOpinionIsImportant: 'Tu opinión es muy importante para nosotros.',
    close: 'Cerrar',
    contactSupport: 'Si tu problema requiere seguimiento, comunícate con el soporte al: 19 99959-6630.',
    toGoBack: 'Volver',
    yesOption: 'Sí',
    feedbackReason: 'Por favor, informa el motivo del feedback (categoría "Otros").',
    errorThatHappens: 'Por favor, informa el error que ocurre.',
    countryCode: '+11 111-111-111',
    countryCodeRules: 'Ingresa un número de celular válido.',
    formatInput: '+## ###-###-###',
    postTenantFeedbackError: 'No se pudo registrar el feedback',
};
