<template>
  <v-card class="mb-16">
    <News
      v-if="isBrazil"
    />
    <portal to="toolbarTitle">
      <TransactionsHelp/>
    </portal>
    <v-expansion-panels hover>
      <v-expansion-panel :key="1">
        <v-expansion-panel-header>
          <div><v-icon>filter_list</v-icon>{{ $t('filtersAndActions') }}</div>
          <v-spacer></v-spacer>
        </v-expansion-panel-header>
        <v-expansion-panel-content eager>
          <v-form
            ref="form"
            class="px-4"
            v-model="valid"
            @submit.prevent="options.page = 1; getTransactions()"
            lazy-validation
          >
            <v-row>
              <v-col cols="12" md="3" class="search-field">
                <v-col cols="12" class="search-field">
                  <v-autocomplete
                    prepend-icon="account_box"
                    :items="customers"
                    v-model="form.customerId"
                    :label="$t('customerTransactions')"
                    item-text="name"
                    item-value="id"
                    single-line
                    clearable
                  >
                  </v-autocomplete>
                </v-col>

                 <v-col cols="12" class="search-field">
                  <v-text-field
                    type="number"
                    min="0"
                    step="0.01"
                    placeholder="0.00"
                    :rules="[rules.currency]"
                    prepend-icon="attach_money"
                    :label="$t('minimumPriceTransactions')"
                    v-model="form.minAmount"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" class="search-field">
                  <v-menu
                    class="pr-2"
                    :close-on-content-click="true"
                    v-model="hasMinDate"
                    transition="scale-transition"
                    offset-y
                    :nudge-right="40"
                    max-width="290px"
                    min-width="290px"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-on="on"
                        :label="$t('initialDateTransactions')"
                        :rules="[rules.required, rules.date]"
                        required
                        v-model="formattedMinDate"
                        prepend-icon="date_range"
                        readonly
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.minDate"
                      no-title
                      scrollable
                      actions
                      locale="pt-br"
                      :allowed-dates="getAllowedMinDates"
                      :change="
                        (formattedMinDate = formatDate(
                          form.minDate
                        ))
                      "
                    >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="clearMinDate"
                          >{{ $t('clearTransactions') }}</v-btn
                        >
                      </v-card-actions>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-col>

              <v-col cols="12" md="3" class="search-field">
                <v-col cols="12" class="search-field">
                  <v-autocomplete
                    prepend-icon="location_on"
                    :disabled="form.customerId === null || loading.locals"
                    :hint="
                      form.customerId === null
                        ? $t('selectClientFirstTransactions')
                        : ''
                    "
                    :items="locations"
                    v-model="form.localId"
                    :loading="loading.locals"
                    item-text="name"
                    item-value="id"
                    :label="$t('transactionLocation')"
                    single-line
                    persistent-hint
                    clearable
                  >
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" class="search-field">
                  <v-text-field
                    type="number"
                    min="0"
                    step="0.01"
                    placeholder="0.00"
                    :rules="[rules.currency]"
                    number
                    prepend-icon="attach_money"
                    :label="$t('maximumPriceTransactions')"
                    v-model="form.maxAmount"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" class="search-field">
                  <TimePicker
                    v-model="form.minTime"
                    :label="$t('minTimeTransactions')"
                  />
                </v-col>

              </v-col>

              <v-col cols="12" md="3" class="search-field">
                <v-col cols="12" class="search-field">
                  <v-autocomplete
                    prepend-icon="business"
                    :disabled="
                      form.localId === null || loading.pointsOfSale
                    "
                    :hint="
                      form.localId === null
                        ? $t('selectLocationTransactions')
                        : ''
                    "
                    :items="pointsOfSale"
                    v-model="form.pointOfSaleId"
                    :loading="loading.pointsOfSale"
                    :label="$t('pointOfSaleTransactions')"
                    single-line
                    persistent-hint
                    clearable
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" class="search-field">
                  <products-filter
                    v-model="form.productId"
                    icon="mdi-package-variant-closed"
                    @input="handleSearchResult"
                  >
                  </products-filter>
                </v-col>
                <v-col cols="12" class="search-field">
                  <v-menu
                    :close-on-content-click="true"
                    v-model="hasMaxDate"
                    transition="scale-transition"
                    offset-y
                    :nudge-right="40"
                    max-width="290px"
                    min-width="290px"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-on="on"
                        :label="$t('maxDateTransactions')"
                        :rules="[rules.required, rules.date]"
                        required
                        v-model="formattedMaxDate"
                        prepend-icon="event_available"
                        readonly
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.maxDate"
                      no-title
                      scrollable
                      actions
                      locale="pt-br"
                      :change="
                        (formattedMaxDate = formatDate(
                          form.maxDate
                        ))
                      "
                      :allowed-dates="getAllowedMaxDates"
                    >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="clearMaxDate"
                          >{{ $t('clearTransactions') }}</v-btn
                        >
                      </v-card-actions>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-col>

              <v-col cols="12" md="3" class="search-field">

                <v-col cols="12" class="search-field tipo-venda">
                  <v-autocomplete
                    prepend-icon="playlist_add_check"
                    :items="paymentMethods"
                    v-model="form.paymentMethod"
                    :label="$t('paymentTypeTransactions')"
                    single-line
                    hide-details
                  >
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" class="search-field">
                  <v-text-field
                    type="text"
                    min="0"
                    placeholder="0000"
                    prepend-icon="mdi-credit-card"
                    :label="$t('lastCardDigits')"
                    v-model="form.cardHolder"
                    clearable
                    maxlength="4"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" class="search-field">
                  <TimePicker
                    v-model="form.maxTime"
                    :label="$t('maxTimeTransactions')"
                  />
                </v-col>

              </v-col>

            </v-row>
            <v-row justify="end">
              <v-switch
                class="pa-0 pr-2"
                v-model="showDiscounts"
                :label="$t('buttons.showDiscount')"
              ></v-switch>
              <v-switch
                class="pa-0 pr-2"
                v-model="showProfits"
                :label="$t('showProfits')"
              ></v-switch>
              <v-btn
                class="mx-2 my-2"
                @click.native="clearQuery"
                color="error"
                outlined
                :disabled="!valid"
              >
                <v-icon left>clear</v-icon>{{ $t('clearTransactions') }}
              </v-btn>
              <v-btn
                class="mx-2 my-2"
                type="submit"
                color="success"
                :disabled="!valid"
              >
                <v-icon left>refresh</v-icon> {{ $t('updateTransactions') }}
              </v-btn>
              <v-btn
                v-if="!userIsRemoteMonitoring"
                class="mx-2 my-2"
                color="info"
                @click.native="getExcel"
                :disabled="!valid"
                :loading="loading.excel"
              >
                <v-icon left>mdi-file-excel</v-icon> {{ $t('excelTransactions') }}
              </v-btn>

              <!-- NFCe menu -->
              <v-menu :close-on-content-click="false" v-if="isBrazil && !userIsRemoteMonitoring">
                <template #activator="{ on }">
                  <v-btn v-on="on" class="mx-2 my-2" color="info">
                    <v-icon left>more_vert</v-icon>{{ $t('nfceTransactions') }}
                  </v-btn>
                </template>

                <v-list>
                  <invoice-xml
                    :initialDate="form.minDate"
                    :finalDate="form.maxDate"
                    :customerId="form.customerId"
                    :localId="form.localId"
                    :pointOfSaleId="form.pointOfSaleId"
                    #default="{ loading, download }"
                    @error="
                      (error) =>
                        $refs.feedback.handleError(
                          $t('errorWhenDownloadingXml'),
                          error
                        )
                    "
                  >
                    <v-list-item
                      :disabled="loading"
                      @click.stop="download"
                    >
                      <v-list-item-action class="mr-4">
                        <v-progress-circular
                          color="primary"
                          indeterminate
                          v-if="loading"
                        ></v-progress-circular>
                        <v-icon v-else large>mdi-folder-zip</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        {{ $t('downloadXmlTransactions') }}
                      </v-list-item-content>
                    </v-list-item>
                  </invoice-xml>
                  <v-list-item
                    :disabled="loading.excelLoading"
                    @click.stop="getExcelXml">
                    <v-list-item-action class="mr-4">
                      <v-progress-circular
                        color="primary"
                        indeterminate
                        v-if="loading.excelLoading"
                      ></v-progress-circular>
                      <v-icon v-else large>mdi-file-excel</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      {{ $t('downloadExcelTransactions') }}
                    </v-list-item-content>
                  </v-list-item>
                  <bulk-retry-invoice
                    :initial-date="form.minDate"
                    :final-date="form.maxDate"
                  >
                    <template #activator="{ on }">
                      <v-list-item v-on="on">
                        <v-list-item-action class="mr-4">
                          <v-icon large>mdi-reload</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          {{ $t('reissueInBulk') }}
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </bulk-retry-invoice>
                  <invalidation-request>
                    <template #activator="{ on }">
                      <v-list-item v-on="on">
                        <v-list-item-action class="mr-4">
                          <v-icon large>mdi-close-circle-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          {{ $t('invalidateNumbering') }}
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </invalidation-request>
                  <invalidations-dialog>
                    <template #activator="{ on }">
                      <v-list-item v-on="on">
                        <v-list-item-action class="mr-4">
                          <v-icon large
                            >mdi-text-box-remove-outline</v-icon
                          >
                        </v-list-item-action>
                        <v-list-item-content>
                          {{ $t('consultInvalidations') }}
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </invalidations-dialog>
                  <v-list-item>
                    <v-list-item-action class="mr-4">
                      <v-switch
                        v-model="form.showOnlyInvoiceErrors"
                        @change="getTransactions"
                      ></v-switch>
                    </v-list-item-action>
                    <v-list-item-content>
                      {{ $t('showOnlyInvoiceErrors') }}
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-data-table
      :headers="computedHeaders"
      :items="transactions"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading.table"
      class="mb-4"
      :item-class="getItemClass"
      must-sort
      :expanded.sync="expanded"
      show-expand
      @click:row="(item, props) => props.expand(!props.isExpanded)"
      :no-data-text="$t('noResultsTransactions')"
      :footer-props="{ itemsPerPageOptions: [10, 25, 50, 100] }"
    >
      <template #[`header.data-table-expand`]>
        <v-btn icon>
          <v-icon small @click.native="onExpandClick">{{
            expandAllIcon
          }}</v-icon>
        </v-btn>
      </template>
      <template #[`item.result`]="{ item }">
        <v-icon small :color="getResultColor(item.result)">
          {{ getResultIcon(item.result) }}
        </v-icon>
        {{ getResultDescription(item.result) }}
      </template>
      <template #[`item.pointOfSaleLocalName`]="{ item }">
        {{ getFullLocation(item) }}
      </template>
      <template #[`item.totalPrice`]="{item}">{{
        $currencyFormatter.format(item.totalPrice)
        }}
      </template>
      <template #[`item.discount`]="{item}">{{
          $currencyFormatter.format(item.discount)
        }}
      </template>
      <template #[`item.totalTaxes`]="{ item }">{{
        $currencyFormatter.format(item.totalTaxes)
      }}</template>
      <template #[`item.paymentAmount`]="{ item }">{{
          $currencyFormatter.format(item.paymentAmount)
        }}</template>
      <template #[`item.profits`]="{ item }">{{
        $currencyFormatter.format(item.profits)
      }}</template>
      <template #[`item.profitMargins`]="{ item }">{{
        formatPercentage(item.profitMargins)
      }}</template>
      <template #[`item.paymentMethod`]="{ item }">{{
        paymentMethodTranslations[item.paymentMethod]
      }}</template>
      <template #[`item.cardHolder`]="{ item }">
        {{ item.cardHolder ? `*-*-*-${item.cardHolder}` : "-" }}
      </template>
      <template #[`item.date`]="{ item }">{{
        formatDateTime(item.date)
      }}</template>
      <template v-if="!userIsRemoteMonitoring" #[`item.invoice`]="{ item }">
        <!-- Success invoice -->
        <v-tooltip
          top
          v-if="item.invoice && item.invoice.status === 'Approved'"
        >
          <template #activator="{ on }">
            <v-btn
              v-on="on"
              icon
              @click.stop="openInvoiceDetails(item.invoice)"
            >
              <v-icon color="success">mdi-text-box-check</v-icon></v-btn
            >
          </template>
          {{ $t('approvedInvoiceTransactions') }}
        </v-tooltip>

        <!-- Error invoice -->
        <v-tooltip top v-else-if="item.invoiceUuid">
          <template #activator="{ on }">
            <v-btn
              v-on="on"
              icon
              @click.stop="
                openInvoiceDetails(
                  item.invoice || createEmptyInvoice(item.invoiceUuid)
                )
              "
            >
              <v-icon color="error">mdi-text-box-remove</v-icon>
            </v-btn>
          </template>
          {{ $t('invoiceErrorTransactions') }}
        </v-tooltip>

        <!-- Undefined invoice -->
        <v-tooltip top v-else>
          <template #activator="{ on }">
            <v-btn v-on="on" icon @click.stop="openInvoiceDetails(null)">
              <v-icon color="grey">mdi-text-box-minus</v-icon>
            </v-btn>
          </template>
          {{ $t('notEnabledForPos') }}
        </v-tooltip>
      </template>

      <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="ma-0 pa-0">
          <v-simple-table
            class="details-table blue-grey lighten-5 elevation-0"
          >
            <tbody>
              <tr>
                <td class="caption" v-if="!userIsRemoteMonitoring">
                  <strong>{{ item.paymentMethod === 'Cashless' ? '' : $t('acquirerTransactions') }}</strong>
                  <span>{{ item.paymentMethod === 'Cashless' ? '': item.paymentProcessorDescription }}</span>
                </td>
                <td class="caption"  v-if="!userIsRemoteMonitoring">
                  <strong>{{ item.paymentMethod === 'Cashless' ? '' : $t('acquirerCode') }}</strong>
                  <span>{{ item.paymentMethod === 'Cashless' ? '': item.friendlyTransactionCode }}</span>
                </td>
                <td class="caption" v-if="!userIsRemoteMonitoring">
                  <strong>{{ item.paymentMethod === 'Cashless' ? '' : $t('destinationAccount') }}</strong>
                  <span>{{ item.paymentMethod === 'Cashless' ? '': item.receiverAccount }}</span>
                </td>
              </tr>
              <tr>
                <td class="caption" v-if="!userIsRemoteMonitoring">
                  <strong>{{ item.paymentMethod === 'Cashless' ? $t($t('cashlessCard'))  : $t('brandTransactions') }}</strong>
                  <span>{{ item.paymentMethod === 'Cashless' ? item.cardHolder : item.cardBrand }}</span>
                </td>
                <td class="caption">
                  <strong>{{ $t('internalCode') }}</strong> {{ item.uuid }}
                </td>
                <td class="caption">
                  <strong>{{ $t('discountCoupon') }}</strong>
                  {{ item.discountCode || $t('noneTransactions') }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <v-simple-table>
            <thead class="blue-grey lighten-5 elevation-0">
              <tr>
                <th>{{ $t('productCode') }}</th>
                <th>{{ $t('productTransactions') }}</th>
                <th>{{ $t('productCategoryTransactions') }}</th>
                <th>{{ $t('quantityTransactions') }}</th>
                <th v-if="canViewProfits">{{ $t('tables.headers.cmv') }} ({{ $currencyFormatter.getSign() }})</th>
                <th v-if="canViewDiscounts">{{ $t('tables.headers.originalValue', {sign: $currencyFormatter.getSign()}) }}</th>
                <th v-if="canViewDiscounts">{{ $t('tables.headers.discount', {sign: $currencyFormatter.getSign()} )}} </th>
                <th v-if="isUSA">{{ $t('totalTaxes', {sign: $currencyFormatter.getSign()}) }}</th>
                <th>{{ $t('tables.headers.paymentAmount',  {sign: $currencyFormatter.getSign()}) }} </th>
                <th v-if="canViewProfits">{{ $t('tables.headers.marginValue', {sign: $currencyFormatter.getSign()}) }}</th>
                <th v-if="canViewProfits">{{ $t('tables.headers.marginPercentage') }}</th>
                <th>{{ $t('selectionTransactions') }}</th>
              </tr>
            </thead>
            <tbody class="blue-grey lighten-5">
              <tr
                v-for="product in item.items"
                :key="product.productDescription"
              >
                <td>{{ product.productCode }}</td>
                <td>{{ product.productDescription }}</td>
                <td>{{ product.productCategoryName }}</td>
                <td>{{ product.quantity }}</td>
                <td v-if="canViewProfits">{{ $currencyFormatter.format(product.costOfSale) }}</td>
                <td v-if="canViewDiscounts">{{ $currencyFormatter.format(product.price) }}</td>
                <td v-if="canViewDiscounts">{{ $currencyFormatter.format(product.productDiscount) }}</td>
                <td v-if="isUSA">{{ $currencyFormatter.format(product.totalTax) }}</td>
                <td>{{ $currencyFormatter.format(product.paymentAmount) }}</td>
                <td v-if="canViewProfits">{{ $currencyFormatter.format(product.profits) }}</td>
                <td v-if="canViewProfits">{{ formatPercentage(product.profitMargins) }}</td>
                <td>{{ product.selection }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </td>
      </template>
    </v-data-table>

    <invoice-dialog
      v-model="invoiceDialog.enabled"
      :invoice="invoiceDialog.invoice"
      @new-invoice="onNewInvoice"
    ></invoice-dialog>

    <feedback ref="feedback"></feedback>
    <download-dialog
      v-model="downloadDialog.show"
      :fileName="downloadDialog.fileName"
      @update="(value) => downloadDialog.show = value"
    />
  </v-card>
</template>

<script>
import { shouldRefreshData } from "@/components/TableOptionsComparator";
import InvoiceDialog from "@/views/invoice/InvoiceDialog";
import InvoiceXml from "@/views/invoice/InvoiceXml";
import InvalidationRequest from "@/views/invoice/InvalidationRequest";
import InvalidationsDialog from "@/views/invoice/InvalidationsDialog";
import BulkRetryInvoice from "@/views/invoice/BulkRetryInvoice";
import TransactionsHelp from "@/views/transactions/TransactionsHelp";
import { paymentMethodTranslations } from "@/constants/PaymentMethods";
import ProductsFilter from "@/components/filters/ProductsFilter";
import TimePicker from "@/components/filters/TimePicker.vue";
import DownloadDialog from '@/components/common/DownloadDialog.vue';
import {  REMOTE_MONITORING_ROLE} from '@/constants/Roles';
import News from '@/components/News.vue';
import {Invoices} from "@/api/agent";

export default {
  data() {

    const isBrazil = this.$auth.user().tenantCountry == "BR";
    const isColombia = this.$auth.user().tenantCountry == "CO";
    const isUSA = this.$auth.user().tenantCountry == "US";

    let paymentMethods = [
      { text: paymentMethodTranslations["Credit"], value: "Credit" },
      { text: paymentMethodTranslations["Debit"], value: "Debit" },
      { text: paymentMethodTranslations["Cash"], value: "Cash" },
    ];
    if (isBrazil) {
      paymentMethods = paymentMethods.concat([
        { text: paymentMethodTranslations["Voucher"], value: "Voucher" },
        { text: paymentMethodTranslations["AppCreditCard"], value: "AppCreditCard" },
        { text: paymentMethodTranslations["RemoteCredit"], value: "RemoteCredit" },
        { text: paymentMethodTranslations["Pix"], value: "Pix" },
        { text: paymentMethodTranslations["PicPay"], value: "PicPay" },
        { text: paymentMethodTranslations["Free"], value: "Free" },
        { text: paymentMethodTranslations["Cashless"], value: "Cashless" }
      ]);
    } else if (isColombia) {
      paymentMethods = paymentMethods.concat([
        { text: paymentMethodTranslations["QrCode"], value: "QrCode"}
      ]);
    }

    const $cf = this.$currencyFormatter;
    return {
      isBrazil: isBrazil,
      isUSA: isUSA,
      form:{},
      hasMinDate: false,
      hasMaxDate: false,
      minTime: null,
      maxTime: null,
      transactions: [],
      customers: [],
      locations: [],
      pointsOfSale: [],
      paymentMethodTranslations,
      paymentMethods: paymentMethods,
      valid: false,
      showProfits: true,
      showDiscounts: true,
      headers: [
        { text: this.$t('tables.headers.result'), align: "left", sortable: true, value: "result" },
        { text: this.$t('tables.headers.customer'), align: "left", sortable: false, value: "pointOfSaleLocalCustomerName",},
        { text: this.$t('tables.headers.location'), align: "left", sortable: false, value: "pointOfSaleLocalName",},
        { text: this.$t('tables.headers.pos'), align: "left", sortable: true, value: "pointOfSaleId" },
        { text: this.$t('tables.headers.originalValue', {sign: $cf.getSign()}) , align: "left", sortable: true, value: "totalPrice",},
        { text: this.$t('tables.headers.discount', {sign: $cf.getSign()}), align: "left", sortable: false, value: "discount",},
        { text: this.$t('tables.headers.totalTaxes', {sign: $cf.getSign()}), align: "left", sortable: true, value: "totalTaxes",},
        { text: this.$t('tables.headers.paymentAmount', {sign: $cf.getSign()}), align: "left", sortable: true, value: "paymentAmount",},
        { text: this.$t('tables.headers.marginValue', {sign: $cf.getSign()}), align: "left", sortable: true, value: "profits",},
        { text: this.$t('tables.headers.marginPercentage'), align: "left", sortable: true, value: "profitMargins",},
        { text: this.$t('tables.headers.method'), align: "left", sortable: true, value: "paymentMethod",},
        { text: this.$t('tables.headers.card'), align: "left", sortable: false, value: "cardHolder" },
        { text: this.$t('tables.headers.dateHour'), align: "left", sortable: true, value: "date" },
        { text: this.$t('tables.headers.nfce'), align: "left", sortable: false, value: "invoice" },
      ],
      loading: {
        locals: false,
        pointsOfSale: false,
        table: true,
        excel: false,
        excelLoading: false,
      },
      rules: {
        currency: (v) =>
          !v || /^\d\d*(?:\.\d{0,2})?$/.test(v) || this.$t('currencyRules'),
        required: (v) => !!v || this.$t('requiredRules'),
        date: (v) =>
          /^\d\d\/\d\d\/\d\d\d\d*$/.test(v) || this.$t('dateRules'),
      },
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["date"],
        sortDesc: [true],
        mustSort: true,
      },
      totalItems: 0,
      expanded: [],
      invoiceDialog: {
        enabled: false,
        invoice: null,
      },
      downloadDialog: {
        show: false,
        fileName: ""
      }
    };
  },
  computed: {
    canViewProfits() {
      return this.showProfits && !this.userIsRemoteMonitoring;
    },
    canViewDiscounts() {
      return this.showDiscounts;
    },
    isAllExpanded() {
      return (
        this.transactions.length > 0 &&
        this.expanded?.length === this.transactions.length
      );
    },
    expandAllIcon() {
      return this.isAllExpanded
        ? "mdi-arrow-collapse-all"
        : "mdi-arrow-expand-all";
    },
    computedHeaders() {
      let computedHeaders = this.isBrazil && !this.userIsRemoteMonitoring ?
        this.headers : this.headers.filter(h => h.value !== 'invoice');

      computedHeaders = this.canViewProfits ?
        computedHeaders : computedHeaders.filter(h => h.value !== "profitMargins" && h.value !== "profits");

      computedHeaders = !this.isUSA ?
        computedHeaders.filter(h => h.value !== "totalTaxes") : computedHeaders;

      computedHeaders = this.canViewDiscounts ?
        computedHeaders : computedHeaders.filter(h => h.value !== "discount" && h.value !== "totalPrice");

      return computedHeaders;
    },
    userIsRemoteMonitoring(){
      return this.$auth.check(REMOTE_MONITORING_ROLE);
    },
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.getTransactions();
        }
      },
      deep: true,
    },
    "form.customerId": function (newId) {
      if (newId != null) {
        this.loading.locals = true;
        this.axios.get("api/Customers/" + newId).then((response) => {
          this.loading.locals = false;
          this.locations = response.data.locals;
        }).finally(() => {
          this.form.localId = this.locations.find((l) => l.id === this.form.localId) != null
            ? this.form.localId
            : null;
        });
      }
      else{
        this.form.localId = null
      }
    },
    "form.localId": function (newId) {
      if (newId != null) {
        this.loading.pointsOfSale = true;
        this.axios
          .get("api/Locals/" + newId + "/PointsOfSale")
          .then((response) => {
            this.loading.pointsOfSale = false;
            this.pointsOfSale = response.data.map((p) => ({
              text:
                p.id +
                (p.specificLocation !== null ? " / " + p.specificLocation : ""),
              value: p.id,
            }));
          }).finally(() => {
            this.form.pointOfSaleId = this.pointsOfSale.find((p) => p.value === this.form.pointOfSaleId) != null
              ? this.form.pointOfSaleId
              : null;
          });
      }
      else{
        this.form.pointOfSaleId = null
      }
    },
  },
  mounted() {
    this.prepareParams();

    this.axios.get("api/Customers/Active").then((response) => {
      this.customers = response.data;
      this.getTransactions();
    });
  },
  methods: {
    handleSearchResult(productId){
      this.form.productId = productId;
    },
    getTransactions() {
      if (this.$refs.form.validate()) {
        this.loading.table = true;
        this.form.sortOrder = this.options.sortBy[0];
        this.form.descending = this.options.sortDesc[0];
        this.form.page = this.options.page;
        this.form.pageSize = this.options.itemsPerPage;
        const wasAllExpanded = this.isAllExpanded;
        this.axios
          .get("api/Transactions", { params: this.form })
          .then((response) => {
            this.loading.table = false;
            this.transactions = response.data.items;
            this.transactions.forEach(
              item => {
                item.discount = item.paymentAmount - item.totalTaxes - item.totalPrice;
                item.items.forEach(
                  items => {
                    items.productDiscount = items.discountedPrice - items.price ;
                  }
                )
              });
            this.totalItems = response.data.totalItems;
            this.expanded = wasAllExpanded ? this.transactions : [];
          })
      }
    },
    prepareParams() {
      this.form = this.newForm();
      const maxDate = new Date();
      const minDate = new Date(maxDate.getTime());

      const params = Object.assign({}, this.$route.query);
      if (Object.keys(params).length > 0) {
        this.form.cardHolder = params.cardHolder;
        this.form.paymentMethod = params.paymentMethod;
        this.form.customerId = params.customerId && parseInt(params.customerId);
        this.form.localId = params.localId && parseInt(params.localId);
        this.form.pointOfSaleId = params.pos && parseInt(params.pos);

        delete params.cardHolder;
        delete params.paymentMethod;
        delete params.customerId;
        delete params.localId;
        delete params.pos;
      }

      if (params.periodo == 'hoje') {
        minDate.setDate(minDate.getDate());
      } else if (params.periodo == 'sete') {
        minDate.setDate(minDate.getDate() - 7);
      } else {
        minDate.setDate(minDate.getDate() - 30);
      }
      delete params.periodo;
      this.$router.replace({ query: params });

      this.form.maxDate = this.$moment(maxDate).format("YYYY-MM-DD");
      this.form.minDate = this.$moment(minDate).format("YYYY-MM-DD");
      this.form.timezoneOffset = new Date().getTimezoneOffset();
    },
    getExcel() {
      if (this.$refs.form.validate()) {
        this.loading.excel = true;
        this.axios
          .get("api/Transactions/Excel", { params: this.form })
          .then((response) => {
            this.loading.excel = false;
            this.downloadDialog.show = true;
            this.downloadDialog.fileName = response.data;
          })
          .catch((error) => {
            this.loading.excel = false;
            this.$refs.feedback.handleError(this.$t('errorWhenGeneratingReport'), error)
          });
      }
    },
    async getExcelXml(){
      this.loading.excelLoading = true;
      let params = {
        initialDate: this.form.minDate,
        finalDate: this.form.maxDate,
        customerId: this.form.customerId,
        localId: this.form.localId,
        pointOfSaleId: this.form.pointOfSaleId
      }
      try {
        const response = await Invoices.getExcelXml(params);
        this.downloadDialog.show = true;
        this.downloadDialog.fileName = response;
      }
      catch(error){
        this.loading.excelLoading = false;
        this.$refs.feedback.handleError(this.$t('errorWhenGeneratingReportNFCe'), error)
      }
      finally{
        this.loading.excelLoading = false;
      }
    },
    openInvoiceDetails(invoice) {
      this.invoiceDialog.invoice = invoice;
      this.invoiceDialog.enabled = true;
    },
    createEmptyInvoice(invoiceUuid) {
      return {
        id: invoiceUuid,
        status: "RequestError",
        statusReason: this.$t('failureToCommunicate'),
      };
    },
    onExpandClick() {
      this.expanded = this.isAllExpanded ? [] : this.transactions;
    },
    clearQuery() {
      this.form = this.newForm();
      this.getTransactions();
    },
    newForm() {
      return {
        customerId: null,
        localId: null,
        pointOfSaleId: null,
        paymentMethod: null,
        minAmount: null,
        maxAmount: null,
        minDate: null,
        maxDate: null,
        cardHolder: null,
        minTime: null,
        maxTime: null,
        productId: null
      };
    },
    formatPercentage(number) {
      if (number == null) return "-";
      return (number * 100).toLocaleString("pt-BR", {
        maximumFractionDigits: 2,
      }) + " %";
    },
    getResultDescription(result) {
      switch (result) {
        case "Ok":
          return "";
        case "Unknown":
          return this.$t('unknownTransactions');
        case "VendFailure":
          return this.$t('dispensingFailure');
        case "Refunded":
          return this.$t('refundedTransactions');
        case "RefuncCancelled":
          return this.$t('refundCancelledTransactions');
        case "RefundError":
          return this.$t('refundErrorTransactions');
        case "ProductNotSelected":
          return this.$t('productNotSelectedTransactions');
      }
    },
    formatDateTime(date) {
      return this.$moment(date).format("DD/MM/YYYY HH:mm:ss");
    },
    formatDate(date) {
      return this.$moment(date).format("DD/MM/YYYY");
    },
    clearMinDate() {
      this.form.minDate = null;
      this.formattedMinDate = null;
    },
    clearMaxDate() {
      this.form.maxDate = null;
      this.formattedMaxDate = null;
    },
    getAllowedMinDates(minDate) {
      if (this.form.maxDate !== null) {
        return this.isDateRangeAllowed(
          new Date(minDate),
          new Date(this.form.maxDate)
        );
      }
      return true;
    },
    getAllowedMaxDates(maxDate) {
      if (this.form.minDate !== null) {
        return this.isDateRangeAllowed(
          new Date(this.form.minDate),
          new Date(maxDate)
        );
      }
      return true;
    },
    isDateRangeAllowed(min, max) {
      return max >= min && Math.round(max - min) / (1000 * 60 * 60 * 24) < 90;
    },
    getFullLocation(item) {
      const specific = item.pointOfSaleSpecificLocation;
      return (
        item.pointOfSaleLocalName + (specific != null ? " / " + specific : "")
      );
    },
    getResultColor(result) {
      if (result === "Ok") return "green darken-2";
      else return "red darken-2";
    },
    getResultIcon(result) {
      if (result === "Ok") return "check_circle";
      else return "error";
    },
    getItemClass(item) {
      if (this.expanded.includes(item)) return "blue-grey lighten-4";
    },
    onNewInvoice(invoice, oldInvoiceUuid) {
      this.invoiceDialog.invoice = invoice;
      const transaction = this.transactions.find(
        (t) => t.invoiceUuid === oldInvoiceUuid
      );
      if (transaction) {
        transaction.invoiceUuid = invoice.id;
        transaction.invoice = invoice;
      }
    }
  },
  components: {
    InvoiceDialog,
    InvoiceXml,
    InvalidationRequest,
    InvalidationsDialog,
    BulkRetryInvoice,
    TransactionsHelp,
    ProductsFilter,
    TimePicker,
    DownloadDialog,
    News
  },
};
</script>

<style scoped>
.details-table {
  padding-top: 12px;
  padding-bottom: 12px;
}
.details-table tbody tr td {
  border: none !important;
  height: 24px !important;
}

.tipo-venda{
  height: 66px;
}
</style>
