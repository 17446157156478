import {defineStore} from "pinia";
import agent from "@/api/agent";
import {ref} from "vue";
import {useAlertsStore} from "@/stores/alertsStore";
import {
  CatalogItem,
  Catalog,
  EnvironmentInformation,
  environmentInfoInitialState,
  catalogItemInitialState,
  DeliveryAddress,
} from "@/types/AcquisitionOrders";

export const useAcquisitionOrdersStore = defineStore('acquisitionOrders', () => {
  const alertsStore = useAlertsStore()

  const environmentCatalog = ref<Catalog>();
  const environmentInfo = ref<EnvironmentInformation>(environmentInfoInitialState.value);
  const deliveryAddresses = ref<DeliveryAddress[]>([]);

  const acquisitionOrderCatalogItemDetailsDialog = ref(false);
  const catalogItemDisplayed = ref<CatalogItem>(catalogItemInitialState.value);

  const loadingEnvironmentCatalog = ref(false);
  const loadingEnvironmentInfo = ref(false);

  async function getEnvironmentCatalog() {
    loadingEnvironmentCatalog.value = true;
    await agent.AcquisitionOrders.getEnvironmentCatalog()
      .then((response) => {
        environmentCatalog.value = response;
      })
      .catch((error) => alertsStore.showErrorSnackbar(error.response.data))
      .finally(() => loadingEnvironmentCatalog.value = false);
  }

  async function getEnvironmentInfo() {
    loadingEnvironmentInfo.value = true;
    await agent.AcquisitionOrders.getEnvironmentInfo()
      .then((response) => {
        environmentInfo.value = response;
        setDeliveryAddresses(environmentInfo.value);
      })
      .catch((error) => alertsStore.showErrorSnackbar(error.response.data))
      .finally(() => loadingEnvironmentInfo.value = false);
  }

  async function setDeliveryAddresses(environmentInfo: EnvironmentInformation) {
    const deliveryAddress = {
      address: environmentInfo.customerInfo.address,
      addressNumber: environmentInfo.customerInfo.addressNumber,
      addressComplement: environmentInfo.customerInfo.addressComplement,
      cep: environmentInfo.customerInfo.cep,
      city: environmentInfo.customerInfo.city,
      brazilState: environmentInfo.customerInfo.brazilState,
    } as DeliveryAddress;

    const emptyDeliveryAddress = {
      address: null,
      addressNumber: null,
      addressComplement: null,
      cep: null,
      city: null,
      brazilState: null,
    } as DeliveryAddress;

    deliveryAddresses.value = [deliveryAddress, emptyDeliveryAddress];
  }

  function handleAcquisitionOrderCatalogItemDetails(item: CatalogItem){
    catalogItemDisplayed.value = item;
    acquisitionOrderCatalogItemDetailsDialog.value = true;
  }

  function hasDeliveryAddress(): boolean {
    return deliveryAddresses.value.length > 0;
  }

  return {
    acquisitionOrderCatalogItemDetailsDialog,
    catalogItemDisplayed,
    deliveryAddresses,
    environmentCatalog,
    environmentInfo,
    getEnvironmentCatalog,
    getEnvironmentInfo,
    handleAcquisitionOrderCatalogItemDetails,
    hasDeliveryAddress,
    loadingEnvironmentCatalog,
    loadingEnvironmentInfo
  }
})
