export type CameraHub = {
  id: number;
  tenantId: number;
  groupId: number|null;
  pointOfSaleId: number;
  agent: string;
};

export type CameraHubResponse = {
  cameraHubs: CameraHub[];
}

export class CameraHubStreamRequest {
  constructor(
    public cameraHub: CameraHub,
    public initialDate: string,
    public duration: number
  ) {}
}

// export class SuspiciousTransactionsRequest extends PaginatedQuery {
//   minDate: null;
//   maxDate: null;
//   customerId: number:null,
//   localId: null,
//   userId: null,
//   pointOfSaleId: null,
//   productId: null,
//   minPrice: null,
//   maxPrice: null,
//   cardHolder: null,
//   minTime: null,
//   maxTime: null,
//   status: null,
//   result: null,
//   subtractedItems: null
//
//   constructor(
//     public page: number,
//     public pageSize: number,
//     public sortOrder?: string,
//     public descending: boolean = false,
//     public search?: string | null,
//   ) {
//
//   }
// }
