<template>
  <v-autocomplete
    prepend-icon="shopping_cart"
    :items="products"
    v-model="productId"
    :label="$t('filters.labels.product')"
    item-text="searchText"
    item-value="id"
    single-line
    clearable
    @change="changeProduct"
  >
  </v-autocomplete>
</template>
<script>
import agent from "@/api/agent";
export default {
  props: ["resetComponent", "value"],
  data() {
    return {
      products: [],
    };
  },
  computed: {
    productId: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  mounted() {
    this.loadDropdownProducts();
  },
  methods: {
    resetDropDown() {
      this.productId = null;
    },
    loadDropdownProducts(){

      const params = {
        page: 1,
        pageSize: -1,
        sortOrder: "description",
        descending: false,
      };
      agent.Products.simpleInfo(params)
      .then((response) => {
        this.products = response.items.map((product) => {
          product.searchText =
            (product.code !== null ? product.code + " - " : "") + product.description;
          return product;
        });
      });
    },
    changeProduct() {
      this.$emit("change", this.productId);
    },
  },
};
</script>
