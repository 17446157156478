export default {
  clientRequester: 'Requesting Client',
  paymentProcessorAccounts: 'Payment Processor Accounts',
  apiToken: 'API Token',
  activationCode: 'Activation Code',
  noPaymentProcessorAlert: 'No Payment Processor account available. Register one to select.',
  registerNewAccount: 'Register New Account',
  deliveryAddress: 'Delivery Address',
  address: 'Address',
  editAddress: 'Edit Address',
  termsAndConditions: 'Terms and Conditions',
  termsAndConditionsText: 'By placing your order, you agree to AMLabs Terms of Use',
  finishOrder: 'Finish Order',
  fantasyName: 'Trade Name',
  billingDocumentId: 'Company Documento',
  customerEmail: 'Customer Email',
  customerPhone: 'Customer Phone',
  productAddedToPurchase: 'Product added to purchase',
  midasCloseOrderAddressNumber: "Address Number",
  midasCloseOrderAddress: "Address",
  midasCloseOrderAddressComplement: "Complement",
  midasCloseOrderCep: "Postal Code",
  midasCloseOrderCity: "City",
  midasCloseOrderState: "State",
  noAddressAvailableAlert: "No address available. Register one to select.",
  withdrawAtCompany: 'Pickup at the company: AV. Monte Castelo, 575 - Jardim Proença, Campinas - SP, Postal Code: 13026-241',
  productSoldByAmLabs: 'Product sold by AMLabs',
  midasCloseOrderQuantity: 'Quantity',
  midasCloseOrderSave: 'Save',
  missingPaymentProcessorError: 'Select a PagSeguro account to complete the order',
  missingAddressInformationError: 'Delivery address information is missing. Please fill it to complete the order',
  missingPhoneInformationError: 'Customer phone information is missing. Please fill it to complete the order',
  missingEmailInformationError: 'Customer email information is missing. Please fill it to complete the order',
  missingApiTokenInformationError: 'API token information is missing. Please fill it to complete the order',
  missingActivationCodeInformationError: 'Activation code information is missing. Please fill it to complete the order',
  cepRules: 'The Postal Code must contain 8 digits',
  cepNeeded: 'Please enter the Postal Code',
  midasCloseOrderAddressComplementHint: 'Apartment/Block. Otherwise, enter N/A',
  cep: 'Postal Code',
  cepError: 'Invalid Postal Code, please enter a valid one',
  editPagseguroAccount: 'Edit Pagseguro Account',
  documentIdIsOk: "Valid Documento in the Federal Revenue and State Registration",
    documentIdIsNotOk: "Irregular Documento - {documentId} - Status in Federal Revenue: {status} and State Registration: {ieStatus} Please regularize with your accountant/Revenue/Sefaz before proceeding with the order.",
  missingDocumentIdError: "Incorrect or Non-existent Documento. Please contact our support for verification.",
  statusEnum: {
  Active: "ACTIVE",
    Suspended: "SUSPENDED",
    Unqualified: "UNQUALIFIED",
    Down: "CANCELLED",
    Null: "NULL"
},
  ieStatus: {
    Ok: "ACTIVE",
      NotOk: "IRREGULAR"
  },
  registerNewAddress: 'Register New or Edit Address',
};
