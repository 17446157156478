export default {
  clientRequester: 'Cliente Solicitante',
  paymentProcessorAccounts: 'Cuentas de procesadores de pagos',
  apiToken: 'Token de API',
  activationCode: 'Código de Activación',
  noPaymentProcessorAlert: 'No hay ninguna cuenta de procesador disponible. Registre una para seleccionar.',
  registerNewAccount: 'Registrar Nueva Cuenta',
  deliveryAddress: 'Dirección de Entrega',
  address: 'Dirección',
  editAddress: 'Editar Dirección',
  termsAndConditions: 'Términos y Condiciones',
  termsAndConditionsText: 'Al realizar su pedido, acepta los Términos de Uso de AMLabs',
  finishOrder: 'Finalizar Pedido',
  fantasyName: 'Nombre Comercial',
  billingDocumentId: 'Document Id de la Empresa',
  customerEmail: 'Correo Electrónico del Cliente',
  customerPhone: 'Teléfono del Cliente',
  productAddedToPurchase: 'Producto agregado para compra',
  midasCloseOrderAddressNumber: "Número de Dirección",
  midasCloseOrderAddress: "Enderezo",
  midasCloseOrderAddressComplement: "Complemento",
  midasCloseOrderCep: "Código Postal",
  midasCloseOrderCity: "Ciudad",
  midasCloseOrderState: "Estado",
  noAddressAvailableAlert: "No hay dirección disponible. Registre una para seleccionar.",
  withdrawAtCompany: 'Retirar en la empresa: AV. Monte Castelo, 575 - Jardim Proença, Campinas - SP, Código Postal: 13026-241',
  productSoldByAmLabs: 'Producto vendido por AMLabs',
  midasCloseOrderQuantity: 'Cantidad',
  midasCloseOrderSave: 'Guardar',
  missingPaymentProcessorError: 'Seleccione una cuenta PagSeguro para completar el pedido',
  missingAddressInformationError: 'Faltan datos en la dirección de entrega. Complételos para finalizar el pedido',
  missingPhoneInformationError: 'Faltan datos en el teléfono del cliente. Complételos para finalizar el pedido',
  missingEmailInformationError: 'Faltan datos en el correo electrónico del cliente. Complételos para finalizar el pedido',
  missingApiTokenInformationError: 'Faltan datos en el token de API. Complételos para finalizar el pedido',
  missingActivationCodeInformationError: 'Faltan datos en el código de activación. Complételos para finalizar el pedido',
  cepRules: 'El Código Postal debe contener 8 dígitos',
  cepNeeded: 'Por favor, ingrese el Código Postal',
  midasCloseOrderAddressComplementHint: 'Apartamento/Bloque. De lo contrario, coloque N/A',
  cep: 'Código Postal',
  cepError: 'Código Postal inválido, por favor ingrese un código válido',
  editPagseguroAccount: 'Editar Cuenta Pagseguro',
  documentIdIsOk: "Document Id Válido en la Receita Federal y en la Inscripción Estatal",
  documentIdIsNotOk: "Document Id irregular - {documentId} - Estado en la Receita Federal: {status} y en la Inscripción Estatal: {ieStatus} Regularícese con su contador/Receita/Sefaz antes de continuar con el pedido.",
  missingDocumentIdError: "Document Id Incorrecto o Inexistente. Por favor, contacte a nuestro soporte para verificar",
  statusEnum: {
    Active: "ACTIVA",
    Suspended: "SUSPENDIDA",
    Unqualified: "INAPTÁ",
    Down: "BAJADA",
    Null: "NULA"
  },
  ieStatus: {
    Ok: "ACTIVA",
    NotOk: "IRREGULAR"
  },
  registerNewAddress: 'Registrar Nueva o Editar Dirección',
};
