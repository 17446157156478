export default {
  franchisee: {
    response: {
      groups: {
        search: {
          error: "Error searching groups"
        },
        access: {
          error: "Error accessing groups"
        }
      }
    },
    data: {
      title: "Monthly Close"
    },
    help: {
      report: {
        description: `
        <p> With this functionality, you can generate a consolidated monthly revenue report for all your franchisees. Simply select the desired year and month in the filter and generate the report.</p>
        <p><strong>Important </strong>: only closed months can be selected for the closing.</p>
        `
      },
      about: {
        title: "Environments",
        description: `
          <p>
            On this page, you can view the main information for each franchisee environment, including:
          </p>
          <ul>
            <li>
              <strong>Sub Environment ID</strong>: Identification number of the sub environment.
            </li>
            <li>
              <strong>Sub Environment</strong>: Name of the sub environment.
            </li>
            <li>
              <strong>Revenue (last 30 days)</strong>: Total revenue generated by the sub environment in the last 30 days.
            </li>
            <li>
              <strong>Number of Machines</strong>: Total number of machines associated with the sub environment.
            </li>
            <li>
              <strong>Number of Stores</strong>: Total number of stores linked to the sub environment.
            </li>
            <li>
              <strong>Average NPS</strong>: Customer satisfaction rating based on the Net Promoter Score (NPS).
            </li>
          </ul>
          <p>
            By using the "Monthly Close" button, you can view the compiled information for the selected month.
          </p>
        `
      }
    }
  },
}
