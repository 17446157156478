export default {
  methods: {
    formatMaskPN(includeCountryCode = true) {
      const country = this.$auth.user().tenantCountry;

      const masks = {
        BR: { withCode: "+## ## #####-####", withoutCode: "## #####-####" },
        US: { withCode: "+# ###-###-####", withoutCode: "###-###-####" },
        CO: { withCode: "+## (###) ### ####", withoutCode: "(###) ### ####" },
      };
    
      const selectedMask = masks[country] || masks["BR"];
    
      return includeCountryCode ? selectedMask.withCode : selectedMask.withoutCode;
    },
    placeholderPN(includeCountryCode = true) {
      const country = this.$auth.user().tenantCountry;

      const placeholders = {
        BR: { withCode: "+55 11 11111-1111", withoutCode: "11 11111-1111" },
        US: { withCode: "+1 111-111-1111", withoutCode: "111-111-1111" },
        CO: { withCode: "+57 (111) 111 1111", withoutCode: "(111) 111 1111" },
      };
    
      const selectedPlaceholder = placeholders[country] || placeholders["BR"];
    
      return includeCountryCode ? selectedPlaceholder.withCode : selectedPlaceholder.withoutCode;
    },
    rulePN() {
      const tenantCountry = this.$auth.user().tenantCountry;
      const rules = {
        BR: (v) => !v || (v.replace(/\D/g, '').length === 13 || v.replace(/\D/g, '').length === 11) || this.$t('countryCodeRules'),
        US: (v) => !v || (v.replace(/\D/g, '').length === 11 || v.replace(/\D/g, '').length === 10) || this.$t('countryCodeRules'),
        CO: (v) => !v || (v.replace(/\D/g, '').length === 12 || v.replace(/\D/g, '').length === 10) || this.$t('countryCodeRules')
      };
      return rules[tenantCountry] || rules['BR'];
    }
  }
}
