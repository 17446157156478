<template>
  <v-expansion-panels v-model="expansionIndex" hover>
    <v-expansion-panel>

      <v-expansion-panel-header>
        <template>
        <div><v-icon>filter_list</v-icon>{{ $t('filters.filtersActions') }}</div>
        </template>
      </v-expansion-panel-header>

      <v-expansion-panel-content class="pb-2">
        <v-form ref="form" v-model="valid">

          <!-- Date Row -->
          <v-row>
            <!-- Min Date Column -->
            <v-col cols="12" md="3">
              <v-menu
                class="pr-2"
                :close-on-content-click="true"
                v-model="hasMinDate"
                transition="scale-transition"
                offset-y
                :nudge-right="40"
                max-width="290px"
                min-width="290px"
              >
                <template #activator="{ on }">
                  <v-text-field
                    v-on="on"
                    :label="$t('filters.labels.initialDate')"
                    :rules="[rules.required]"
                    required
                    v-model="formattedMinDate"
                    prepend-icon="date_range"
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.minDate"
                  no-title
                  scrollable
                  actions
                  :allowed-dates="getAllowedMinDates"
                  @input="changeDate()"
                >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="form.minDate = null"
                      >{{ $t('buttons.clear') }}</v-btn
                    >
                  </v-card-actions>
                </v-date-picker>
              </v-menu>
            </v-col>

            <!-- Max Date Column -->
            <v-col cols="12" md="3">
              <v-menu
                class="pr-2"
                :close-on-content-click="true"
                v-model="hasMaxDate"
                transition="scale-transition"
                offset-y
                :nudge-right="40"
                max-width="290px"
                min-width="290px"
              >
                <template #activator="{ on }">
                  <v-text-field
                    v-on="on"
                    :label="$t('filters.labels.finalDate')"
                    :rules="[rules.required]"
                    required
                    v-model="formattedMaxDate"
                    prepend-icon="date_range"
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.maxDate"
                  no-title
                  scrollable
                  actions
                  :allowed-dates="getAllowedMaxDates"
                  @input="changeDate()"
                >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="form.maxDate = null"
                      >{{ $t('buttons.clear') }}</v-btn
                    >
                  </v-card-actions>
                </v-date-picker>
              </v-menu>
            </v-col>

            <!-- Date Intervals chips -->
            <v-col class="search-field px-2" cols="12" md="6">
              <v-row class="ma-0">
                <p class="search-field my-0">
                  {{$t('filters.labels.rangeOfDays')}}
                </p>
              </v-row>
              <div class="ma-0 pa-0">
                <v-chip-group
                  active-class="deep-purple--text text--accent-4"
                  show-arrows
                  v-model="definedDateInterval"
                  @change="changeDateInterval(); selectedSavedReport = null;"
                >
                  <v-chip
                    outlined
                    filter
                    v-for="(interval, index) in dateIntervals"
                    :key="index"
                    :value="interval.value"
                  >
                    {{ interval. text }}
                  </v-chip>
                </v-chip-group>
              </div>
            </v-col>
          </v-row>

          <!-- Operation Row -->
          <v-col class="px-0">
            <v-expansion-panels flat hover>
              <v-expansion-panel class="px-0">
                <v-expansion-panel-header class="search-field px-0">
                  <template>
                    <div>
                      <v-icon>mdi-warehouse</v-icon>
                      {{ $t('filters.inventory') }}
                    </div>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="px-0">
                  <v-row class="px-0" cols="12">
                    <v-col cols="6" md="3">
                      <inventories-drop-down
                        v-model="form.inventoryId"
                        @input="changeInventory"
                        ref="inventoriesDropDown"
                        :multiple="false"
                      ></inventories-drop-down>
                    </v-col>
                    <v-col cols="6" md="6">
                      <operations-drop-down
                        v-model="form.type"
                        @input="changeOperationType"
                      ></operations-drop-down>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>

          <!-- Product And Saved Reports Row -->
          <v-row cols="12" class="px-0">

            <!-- Products Column -->
            <v-col class="px-0" cols="12" md="12">
              <v-expansion-panels flat hover>
                <v-expansion-panel class="px-3">
                  <v-expansion-panel-header class="search-field px-0">
                    <template>
                      <div>
                        <v-icon>mdi-cart</v-icon>
                        {{ $t('filters.labels.product') }}
                      </div>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="px-0">
                    <v-row cols="12" class="px-0">
                      <v-col cols="3">
                        <products-filter
                          icon="shopping_cart"
                          v-model="form.productId"
                          @input="selectedSavedReport = null"
                        ></products-filter>
                      </v-col>
                      <v-col cols="3">
                        <categories-drop-down
                          v-model="form.productCategoryId"
                          ref="categoriesDropDown"
                          @input="selectedSavedReport = null"
                        ></categories-drop-down>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>

          </v-row>

          <!-- Groupings Row -->
          <v-row>
            <v-subheader class="pt-5">{{ $t('filters.groupBy') }}</v-subheader>
            <v-col class="search-field" cols="12" md="12">
              <v-chip-group
                v-model="form.group"
                column
                active-class="deep-purple--text text--accent-4"
                mandatory
                multiple
                show-arrows
                @change="selectedSavedReport = null"
              >
                <v-chip
                  outlined
                  v-for="(group, index) in groups"
                  :key="index"
                  :value="group.value"
                >
                  <v-avatar
                    v-if="getGroupIndex(group.value) >= 1"
                    left
                    class="deep-purple lighten-4"
                  >
                    {{ getGroupIndex(group.value)}}
                  </v-avatar>
                  {{ group.text }}
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>

          <!-- Buttons Row -->
          <v-row class="pt-5" justify="end">

            <ClearButton
              @clear="clearQuery"
            />

            <SaveReportMenu
              :value="saveReportMenu"
              :form="form"
              :defined-date-interval="definedDateInterval"
              :groups="form.group"
              :selected-saved-report="selectedSavedReport"
              type="Inventory"
              @input="changeSelectedSavedReport"
              @window="handleSavedReportMenu"
              @filterAdded="handleFilterAdded"
            />

            <ExcelButton
              @excel="emitExcelModification"
              :loading="loading.excel"
            />

            <UpdateButton
              :valid="valid"
              @refresh="emitTableModification"
            />
          </v-row>
          <!-- Feedback -->
          <feedback ref="feedback"></feedback>

        </v-form>

      </v-expansion-panel-content>

    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import moment from "moment";
import InventoriesDropDown from '@/components/filters/InventoriesDropDown';
import CategoriesDropDown from '@/components/filters/CategoriesDropDown';
import SaveReportMenu from './SaveReportMenu';
import OperationsDropDown from '@/components/filters/OperationsDropDown';
import ClearButton from "@/components/buttons/ClearButton.vue";
import ExcelButton from "@/components/buttons/ExcelButton.vue";
import UpdateButton from "@/components/buttons/UpdateButton.vue";
import {displayDateFormatted} from "@/utility/TextFormatting";
import {DateTimePrecision} from "@/constants/enums";
import ProductsFilter from '@/components/filters/ProductsFilter.vue';


export default {
  components: {
    InventoriesDropDown,
    ProductsFilter,
    CategoriesDropDown,
    OperationsDropDown,
    SaveReportMenu,
    ClearButton,
    ExcelButton,
    UpdateButton
  },
  props: ["loadingExcelStop"],
  mounted() {
    const maxDate = new Date();
    const minDate = new Date(maxDate.getTime());
    minDate.setDate(minDate.getDate() - 30);
    this.form.maxDate = moment(maxDate).format("YYYY-MM-DD");
    this.form.minDate = moment(minDate).format("YYYY-MM-DD");
  },
  watch: {
    loadingExcelStop: function() {
      this.loading.excel = false;
    },
    'form.productId'(newProductId) {
      if (newProductId != null) {
        this.form.productCategoryId = null;
      }
    },
    'form.productCategoryId'(newCategoryId) {
      if (newCategoryId) {
        this.form.productId = null;
      }
    }
  },
  computed: {
    formattedMinDate() {
      return displayDateFormatted(this.form.minDate, this.country, false, DateTimePrecision.DateOnly);
    },
    formattedMaxDate() {
      return  displayDateFormatted(this.form.maxDate, this.country, false, DateTimePrecision.DateOnly);
    },
    country() {
      return this.$auth.user().tenantCountry;
    },
  },
  data() {
    return {
      valid: false,
      hasMinDate: null,
      hasMaxDate: null,
      dateIntervals: [{ text: this.$t('dateIntervals.thirtyDays'), value: 30 },
                      { text: this.$t('dateIntervals.fifteenDays'), value: 15 },
                      { text: this.$t('dateIntervals.sevenDays'), value: 7 },
                      { text: this.$t('dateIntervals.sameDay'), value: 0 }],
      groups: [
        { text: this.$t('filters.labels.product'), value: "product" },
        { text: this.$t('filters.labels.category'), value: "category" },
        { text: this.$t('filters.inventory'), value: "inventory" },
        { text: this.$t('filters.labels.operation'), value: "type" },
        { text: this.$t('filters.labels.day'), value: "day" },
        { text: this.$t('filters.labels.month'), value: "month" },
        { text: this.$t('filters.labels.dayOfWeek'), value: "dayOfWeek" }],
      form: {
        minDate: null,
        maxDate: null,
        inventoryId: null,
        group: [],
        productId: null,
        productCategoryId: null,
        type: []
      },
      definedDateInterval: null,
      rules: {
        required: (v) => !!v || this.$t('requiredDesiredRules'),
      },
      loading: {
        excel: false,
        saveReport: false,
        savedReports: false
      },
      expansionIndex: 0,
      ponents: 0,
      savedReports: [],
      newReportDescription: "",
      selectedSavedReport: null,
      saveReportMenu: false
    }
  },
  methods: {
    emitExcelModification() {
      if (this.$refs.form.validate()) {
        this.$emit("excel", this.form);
        this.loading.excel = true;
      }
    },
    emitTableModification() {
      if (this.$refs.form.validate()) {
        this.$emit("table", this.form);
        this.expansionIndex = undefined;
      }
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    changeDateInterval() {
      this.form.minDate = moment(this.form.maxDate).subtract(parseInt(this.definedDateInterval), 'days')
      .format("YYYY-MM-DD");
    },
    changeDate() {
      this.definedDateInterval = null;
    },
    getAllowedMinDates(minDate) {
      if (this.form.maxDate !== null) {
        return this.isDateRangeAllowed(
          new Date(minDate),
          new Date(this.form.maxDate)
        );
      }
      return true;
    },
    getAllowedMaxDates(maxDate) {
      if (this.form.minDate !== null) {
        return this.isDateRangeAllowed(
          new Date(this.form.minDate),
          new Date(maxDate)
        );
      }
      return true;
    },
    isDateRangeAllowed(min, max) {
      return max >= min && Math.round(max - min) / (1000 * 60 * 60 * 24) < 180;
    },
    getGroupIndex(groupChip) {
      return this.form.group.indexOf(groupChip) + 1;
    },
    clearQuery() {
      this.form = this.newForm();
      this.definedDateInterval = null;
    },
    newForm() {
      return {
        minDate: this.form.minDate,
        maxDate: this.form.maxDate,
        productCategoryId: null,
        productId: null,
        group: [],
        type: []
      };
    },
    changeSelectedSavedReport(selectedReport) {
      this.clearQuery();
      this.selectedSavedReport = selectedReport;
      if (this.selectedSavedReport != null) {
        this.form.group = this.selectedSavedReport.groupColumns;
        // This loop brings the filters in pairs from the SavedReport to the filter form in the page
        this.selectedSavedReport.savedReportFilters?.forEach(filterPair => {
          const filterString = filterPair.filterName;
          if(filterString != "dateInterval" && filterString != "type") {
            this.form[filterString] = Number(filterPair.filterValue) || filterPair.filterValue;
          }
          else if(filterString == "type") {
            this.form[filterString].push(...JSON.parse(filterPair.filterValue));
          }
          else {
            this.definedDateInterval = Number(filterPair.filterValue);
            this.changeDateInterval();
          }
        });
      }
    },
    changeInventory() {
      this.selectedSavedReport = null;
    },
    changeOperationType(value) {
      this.form.type = value;
    },
    handleSavedReportMenu(value) {
      this.saveReportMenu = value;
    },
    handleFilterAdded(value) {
      this.$refs.feedback.handleSuccess(value);
    },
  }
}
</script>
