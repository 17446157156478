export default {
    nonEmptyRules: 'Ingrese un nombre válido',
    requiredRules: 'Este campo es obligatorio.',
    requiredDesiredRules: 'Este campo es obligatorio.',
    integerRules: 'Ingrese un número entero',
    floatRules: 'Ingrese un valor válido',
    uniqueSelectionRules: 'Esta selección ya existe',
    requiredIfRules: 'Este campo es obligatorio.',
    isUrlRules: 'Ingrese una URL válida',
    isPositiveRules: 'Ingrese un valor válido',
    validCharactersRules: 'Ingrese caracteres válidos',
    barCodeRules: 'Ingrese un código de barras válido',
    currencyRules: 'Ingrese un valor válido',
    dateRules: 'Ingrese una fecha válida',
    countryCodeRules: 'Ingrese un número de teléfono válido.',
    integerRulesPositive: 'Ingrese un número positivo',
};
