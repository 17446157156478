
import VideoAlerts from "./VideoAlerts.vue";
import SuspiciousTransactions from "./SuspiciousTransactions.vue";
import SuspiciousMasterHelp from './helpers/SuspiciousMasterHelp.vue';
import SuspiciousAccess from './SuspiciousAccess.vue';
import VideoDialog from "./VideoDialog.vue";
import Vue from "vue";
import {CameraHubs} from "@/api/agent";
import {CameraHub, CameraHubStreamRequest} from "@/types/CameraHub";

export default Vue.extend({
    data() {
      return {
        activeTab: 0,
        customers: [],
        isTokenActivated: true,
        cameraHubs: [] as CameraHub[],
        videoDialog: {
          enabled: false,
          url: null as string|null
        }
      }
    },
    mounted() {
      this.axios.get("api/Customers/Active").then((response) => {
        this.customers = response.data;
      });

      this.axios.get("api/Suspicious/transaction-sync-status").then((response)=> {
        this.isTokenActivated = response.data.active;
        });

      this.getCameraHubs();
    },
    methods: {
      getCameraHubs() {
        const tenantId = (this as any).$auth.user().tenantId;
        const groupId = (this as any).$auth.user().tenantGroupId;
        CameraHubs.getCameraHubs(tenantId, groupId).then((response) => {
          this.cameraHubs = response.cameraHubs;
        });
      },
      hasCameraHub(pointOfSaleId: number): boolean {
        return this.cameraHubs.some((ch: CameraHub) => ch.pointOfSaleId == pointOfSaleId);
      },
      showFeedbackMessage(newFeedback: { type: any, content: any }) {
        if (newFeedback.type == 'error') {
          (typeof newFeedback.content) == 'string'
            ? (this.$refs.feedback as any).handleError(newFeedback.content)
            : (this.$refs.feedback as any).handleError('Erro', newFeedback.content);
        }
        else {
          (this.$refs.feedback as any).handleSuccess(newFeedback.content);
        }
      },
      onCameraClick(event: { pointOfSaleId: number, initialDate: string, duration: number }) {
        this.videoDialog.enabled = true;
        const cameraHub = this.cameraHubs.find((ch: CameraHub) => ch.pointOfSaleId == event.pointOfSaleId);
        if (cameraHub === undefined) {
          (this.$refs.feedback as any).handleError('Erro', 'Câmera não encontrada');
        }
        const request = new CameraHubStreamRequest(cameraHub!, event.initialDate, event.duration);
        CameraHubs.getStreamingUrl(request).then((response) => {
          this.videoDialog.enabled = true;
          this.videoDialog.url = response.url;
        });
      }
    },
    components: {
        VideoAlerts,
        VideoDialog,
        SuspiciousTransactions,
        SuspiciousMasterHelp,
        SuspiciousAccess,
    },
});
